<template lang='pug'>
  #myhistory.view
    header
      w-wrapper
        w-flex
          h4.title {{ $t('History')}}

    section.myhistory  
      w-wrapper.mainbody
        .active-tab.color-gray
          p.tab(@click='tab = "Joined"' :class="{active: tab == 'Joined'}") {{ $t("Joined Sessions") }}
          p.tab(@click='tab = "Hosted"' :class="{active: tab == 'Hosted'}") {{ $t("Hosted Sessions") }}
        w-divider.borderless.card
        w-flex.column.sessions-info
          .info-title.table-format
            tr
              td {{ $t("Session Date") }}
              td {{ $t("Host") }}
              td {{ $t("Session Name") }} 
              td {{ $t("Points Used") }}
          w-flex.info-detail
            .joined(v-if='tab == "Joined"')
              w-flex.no-purchase(v-if='joined.length == 0')
                p.em13.color-gray {{ $t("No purchase history.") }} 
              .session-info.table-format(v-for='item, i in joined' :key='i')
                tr
                  td {{ item.start.toDate().toDateString() }}
                  td {{ item.pid }}
                  td {{ item.topic.desc }}
                  td {{ item.topic.points }}
            .hosted(v-else)
              w-flex.no-purchase(v-if='hosted.length == 0')
                p.em13.color-gray {{ $t("No purchase history.") }} 
</template>

<script>
  import { db } from '@/main'
  import { Viewable } from '@/mixins/authenticable'

  export default {
    name: 'my-history.view',

    mixins: [ Viewable ],

    data() {
      return {
        tab: 'Joined',
        session: null,
        joined: [],
        hosted: []
      }
    },

    watch: {
      user(curr, prev) {
        if ( curr.uid ) {
          this.$binding( 'hosted', db.collection( 'rsvs' ).where( 'hid', '==', this.user.uid ).where( 'start', '<', new Date()) )
          this.$binding( 'joined', db.collection( 'rsvs' ).where( 'reserved.by.profile.uid', '==', this.user.uid ).where( 'start', '<', new Date()) )
        }
      },
    },

    firestore() {
      if ( this.user.uid ) return {
        hosted: db.collection( 'rsvs' ).where( 'hid', '==', this.user.uid ).where( 'start', '<', new Date()),
        joined: db.collection( 'rsvs' ).where( 'reserved.by.profile.uid', '==', this.user.uid ).where( 'start', '<', new Date())
      }

    },
  }
</script>

<style scoped lang='stylus'>
  #myhistory
    .w-wrapper
      padding 10px 18px 10px 18px
    .mainbody
      background rgba( 255 255 255 .95)
      border-radius 5px
      padding 50px
  
  header
    margin-top -30px
    background rgba( 80 160 210 .95 )
    color white  
    @media( max-width: 420px)
      font-size: .8em
      padding 0px

  section
    background rgba( 0 0 0 .5 )
    padding 20px
      
  .active-tab 
    font-size 1.1em
    @media(max-width: 360px)
      display flex
      justify-content space-around
      text-align center
    .tab
      margin-right 24px
      padding-bottom 4px
      display inline-block
      cursor pointer
      @media(max-width: 360px)
        margin-right 0
  .active 
    border-bottom 5px solid rgb( 130 130 130 )
  .info-title
    background rgb( 180 180 180 )
    color white
    padding 16px 48px
    border-top-right-radius 13px
    border-top-left-radius 13px
    box-sizing border-box
    @media(max-width: 680px)
      padding 16px 12px
    p
      @media(max-width: 560px)
        width 100px
        margin-right 5px
        text-align center
    @media(max-width: 420px)
      padding 16px
  .info-detail
    min-height 200px
    border 1px solid rgb( 180 180 180 )
    background white
    .joined, .hosted 
      width 100%
      padding 0 48px
      @media(max-width: 680px)
        padding 0 12px
      .session-info 
        border-bottom 1px solid rgba( 120 120 120 .3 )
        padding 8px 0
  .table-format
    width 100%
    tr
      td
        width 10%
        text-align left 
        font-size 13px
        @media(max-width: 580px)
          width 15%
  .no-purchase 
    justify-content center
    margin-top 32px
</style>
