<template lang='pug'>
  #forgot-password.view
    w-notice(:message='message' @close='message = ""')
    w-wrapper.max-640
      h4.heading {{ $t('Enter your email below to reset your password') }}
      form(@submit.prevent='send')
        fieldset
          w-input
            template(slot='icon')
              email-icon
            input( required type='email' :placeholder='$t("Email")' v-model='email' )

        w-flex.centered
          .back-button
            router-link.text-only(:to='{ name: "sign-in" }') {{ $t('Back') }}
          w-button.wide(type='submit')
            template(slot='text') {{ $t('Send Reset Email') }}
</template>

<script>
  import { auth } from '@/main'

  export default {
    name: 'forgot-password.view',

    data() {
      return {
        email: '',
        message: '',
        show: false
      }
    },

    methods: {
      send() {
        auth.sendPasswordResetEmail( this.email )
        .then( () => this.message = 'Password-reset email is sent!' )
        .catch( e => this.message = e )
      }
    }
  }

</script>

<style scoped lang='stylus'>
  #forgot-password
    .w-wrapper
      background white
      padding 24px
      border-radius 15px

    .heading
      text-align center
      margin-bottom 32px
    .w-button
      margin-top 32px
      margin-right auto
    .back-panel
      top 12px
    .back-button
      margin-right auto
      text-align left
      margin-top 32px
      position relative
      left 32px
    .w-input .img
      margin-top 5.5px
</style>
