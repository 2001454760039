<template lang='pug'>
  w-flex.w-modal.centered.component( v-show='show' )
    slot
</template>

<script>
  export default {
    name: 'w-modal.component',

    props: {
      show: { type: Boolean, default: false },
      scrolled: Boolean
    },

    watch: {
      show(curr, prev) {
        if ( curr ) document.body.style.overflowY = 'hidden'
        else document.body.style.overflowY = 'auto'
      }
    },
  }
</script>

<style scoped lang='stylus'>
  @keyframes appear
    from
      background-color var(--clr-blue-light)
      border-color var(--clr-blue-light)
      transform scale(0)
      border-width 0
    to
      background-color white
      transform scale(1)

  >>> .close
    position absolute
    right 16px
    top 16px
    font-size 1.75em
    
  .w-modal
    height 100vh
    width 100vw
    position fixed
    top 0
    left 0
    z-index 8
    background rgba( 0 0 0 .5 )
    // @media( min-width: 1080px )
    //   box-sizing border-box
    //   padding-left var(--width-nav)

    &.align-left
      text-align left

    >>> .container
      animation-name appear
      animation-duration 640ms
</style>
