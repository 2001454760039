<template lang='pug'>
  #privacy.view
    header
      w-wrapper
        w-flex
          h4.title {{ $t('Privacy Policy')}}
    
    section.myposts
      w-wrapper.mainbody
        p Protecting the privacy of our customers is important to WorldTone. In order to keep you fully informed about our use of information we collect at WorldTone.com, please read our privacy policy.
        h5.title Information We Collect
        p.body When you sign up to participate in our program as a customer or service provider, we will ask for your name, e-mail address, mailing address, credit card number and expiration date (or other payment method) so that we may enable your participation.  We also will be notifying you with the status of any of your transactions. We also monitor customer traffic patterns and site usage to help us develop WorldTone.com's content and enable the best experience of everyone.
        h5.title How We Use That Information
        p.body We currently use the information that you provide to enable your transactions.  We may use the information to notify you about changes to WorldTone’s latest services and information that is specific to your usage needs. If you decide at any time that you no longer wish to connect with WorldTone.com, we value your privacy and other than information you need regarding credits that you may have with WorldTone, we will cease communication with you as you may request.  Please feel free to e-mail us at unsubscribe@WorldTone.com and ask to be removed from our notification list. When you submit a customer review, we also ask for your e-mail address, although you can choose not to have your user name displayed with any of your reviews.
        h5.title How We Protect Information
        p.body WorldTone uses a secure privately operated server to assure that your account information is safe.  The secure server software (SSL) encrypts all information you input before it is sent to us. In addition, we protect the customer data we collect against unauthorized access.
        h5.title Disclosure Of Information To Third Parties
        p.body WorldTone does not sell, lease or trade personal information with third parties. We may choose to do so in the future with reliable and secure parties, but unlike most companies, we will notify you and give you the opportunity to easily opt-out of such programs should they be created. 
        h5.title Your Consent
        p.body By using our Web site, you consent to the collection and use of this information by WorldTone. If we decide to change our privacy policy, we will post those changes on this page so that you are always aware of what information we collect, how we use it, and under what circumstances we disclose it. Regardless of later changes, however, we will never use the information you provide under this current notice in a new way without first providing you an opportunity to opt-out or otherwise prevent that use. If you have any questions, please contact us at service@WorldTone.com.
        h5.title BBB OnLine Privacy Program
        p.body WorldTone is proud to participate in the Council of Better Business Bureaus' BBB On-Line Privacy Program and comply with the heightened consumer protections found in BBB On-Line Privacy standards. Further information about this program may be found at www.bbbonline.org.
      
</template>

<script>
  import { Viewable } from '@/mixins/authenticable'
  
  export default {
    name: 'privacy.view',

    mixins: [ Viewable ],
    
  }
</script>

<style scoped lang='stylus'>
  
  #privacy
    .w-wrapper 
      padding 10px 18px 10px 18px
    .mainbody
      background rgba( 255 255 255 .95)
      border-radius 5px
      padding 50px
      .body 
        padding 10px
      .title
        padding-top 20px
      @media( max-width: 560px)
        font-size 0.8em
  header
    margin-top -30px
    background rgba( 80 160 210 .95 )
    color white  
    @media( max-width: 420px)
      font-size: .8em
      padding 0px
  section
    background rgba( 0 0 0 .5 )
    padding 20px
    
</style>
