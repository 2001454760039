<template lang='pug'>
  #landing.view 
    w-wrapper
      section.atf
        h1
          span.connect {{ $t('Connect')}}
          span.the-rest {{ $t('with Different Cultures') }}
        .video-text
          .video
            iframe(
              src="https://www.youtube.com/embed/8R1Y6aXm644" 
              frameborder="0" 
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen=""
            )
            
          .text
            h4.vchat {{ $t('via') }}
            h2
              span.vchat {{ $t('Interactive Video Chat') }}
            p {{ $t('Make friends, learn from each other, and make money!') }}
            .buttons
              button( @click='$router.push( "/sign-up" )').join {{ $t('Join Us!') }}
              button( @click='$router.push( "/sign-in" )').sign-in {{ $t('Sign In') }}

      section.flags
        w-wrapper
          h2 
            span.interest {{ $t('I am interested in...') }}
          .icons
            img.culture( v-for='c, i in cultures' :src='c.flag' @click='current.culture = i' )
          .swappable
            img.detail( :src='cultures[current.culture].banner' )
            .langinfo
              h4 {{ cultures[current.culture].infotitle }}
              p {{ cultures[current.culture].infodetail }}
              p {{ cultures[current.culture].infodetail2 }}
</template>

<script>
  import { db } from '@/main'
  import { Viewable } from '@/mixins/authenticable'

  export default {
    name: 'landing.view',

    mixins: [ Viewable ],

    data() {
      return {
        cultures: [
          { flag: '/flag-korea.svg', banner: '/hanbok.jpg', infotitle: "Hanbok (한복)", infodetail: "Hanbok (한복) is the semi-formal or formal attire for traditional occasions such as festivals, celebrations, and ceremonies. Hanbok (한복) in korean means “korean clothing”, but is now understood to refer to the clothing worn during the Joseon Period.", infodetail2: "Hanbok (한복) is characterised by vibrant colors, simple lines, and a lack of pockets. "},
          { flag: '/flag-china.svg', banner: '/chinese.jpg', infotitle: "Chinese New Year", infodetail: "Chinese New Year, also commonly known as the Spring Festival, is a celebration thrown on the beginning of the traditional Chinese lunar year. This day marks the end of winter and the start of spring. Chinese New Year is one of the most important holidays in China.", infodetail2: "2020 is the year of the Rat, and in 2020 Chinese New Year falls on February 12."},
          { flag: '/flag-us.svg', banner: '/football.jpg', infotitle: "(American) Football", infodetail: "Football (referred to as American football in countries other than America and Canada), is a team sport played by two teams of 11 players on a rectangular field with goalposts on each end. The National Football League (NFL) is the professional football league and contains 32 teams.", infodetail2: "The Super Bowl is the annual championship game of the NFL held in early February."},
          { flag: '/flag-japan.svg', banner: '/anime.jpg', infotitle: "Anime (アニメ)", infodetail: "Anime (アニメ) is hand-drawn and computer animation originating from japane. The term anime (アニメ) is the japanese term for animation, which refers to all forms of animated media. However, outside of Japan, anime refers specifically to animation from Japan, or Japanese animation style.", infodetail2: "Popular anime include Naruto, Demon Slayer: Kimetsu no Yaiba, & Studio Ghibli Films."},
          { flag: '/flag-french.svg', banner: '/french.jpg', infotitle: "Traditional French Cuisine", infodetail: "French cuisine developted throughout the centuries has been influenced by the many surrounding cultures of Spain, Italy, Switzerland, Germany, and Belgium, in addition to its own food tranditions on the long western coastlines of the Atlantic, the Channel and of course inland.", infodetail2: "Pot-au-feu is a French beef stew and is known as the national dish of France. "},
        ],
        current: { culture: 0 }
      }
    }
  }
</script>

<style scoped lang='stylus'>
  .atf
    color white
    font-size 1em
    margin-top 115px
    padding-left 95px
    @media( max-width: 960px )
      margin-top 45px
      padding-left 30px
    @media( max-width: 560px )
      margin-top 0px
      padding-left 10px
    
    h1
      margin-bottom 24px
      .connect
        font-size 1.2em
        font-family: roboto
        margin-right 76px
      .the-rest
        font-size 1.1em
        font-family: roboto
        margin-left 10px
      @media( max-width: 960px )
        .connect
          font-size 1em
        .the-rest
          font-size 0.9em
      @media( max-width: 800px )
        .connect
          font-size 1em
        .the-rest
          font-size .7em
      @media( max-width: 660px )
        .connect
          font-size .9em
        .the-rest
          font-size .6em
      @media( max-width: 560px )
        .connect
          font-size .8em
        .the-rest
          font-size .5em
          margin-left 0px
      @media( max-width: 420px )
        margin-top -35px
        margin-left 30px
        .connect
          font-size .5em
        .the-rest
          font-size .4em
          margin-left -30px
    h4, h2
      display inline
      .vchat
        font-size 1.1em
        font-family: roboto
        margin-top 0px
        @media( max-width: 960px )
          font-size .9em
        @media( max-width: 840px )
          font-size .7em
        @media( max-width: 660px )
          font-size .5em
            
    h4
      margin-right 8px
    
    .video-text
      display grid
      grid-template-columns 365px 1fr
      .video
        height 200px
        background-image url(/laptop.svg)
        background-size cover
        @media (max-width: 560px)
          margin-top 80px
          margin-left 5px
        iframe
          width 242px
          position relative
          left 60px
          top 8px 

      .text
        padding-left 30px
        p
          margin-top 12px
          @media( max-width: 660px )
            font-size 0.9em
          @media( max-width: 560px )
            font-size 0.8em
          @media( max-width: 420px )
            font-size 0.7em
        .buttons
          margin-top 48px
          margin-left 50px
          button
            padding 8px 32px
            border-radius 5px
            font-weight normal
          .join
            background rgb( 250 130 0 )
            margin-right 32px
          .sign-in
            background rgb( 245 245 245 )
            color black
          @media (max-width: 800px)
            margin-top 150px
            margin-left -200px
          @media (max-width: 560px)
            margin-top 280px
            margin-left -10px
          @media (max-width: 420px)
            margin-top 280px
            margin-left 7px
          
        @media( max-width: 660px )
          margin-left -50px
          margin-top -20px
        @media( max-width: 560px )
          margin-left -320px
          margin-top -20px
        @media( max-width: 420px )
          margin-left -340px
          margin-top -30px

  .flags
    background white
    opacity 0.95
    margin-top 60px
    padding 20px 40px
    border-radius 20px
    .interest 
      font-size 0.7em
      @media( max-width: 730px )
        font-size 0.6em
      @media( max-width: 560px )
        font-size 0.5em
    .icons
      padding-top 20px
      padding-bottom 20px
      text-align center
    .culture 
      border 1px solid grey
      background lightblue
      padding 10px
      border-radius 50%
      margin 0px 20px 0px 20px
      cursor pointer
      width 70px
      @media( max-width: 860px )
        width 60px
        margin 0px 15px 0px 15px
      @media( max-width: 750px )
        width 55px
        padding 5px
        margin 0px 10px 0px 10px
      @media( max-width: 640px )
        width 50px
        margin 0px 8px 0px 8px
      @media( max-width: 560px )
        width 40px
        margin 0px 6px 0px 6px
      @media( max-width: 440px )
        width 35px
        margin 0px 4px 0px 4px
    .langinfo
      padding 10px
      p
        padding-top 10px
      @media( max-width: 960px)
        font-size 1em
      @media( max-width: 820px)
        font-size 0.9em
      @media( max-width: 730px)
        font-size 0.8em
      @media( max-width: 640px)
        font-size 0.7em
        
    .swappable
      display grid
      grid-template-columns 200px 1fr
      background white
      opacity 1
      border solid 1px black
      padding 5px
      @media( max-width: 960px)
        grid-template-columns 200px 1fr
      @media( max-width: 820px)
        grid-template-columns 185px 1fr
      @media( max-width: 730px)
        grid-template-columns 160px 1fr
      @media( max-width: 640px)
        grid-template-columns 140px 1fr
      @media( max-width: 560px)
        grid-template-columns 120px 1fr
      
      .detail
        padding 0px
        @media( max-width: 960px)
          width 200px
        @media( max-width: 820px)
          width 175px
        @media( max-width: 730px )
          width 150px
        @media( max-width: 640px )
          width 130px
        @media( max-width: 560px )
          width 110px
</style>
