<template lang='pug'>
  .containers.flex.component
    slot
</template>

<script>
  export default {
    name: 'containers.flex.component'
  }
</script>

<style scoped lang='stylus'>
  .flex
    display flex
    position relative

  .flex.centered
    align-items center
    justify-content center
  
  .flex.left
    justify-content flex-start

  .flex.right
    justify-content flex-end

  .flex.space-between
    justify-content space-between

  .flex.space-evenly
    justify-content space-evenly

  .flex.column
    flex-direction column

  .flex.block
    width 100%

  .flex.full
    width 100%
    height 100vh
</style>
