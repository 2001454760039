<template lang='pug'>
  #w-double-carousel.component
    agile(v-if='users.length' :options='myOptions')
      .box(v-for='user, i in Math.round(users.length / 2)' :key='i')
        profile-card(
          :uid='users[ i * 2 ].uid'
        )
        w-divider.card
        profile-card(
          v-if='users[ i * 2 + 1 ]'
          :to='{ name: "profile", params: { id: users[ i * 2 + 1].id } }'
          :uid='users[ i * 2 + 1 ].uid'
        )
      template(slot='prevButton')
        left-icon.previous
      template(slot='nextButton')
        right-icon.next
</template>

<script>
export default {
  name: 'w-double-carousel.component',

  props: {
    users: Array
  },

  data() {
    return {
      myOptions: {
        responsive: [
          {
            breakpoint: 1080,
            settings: {
              slidesToShow: 5,
              navButtons: true
            }
          },

          {
            breakpoint: 820,
            settings: {
              slidesToShow: 4,
              navButtons: true
            }
          },

          {
            breakpoint: 580,
            settings: {
              slidesToShow: 3,
              navButtons: true
            }
          },

          {
            breakpoint: 480,
            settings: {
              slidesToShow: 2,
              navButtons: true
            }
          },

          {
            breakpoint: 380,
            settings: {
              slidesToShow: 2.2,
              navButtons: false
            }
          },

          {
            breakpoint: 320,
            settings: {
              slidesToShow: 1.6,
              navButtons: false
            }
          },
        ]
      },
    }
  }
}
</script>

<style scoped lang='stylus'>
  .previous, .next
    position absolute 
    top 0
    bottom 40px
    margin auto 0
    font-size 8em
    color rgb( 160 160 160 )
    cursor pointer
    
  .previous
    left -98px
    @media(max-width: 1200px)
      left -50px
    @media(max-width: 1080px)
      left -60px
  .next
    right -98px
    @media(max-width: 1200px)
      right -50px
    @media(max-width: 1080px)
      right -60px
</style>