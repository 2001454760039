<template lang='pug'>
  article.modals.wrapper.component
    slot
      slot(name='close')
        close-button( @click.native='$emit("close")' )

      header
        slot(name='header')

      section
        slot(name='contents')

      footer
        slot(name='footer')
</template>

<script>
  import CloseButton from '@/components/buttons/close'

  export default {
    name: 'modals.wrapper',

    components: {
      CloseButton
    }
  }
</script>

<style scoped lang="stylus">
  .wrapper
    position relative
    @media( max-width: 480px )
      min-width 0
    &.max-640
      max-width 640px
    &.max-800
      max-width 800px


    header
      background rgba( 0 0 0 .1 )
      border-bottom 1px solid rgba( 0 0 0 .25 )

    footer
      border-top 1px solid rgba( 255 255 255 .1 )
    
    header, footer
      padding 16px 32px
      box-shadow var(--shadow-box-shallow)

    section
      padding 32px
      max-height calc( 100vh - 280px )
      overflow-y auto
        
    .close
      position absolute
      right -6px
      top -6px
      background white
</style>