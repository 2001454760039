<template lang='pug'>
  w-wrapper.session-request.modal.component.max-480.container( v-if='requestable' )
    .close.actionable( @click='$emit("close")' )
      close-icon

    w-flex.profile-flex.centered
      p.centered-text 
        span {{ $t('Request to chat with ') }}
      
    w-divider.section.borderless  
        
    w-flex.profile-flex.centered
      img( :src='host.avatar' )
      p.name {{ host.display }}
    
    w-divider.section.borderless

    section( v-if='topics.length && !custom' )
      h6 {{ $t('Chat Topic') }}
      p {{ topic.title }}
      //- select.topicsel( v-model='topic' )
        option( v-for='topic in topics' :value='topic' ) {{ topic.title }} ({{ topic.points }} pts/min)
    
    w-divider.section.borderless

    section
      //- h6 {{ $t('Define My Topic') }}
      //- textarea(v-model='custom')

      .points( v-if='profile.points >= topic.points' )
        //- input( type='number' v-model='topic.points' min='1' :max='profile.points || 0')
        h6 {{ topic.points }} {{ $t('Pts/Min') }} for {{ topic.duration }} mins.
        //- select( v-model='topic.duration' )
          //- option( value='30' ) {{ topic.duration }} {{ $t('mins') }}
          
      router-link.points.notable( v-else :to='{ name: "my-points" }' ) You have no points!

    
    section.centered-text( v-if='profile.points >= topic.points' )
      w-button( @click.native='request' )
        template(v-slot:text) {{ $t('Send Request') }}
  
  w-wrapper.session-request.modal.awaiting.component.max-480.container( v-else )
    .close.actionable( @click='$emit("close")' )
      close-icon
    
    template( v-if='req.status == "pending"' )
      h5 Video Session Requested
      h6 Awaiting response from host...
      h4 {{ timer }}s
    
    w-flex.profile-flex.centered
      template( v-if='req.status == "rejected"')
        img.icon( src='/warning.svg' width='80')
        h5 Host has rejected the request...
        
</template>

<script>
  import { db } from '@/main'

  export default {
    name: 'modal.session-request.component',

    props: [ 'teacher', 'topics', 'user', 'profile' ],

    data() {
      return {
        points: 0,
        custom: '',
        duration: 30,
        requestable: true,
        timer: 30,
        req: null,
        host: {}
      }
    },

    firestore() {
      return {
        host: db.collection( 'profiles' ).doc( this.teacher.id )
      }
    },

    computed: {
      topic() {
        return this.host.topic
      }
    },

    watch: {
      topics( curr, prev ) {
        this.topic = curr ? curr[0] : null
      },

      timer( curr, prev ) {
        if ( curr <= 0 ) this.$emit('close')
      },

      req( curr, prev ) {
        if ( curr.status == 'accepted' ) this.timer = 0
        // else if ( curr.status == 'rejected' ) this.$emit( 'close' )
      }
    },

    methods: {
      request() {
        if ( !this.profile.points ) return

        let req = {
          status: 'pending',
          type: this.topic ? 'existing' : 'custom',
          topic: this.topic || {},
          points: this.points,
          duration: this.duration,
          custom: this.custom,
          uid: this.user.uid,
          profile: this.profile,
          host: this.host
        }

        db.collection( 'requests' ).add( req ).then( doc => {
          this.requestable = false
          setInterval( () => this.timer -= 1, 1000)
          this.$binding( 'req', db.collection( 'requests' ).doc( doc.id ) )
        })
      }
    }
  }
</script>

<style scoped lang='stylus'>
  .awaiting
    h2, h3, h4
      margin-top 16px
      text-align center
  .close
    background rgb( 160 160 160 )
    color rgb( 240 240 240 )
    padding 0px 4px
    top 0 !important
    right 0 !important
    border-top-right-radius 7px

  .session-request
    width 480px
    background white
    padding 30px
    padding-top 50px
    border-radius 5px
    @media(max-width: 480px)
      width 380px
    h4
      margin-bottom 5px
      color red
    img
      width 60px
      height 60px
      margin-right 10px
      border 5px lightblue solid
      border-radius 50%
      @media( max-width: 480px )
        width 40px
        height 40px
    p
      font-size 1.2em
      padding-right 32px
    
    .icon
      width 80px
      height 80px
      text-align center
      border 0px

    .name
      font-weight bold
      color blue
    
    input, textarea, select
      background rgb( 240 240 240 )
      border-radius 5px
      padding 5px 5px
      box-sizing border-box
      font-weight normal

    .topicsel
      width 300px

    .centered-text
      font-weight bold
      padding-top 10px
    .points
      margin-top 5px
      display flex
      flex-wrap nowrap
      align-items center
      &.notable
        justify-content center

      @media( max-width: 420px )
        font-size .9em
        
      input
        margin 0 16px
        border-radius 3px
        color red
        text-align center
        max-width 128px
        @media( max-width: 420px )
          margin 10px

      span
        margin-right 8px
        @media( max-width: 420px )
          margin 0
          display none

      select
        margin-left auto
        @media( max-width: 420px )
          margin 24px 0
    
    section
      margin 16px 0
</style>

