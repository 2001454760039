<template lang='pug'>
  #mypost.view
    w-notice(:message='message' @close='message = ""') 
    header
      w-wrapper
        w-flex
          h4.title {{ $t('My Post')}} - {{ post.title}}

    section.mypost
      w-wrapper.mainbody
        form(@submit.prevent='save()')
          w-fields(
            :title.sync='post.title'
            :subtitle.sync='post.subtitle'
            :desc.sync='post.desc'
          )

          .images(v-if='post.id')
            w-images(
              ref='images'
              :collection='collection'
              :user='user'  
              :parent_id='post.id'  
            )

          w-divider.section
          w-editor(
            :user='user'
            :contents.sync='post.contents'
          )
        
        w-divider.card
        .save.centered-text(@click='save()')
          w-button
            template(v-slot:text) {{ $t("Save") }}
    w-divider.section
</template>

<script>
  import { db } from '@/main'
  import { Viewable } from '@/mixins/authenticable'
  export default {
    name: 'my-post.view',

    mixins: [ Viewable ],

    data() {
      return {
        post: {},
        preview: false,
        message: '',
        collection: 'posts'
      }
    },

    firestore() {
      if (this.user.uid) {
        return {
          post: db.collection( 'posts' ).doc(this.$route.params.id)
        }
      }
    },

    watch: {
      user(curr, prev) {
        if (curr.uid) this.$binding( 'post', db.collection( 'posts' ).doc( this.$route.params.id ) ) 
      }
    },

    methods: {
      save() {
        this.post.edited = new Date()
        db.collection('posts').doc( this.post.id ).update( this.post ).then( () => {
        this.$refs.images.set()
          this.message = 'Changes Saved!',
          setTimeout( () => {this.message = ''}, 2500)
        })
      }
    }
  }


</script>

<style scoped lang='stylus'>
  #mypost
    .w-wrapper
      padding 10px 18px 10px 18px
    .mainbody
      background rgba( 255 255 255 .95)
      border-radius 5px
      padding 50px
    .preview 
      padding 32px 48px
    .buttons 
      text-align right
  header
    margin-top -30px
    background rgba( 80 160 210 .95 )
    color white  
    @media( max-width: 420px)
      font-size: .8em
      padding 0px

  section
    background rgba( 0 0 0 .5 )
    padding 20px
</style>
