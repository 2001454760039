<template lang='pug'>
  #app(@click='close' )
    w-modal( :show='!!reqs.length' )
      session-requests( v-if='!!reqs.length' :reqs='reqs' @accepted='sessions.push($event)' )

    w-modal( :show='!!sessions.length && !!profiles.length && !!user' )
      video-session( 
        v-if='sessions.length'
        :reqs='reqs'
        :user='user'
        :profile='profiles[0]'
        :session='sessions[0]'
        :channel='sessions.length ? sessions[0].id : ""'
        @exit='exit'
      )

    //- nav-drawer( :open.sync='open.left' :user='user' :key='$route.fullPath' )

    header
      visitor-header(
        v-if='!user'
        :key='$route.fullPath'
        :open.sync='open.left'
        :menu='menu.profile'
        @menu='menu.profile = !menu.profile'
      )
      user-header(
        v-else
        :user='user'
        :profile='profile'
        :key='$route.fullPath'
        :open.sync='open.left'
        :menu='menu.profile'
        @menu='menu.profile = !menu.profile'
      )

    main.container
      router-view(
        :user='user'
        :profile='profiles[0]'
        :session='sessions'
        @auth='auth'
      )

    footer
      w-footer
</template>

<script>
  import { db, auth } from '@/main'
  import { Authenticable } from '@/mixins/authenticable'
  import SessionRequests from '@/components/modals/session-requests'
  import VideoSession from '@/components/modals/video-session'
  import VisitorHeader from '@/components/headers/visitor'
  import UserHeader from '@/components/headers/user'

  export default {
    name: "app",

    mixins: [ Authenticable ],

    components: {
      SessionRequests,
      VideoSession,
      VisitorHeader,
      UserHeader
    },

    data() {
      return {
        open: { left: false },
        menu: { profile: false },
        authentication: null,
        timestamp: null,
        sounds: {}
      }
    },

    computed: {
      profile() {
        return this.profiles[0]
      }
    },

    watch: {
      authentication( curr, prev ) {
        console.log('authentication observed')
        if ( curr.id ) {
          let latest = curr.time.toDate()
          let uid = curr.uid
          if ( this.timestamp ) {
            if ( latest.getTime() != this.timestamp.getTime() ) auth.signOut()
          }
        }
      }
    },

    methods: {
      auth( auth ) {
        console.log('stamp received', auth)
        this.timestamp = auth.timestamp
      },

      close() {
        this.open.left = false
        this.menu.profile = false
      },

      exit() {
        // this.sessions = []
      }
    }
  }
</script>

<style lang="stylus">
  @import 'https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css'
  @import url('https://fonts.googleapis.com/css?family=Open+Sans&display=swap')

  :root
    --shadow-panel 0 0 16px 0 rgba( 40 80 120 .75 )
    --shadow-box 0 0 12px 0 rgba( 0 0 0 .2 )
    --shadow-box-shallow 0 0 8px 0 rgba( 0 0 0 .1 )
    --shadow-card -2px 2px 4px 0 rgba( 0 0 0 .25 )
    --shadow-card-hover 0 0 12px 0 rgba( 175 150 125 .64 )
    --shadow-border   0 1px 1px 0 rgba( 255 255 255 1)
    --shadow-text  0 0 1px 2px rgba( 255 255 255 1 )
    --text-shadow-rev 0 -1px 2px rgba( 0,0,0,.2 )
    --shadow-text-rev 0 -1px 2px rgba( 0,0,0,.2 )
    --clr-active rgb( 255 225 255 )
    --clr-light  rgb( 248,248,248 )
    --clr-dark   rgb( 64 64 64 )
    --clr-blue   rgb( 32 144 250 )
    --clr-red    rgb( 240 100 100 )
    --clr-blue-light rgb( 0 192 244 )
    --clr-notice white
    --clr-orange rgb( 248 144 64 )
    --clr-orange-light rgb( 255 225 205 )
    --clr-text rgb( 80 80 80 )
    --clr-heading var(--clr-text)
    --clr-button rgb( 255 250 240 )
    --clr-button-hover rgb( 255 255 255 )
    --clr-nav var(--clr-button)
    --clr-border rgb( 255 255 255 )
    --clr-primary rgb( 200, 100, 150 )
    --clr-accent rgb( 200 100 200 )

    --width-nav 300px
    --height-top-nav 106px
    --height-merchant-nav 88px

    --bg-body white

    --bg-inactive rgba( 0 0 0 .04 )

    --bg-nav rgb( 31 69 144 )

    --bg-nav-hover linear-gradient(
      -135deg,
      rgb( 48 64 144 ),
      rgb( 32 88 128 )
    )

    --bg-button var(--bg-nav-hover)

    --bg-overlay linear-gradient(
      45deg,
      rgba( 225 200 255 .96 ),
      rgba( 255 225 255 .96 ),
      rgba( 255 225 200 .96 )
    )


    --bg-button-hover linear-gradient(
      -45deg,
      rgba( 235 195 125 .88 ),
      rgba( 245 150 75 .88 )
    )

    --bg-panel linear-gradient(
      -135deg,
      rgba( 40 100 160 .98),
      rgba( 40 75 175 .96)
    )

    --bg-card linear-gradient(
      135deg,
      rgb( 255 235 235 ),
      rgb( 245 245 245 ),
      rgb( 250 240 255 )
    )

    --bg-btn linear-gradient(
      135deg,
      rgba( 255 254 252 .9 ),
      rgba( 255 252 248 .9 )
    )

    --bg-notice linear-gradient(
      90deg,
      rgb( 200 92 96 ),
      rgb( 225 135 80 )
    )

  ::placeholder, optgroup.label
    color rgb( 88 108 128 ) !important
    font-weight normal

  ::-webkit-scrollbar
    width 8px

  ::-webkit-scrollbar-thumb
    background rgb( 192 192 192 )
    border-radius 4px

  ::-webkit-scrollbar-track
    background rgb( 240 240 240 )

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus
    transition background-color 9999s ease-in-out 9999s
    -webkit-box-shadow: none //0 0 0 100px #fff inset;
    -moz-box-shadow: none //0 0 0 100px #fff inset;
    background 0
    box-shadow: 0 0 0 64px rgb(255 252 248) inset

  .view
    min-height calc( 100vh - 160px )
    padding-top 24px
  
  #app
    font-family: 'Open Sans', sans-serif
    min-height 100vh
    max-width 100vw
    overflow-x hidden
    background-image url(/bg.svg)
    background-repeat no-repeat
    background-size 3000px
    background-position calc( 50vw - 1200px ) -440px
    line-height 1.25
    color var(--clr-text)
    @media( max-width: 960px )
      background-size 2100px
      background-position -480px -302px
    @media( max-width: 560px )
      background-size 1800px
      background-position -440px -280px
    @media( max-width: 420px )
      background-size 1000px
      background-position -200px -120px

  h1, h2, h3, h4, h5, h6
    letter-spacing .25px
    line-height inherit
    color var(--clr-heading)
    font-family inherit
    font-weight bold
    color inherit

  h1
    font-size 2.8em
  h2
    font-size 2.0em
  h3
    font-size 1.6em
  h4
    font-size 1.4em
  h5
    font-size 1.2em
  h6
    font-size 1.0em

  a
    cursor pointer
    color inherit
    text-decoration inherit
    transition transform 320ms

  strong
    font-weight bold
  em
    font-style italic
  input, select, textarea, button
    background 0
    border 0
    color inherit
    font-family inherit
    text-align inherit
    font-size inherit
    padding 4px 0
    text-shadow inherit
    font-weight bold
    &:focus, &:active
      outline 0

  button
    font-weight normal
    
  textarea
    height 88px
    width 100%

  // select
  //   margin-left -4px
  //   display block
  //   width 100%


  .v-leave
    // transform scale(1)

  .v-leave-to
    transform scale(.88) translateY(390px)
    transition all 360ms
    height 0
    *
      display none

  .v-enter
    opacity 0
    transform translateX(100vw)

  .v-enter-to
    opacity 1
    transition all 360ms
    transform translateX(0)

  .resp
    @media( max-width: 1080px )
      display none

  .actionable, select, button
    cursor pointer

  .container
    position relative

  .centered-text
    text-align center

  .desc
    margin-top 8px
    font-size .92em
    white-space pre-line

  .icon img
    display block
    width 100%
    height 100%
    object-fit contain

  .input-container
    input
      width 100%

  .system-message
    font-family Ubuntu

  .ellipsis
    white-space nowrap
    overflow hidden
    text-overflow ellipsis

  .snippet
    font-style italic

  .notable
    color red

  .enlarge
    font-size 1.2em

  .auth-link
    text-align center
    font-size 1.1em
    a
      margin-left 8px
      color var(--clr-blue)
      font-weight bold

  .bright
    color var(--clr-blue-light)

  .red 
    background red 

  .green
    background green

  .white
    background white

  .bold
    font-weight bold
  .em13
    font-size 1.3em
  .em11
    font-size 1.1em
  .em12 
    font-size 1.2em
  .color-red
    color rgb( 250 80 80 )
  .color-gray 
    color rgb( 130 130 130 )
  .agile
    @media(max-width: 1200px)
      padding 0 28px
    @media(max-width: 480px)
      padding 0
</style>
