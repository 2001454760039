<template lang='pug'>
  button.iconic.button.component( :type='type' :title='title' )
    slot
</template>

<script>
  export default {
    name: 'iconic.component',

    props: {
      type: { type: String, default: 'button' },
      title: String
    }
  }
</script>

<style scoped lang='stylus'>
  .iconic
    text-shadow inherit
    border 1px solid rgba( 88 88 88 .1 )
    box-shadow 0 0 1px 1px rgba( 255 255 255 1 ) inset
    align-items center
    transition transform 320ms
    background 0
    border-radius 3px
    padding 4px 12px
    background rgba( 240 240 240 1 )
    &:hover
      box-shadow 0 0 16px 0px rgba( 0 0 0 .1 )
      border-color rgba( 88 88 88 .2 )
      background white
    &:active
      transform scale(.88)
      background white
    &:focus
      outline 0

  .iconic.round
    border-radius 50%
    height 2em
    width 2em
</style>
