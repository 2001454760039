import Vue from 'vue'
import VueRouter from 'vue-router'


import VueTelInput from 'vue-tel-input'


import Landing from '@/views/landing'
import SignIn from '@/views/sign-in'
import PhoneSignIn from '@/views/phone-sign-in.vue'
import MyProfile from '@/views/my-profile'
import Profile from '@/views/profile'
import MyTopics from '@/views/my-topics'
import Topic from '@/views/topic'
import Charge from '@/views/charge'
import About from '@/views/about'
import Contactus from '@/views/contactus'
import Privacy from '@/views/privacy'
import Security from '@/views/security'
import Help from '@/views/help'
import MyHistory from '@/views/my-history'
import MyPoints from '@/views/my-points'
import MyFollows from '@/views/my-follows'
import MyCalendar from '@/views/my-calendar'
import MyPosts from '@/views/my-posts'
import MyPost from '@/views/my-post'
import SignUp from '@/views/sign-up'
import Session from '@/views/session'
import ForgotPassword from '@/views/forgot-password'
import Messages from '@/views/messages'
import Notifications from '@/views/notifications'

Vue.use(VueRouter)
Vue.use(VueTelInput)


const routes = [
  {
    path: '/',
    name: 'home',
    component: Landing,
    meta: { static: true }
  },

  {
    path: '/sign-in',
    name: 'sign-in',
    component: SignIn,
    meta: { static: true }
  },

  {
    path: '/forgot-password',
    name: 'forgot-password',
    meta: { static: true },
    component: ForgotPassword
  },

  {
    path: '/session/:hid?/:uid?',
    name: 'session',
    component: Session
  },

  {
    path: '/phone-sign-in',
    name: 'phone-sign-in',
    component: PhoneSignIn,
    meta: { static: true }
  },

  {
    path: '/sign-up/:ref?',
    name: 'sign-up',
    component: SignUp,
    meta: { static: true }
  },

  {
    path: '/my-profile',
    name: 'my-profile',
    component: MyProfile
  },

  {
    path: '/profile/:id/:access?',
    name: 'profile',
    component: Profile,
    meta: { static: true }
  },

  {
    path: '/live/:id/',
    name: 'live',
    component: () => import( '@/views/live' )
    // meta: { static: true }
  },

  {
    path: '/topic/:id',
    name: 'topic',
    component: Topic,
    meta: { static: true }
  },

  {
    path: '/my-topics',
    name: 'my-topics',
    component: MyTopics
  },

  {
    path: '/about',
    name: 'about',
    component: About
  },

  {
    path: '/privacy',
    name: 'privacy',
    component: Privacy
  },

  {
    path: '/contactus',
    name: 'contactus',
    component: Contactus
  },

  {
    path: '/security',
    name: 'security',
    component: Security
  },

  {
    path: '/help',
    name: 'help',
    component: Help
  },

  {
    path: '/refer',
    name: 'refer',
    component: () => import( '@/views/refer' )
  },

  {
    path: '/charge',
    name: 'charge',
    component: Charge
  },
  
  {
    path: '/my-history',
    name: 'my-history',
    component: MyHistory
  },

  {
    path: '/messages/:current?',
    name: 'messages',
    component: Messages
  },

  {
    path: '/notifications',
    name: 'notifications',
    component: Notifications
  },
    
  {
    path: '/my-points',
    name: 'my-points',
    component: MyPoints
  },
      
  {
    path: '/my-follows',
    name: 'my-follows',
    component: MyFollows
  },

  {
    path: '/my-calendar',
    name: 'my-calendar',
    component: MyCalendar
  },

  {
    path: '/my-posts',
    name: 'my-posts',
    component: MyPosts
  },

  {
    path: '/my-post/:id',
    name: 'my-post',
    component: MyPost
  },


  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 };
  }
})

export default router
