<template lang='pug'>
  #session.view
    w-wrapper
      #buttons
        button#cameraBtn( @click='open' )
          span Open camera & microphone
        button#createBtn( @click='create' )
          span Create room
        button#joinBtn( @click='join' )
          span.mdc-button__label Join room
      #videos
        video#local-stream(muted="" autoplay="" playsinline="")
        video#remote-stream(autoplay="" playsinline="")
</template>

<script>
  import { db, auth, str } from '@/main'

  export default {
    name: 'session.view',

    components: {
    },

    props: {
      user: { type: Object, default: () => ({}) },
      profile: { type: Object, default: () => ({}) }
    },

    data() {
      return {
        rooms: [],
        local: null,
        remote: null,
        p2p: null,
        configuration: {
          iceServers: [
            {
              urls: [
                'stun:stun1.l.google.com:19302',
                'stun:stun2.l.google.com:19302',
              ],
            },
          ],

          iceCandidatePoolSize: 10
        }
      }
    },

    // firestore() {
    //   return {
    //     rooms: db.collection( 'rooms' )
    //   }
    // },

    computed: {
      room() {
        return { id: `${this.$route.params.uid}->${this.$route.params.hid}` }
        // return { id: `${this.$route.params.uid}->${this.$route.params.hid}` }
      }
    },

    methods: {
      async open() {
        this.local = await navigator.mediaDevices.getUserMedia({ video: true, audio: true })
        document.querySelector('#local-stream').srcObject = this.local
        console.log( 'local set' )
        this.remote = new MediaStream()
        document.querySelector('#remote-stream').srcObject = this.remote
      },

      async create() {
        const roomRef = await db.collection('sessions').doc( this.room.id )

        console.log( 'Create PeerConnection with configuration: ', this.configuration )
        this.p2p = new RTCPeerConnection( this.configuration )

        this.registerPeerConnectionListeners()

        this.local.getTracks().forEach( track => {
          console.log('track obtained', track)
          this.p2p.addTrack( track, this.local )
        })

        // Code for collecting ICE candidates below

        const callerCandidatesCollection = roomRef.collection( 'callerCandidates' )

        this.p2p.addEventListener( 'icecandidate', event => {
          if ( !event.candidate ) {
            console.log( 'Got final candidate!' )
            return
          }
          console.log( 'Got candidate: ', event.candidate )
          callerCandidatesCollection.add( event.candidate.toJSON() )
        })

        // Code for collecting ICE candidates above

        // Code for creating a room below
        const offer = await this.p2p.createOffer()
        await this.p2p.setLocalDescription( offer )
        console.log( 'Created offer:', offer )

        const roomWithOffer = {
          'offer': {
            type: offer.type,
            sdp: offer.sdp,
          },
        }
        await roomRef.set(roomWithOffer)
        console.log(`New room created with SDP offer. Room ID: ${this.room.id}`)
      
        // Code for creating a room above

        this.p2p.addEventListener('track', event => {
          console.log('Got remote track:', event.streams[0])
          event.streams[0].getTracks().forEach( track => {
            console.log('Add a track to the remoteStream:', track)
            this.remote.addTrack( track )
          })
        })

        // Listening for remote session description below
        roomRef.onSnapshot( async snapshot => {
          const data = snapshot.data()
          if ( !this.p2p.currentRemoteDescription && data && data.answer ) {
            console.log('Got remote description: ', data.answer)
            const rtcSessionDescription = new RTCSessionDescription(data.answer)
            await this.p2p.setRemoteDescription(rtcSessionDescription)
          }
        })

        // Listening for remote session description above

        // Listen for remote ICE candidates below
        roomRef.collection('calleeCandidates').onSnapshot( snapshot => {
          snapshot.docChanges().forEach(async change => {
            if (change.type === 'added') {
              let data = change.doc.data()
              console.log(`Got new remote ICE candidate: ${JSON.stringify(data)}`)
              await this.p2p.addIceCandidate(new RTCIceCandidate(data))
            }
          })
        })
        // Listen for remote ICE candidates above
      },

      async join() {
        const roomRef = db.collection('sessions').doc(`${this.room.id}`)
        const roomSnapshot = await roomRef.get()
        console.log('Got room:', roomSnapshot.exists)

        if ( roomSnapshot.exists ) {
          console.log( 'Create PeerConnection with configuration: ', this.configuration )
          this.p2p = new RTCPeerConnection( this.configuration )
          this.registerPeerConnectionListeners()
          this.local.getTracks().forEach( track => this.p2p.addTrack( track, this.local ) )

          // Code for collecting ICE candidates below
          const calleeCandidatesCollection = roomRef.collection( 'calleeCandidates' )
          this.p2p.addEventListener('icecandidate', event => {
            if ( !event.candidate ) {
              console.log('Got final candidate!')
              return
            }
            console.log('Got candidate: ', event.candidate)
            calleeCandidatesCollection.add( event.candidate.toJSON() )
          })
          // Code for collecting ICE candidates above

          this.p2p.addEventListener( 'track', event => {
            console.log( 'Got remote track:', event.streams[0] )
            event.streams[0].getTracks().forEach( track => {
              console.log( 'Add a track to the remoteStream:', track )
              this.remote.addTrack( track )
            })
          })

          // Code for creating SDP answer below
          const offer = roomSnapshot.data().offer
          console.log( 'Got offer:', offer )
          await this.p2p.setRemoteDescription( new RTCSessionDescription(offer) )
          const answer = await this.p2p.createAnswer()
          console.log('Created answer:', answer)
          await this.p2p.setLocalDescription( answer )

          const roomWithAnswer = {
            answer: {
              type: answer.type,
              sdp: answer.sdp,
            }
          }
          await roomRef.update(roomWithAnswer)
          // Code for creating SDP answer above

          // Listening for remote ICE candidates below
          roomRef.collection('callerCandidates').onSnapshot( snapshot => {
            snapshot.docChanges().forEach( async change => {
              if ( change.type === 'added' ) {
                let data = change.doc.data()
                console.log(`Got new remote ICE candidate: ${JSON.stringify(data)}`)
                await this.p2p.addIceCandidate( new RTCIceCandidate( data ) )
              }
            })
          })
        }
      },

      registerPeerConnectionListeners() {
        this.p2p.addEventListener('icegatheringstatechange', () => {
          console.log( `ICE gathering state changed: ${this.p2p.iceGatheringState}` )
        })

        this.p2p.addEventListener('connectionstatechange', () => {
          console.log( `Connection state change: ${this.p2p.connectionState}` )
        })

        this.p2p.addEventListener('signalingstatechange', () => {
          console.log( `Signaling state change: ${this.p2p.signalingState}` )
        })

        this.p2p.addEventListener('iceconnectionstatechange ', () => {
          console.log( `ICE connection state change: ${this.p2p.iceConnectionState}` )
        })
      }
    },

    created() {
      this.open()
    }
  }

</script>

<style scoped lang='stylus'>
  #session
  
  #buttons
    button 
      margin 16px
      background rgba( 0 0 0 .5 )
      padding 8px 24px
      color rgb( 240 240 240 )
      font-weight normal

  #videos 
    display: flex
    flex-direction: row
    flex-wrap: wrap
    > video 
      background: black
      width: 640px
      height: 100%
      display: block
      margin: 1em
</style>
