<template lang='pug'>
  #w-counter.component 
    w-flex.counters
      .counter
        label DAYS
        .value {{ days.toString().padStart( 2, '0' ) }}
      .counter
        label HOURS
        .value {{ hrs.toString().padStart( 2, '0' ) }}
      .counter
        label MINS
        .value {{ mins.toString().padStart( 2, '0' ) }}
      .counter
        label SECS
        .value {{ secs.toString().padStart( 2, '0' ) }}

</template>

<script>
export default {
  name: 'w-counter.component',

  props: {
    countdown: Date
  },

  data() {
    return {
      today: new Date().getTime()
    }
  },

  computed: {
    timeleft() {
      return this.countdown - this.today
    },

    days() {
      return Math.floor( this.timeleft / 86400000 )
    },

    hrs() {
      return Math.floor( (this.timeleft%86400000) / 3600000 )
    },

    mins() {
      return Math.floor( ( (this.timeleft % 86400000) % 3600000 ) / 60000 )
    },

    secs() {
      return Math.floor( ( ( (this.timeleft % 86400000) % 3600000) % 60000 ) / 1000 )
    },
  },

  methods: {
    stoptimer() {
      clearInterval(this.starttime)
    }
  },

  mounted() {
    this.starttime = setInterval( () => 
      this.today = new Date().getTime(), 
      1000
    )
  },

  beforeDestroy() {
    this.stoptimer()
  },
}
</script>

<style scoped lang='stylus'>
  #w-counter 
    .counter
      margin 8px
      font-weight bold
      margin auto
      label
        display block
        text-align center
        margin 8px 0
      .value
        font-size 2em
        color rgb( 240 100 100 )
        background rgb( 240 240 240 )
        border-radius 7px
        padding 16px 24px
        box-shadow 4px 4px 4px rgba( 0 0 0 .25 )
        @media(max-width: 380px)
          padding 12px 18px

</style>