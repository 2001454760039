<template lang='pug'>
  #myfollows.view
    header
      w-wrapper
        w-flex
          h4.title {{ $t('My Follows')}}
      
    section.myfollows
      w-wrapper.mainbody
        .followers.container
          span.bold.em12 {{ $t("Followers") }} ({{followers.length}})
          w-divider.card.borderless
          agile( :options='myOptions' v-if='followers.length' :key='`followers-${key}`')
            .followers-container(v-for='item,i in followers' :key='i')
              profile-card(:uid='item.fid')
            template(slot='prevButton')
              left-icon.previous(:class="{none: nonefollowers}")
            template(slot='nextButton')
              right-icon.next(:class="{none: nonefollowers}")
          .no-followers(v-else)
            p {{ $t("You do not have any followers yet.") }} 

        w-divider.page.borderless

        .following.container
          span.bold.em12 {{ $t("Following") }}  ({{followings.length}})
          w-divider.card.borderless
          agile( :options='myOptions' v-if='followings.length' :key='`followings-${key}`' )
            .following-container(v-for='item,i in followings' :key='i')
              profile-card(:uid='item.lid')
            template(slot='prevButton')
              left-icon.previous(:class="{none: nonefollowings}")
            template(slot='nextButton')
              right-icon.next(:class="{none: nonefollowings}")
          .no-followers(v-else)
            p {{ $t("You are not following anyone yet.") }} 
    w-divider.section
</template>

<script>
  import { db } from '@/main'
  import { Viewable } from '@/mixins/authenticable'

  export default {
    name: 'my-follows.view',

    mixins: [ Viewable ],

    data() {
      return {
        key: 0,
        followers: [],
        followings: [],
        myOptions: {
          infinite: false,
          responsive: [
            {
              breakpoint: 1080,
              settings: {
                slidesToShow: 5,
                navButtons: true
              }
            },

            {
              breakpoint: 860,
              settings: {
                slidesToShow: 4,
                navButtons: true
              }
            },

            {
              breakpoint: 680,
              settings: {
                slidesToShow: 3,
                navButtons: true
              }
            },

            {
              breakpoint: 480,
              settings: {
                slidesToShow: 2,
                navButtons: true
              }
            },

            {
              breakpoint: 430,
              settings: {
                slidesToShow: 2.2,
                navButtons: false
              }
            },

            {
              breakpoint: 380,
              settings: {
                slidesToShow: 1.6,
                navButtons: false
              }
            },

            {
              breakpoint: 310,
              settings: {
                slidesToShow: 1.6,
                navButtons: false
              }
            },
          ]
        },
        streamers: [],
      }
    },

    watch: {
      user(curr, prev) {
        console.log(curr.uid)
        if ( curr.uid ) {
          this.$binding( 'followings', db.collection( 'followings' ).where( 'fid', '==', this.user.uid ) )
          this.$binding( 'followers', db.collection( 'followings' ).where( 'lid', '==', this.user.uid ) )
        }
      }
    },

    computed: {
      nonefollowers() {
        let w = window.innerWidth
        
        switch(true) {
          case ( w < 1080 && w > 860): 
            if ( this.followers.length <= 4 ) return true
            else return false
          break;

          case ( w < 860 && w > 680 ): 
            if ( this.followers.length <= 3 ) return true
            else return false
          break;

          case ( w < 680 && w > 480 ): 
            if ( this.followers.length <= 2 ) return true
            else return false 
          break;

          default: 
            if ( this.followers.length > 5 ) return false
            else return true
        }
      },

      nonefollowings() {
        let w = window.innerWidth
        
        switch(true) {
          case ( w < 1080 && w > 860): 
            if ( this.followings.length <= 4 ) return true
            else return false
          break;

          case ( w < 860 && w > 680 ): 
            if ( this.followings.length <= 3 ) return true
            else return false
          break;

          case ( w < 680 && w > 480 ): 
            if ( this.followings.length <= 2 ) return true
            else return false 
          break;

          default: 
            if ( this.followings.length > 5 ) return false
            else return true
        }
      }
    },

    firestore() {
      return {
        streamers: db.collection( 'profiles' )
      }
    },

    created() {
      window.addEventListener( 'resize', () => 
        this.key++
      )
      if ( this.user.uid ) {
        this.$binding( 'followings', db.collection( 'followings' ).where( 'fid', '==', this.user.uid ) )
        this.$binding( 'followers', db.collection( 'followings' ).where( 'lid', '==', this.user.uid ) )
      }
    },
  }
</script>

<style scoped lang='stylus'>
  #myfollows 
    .w-wrapper
      padding 10px 18px 10px 18px
    .mainbody
      background rgba( 255 255 255 .95)
      border-radius 5px
      padding 50px

  header
    margin-top -30px
    background rgba( 80 160 210 .95 )
    color white  
    @media( max-width: 420px)
      font-size: .8em
      padding 0px

  section
    background rgba( 0 0 0 .5 )
    padding 20px

  .no-following, .no-followers
    background rgba( 80 80 80 .3 )
    padding 42px
    text-align center
    color white
    border-radius 9px
    margin 8px 0
  .view-all 
    width 80px
    border-radius 13px
    color white
    background rgb( 0 125 250 )
    position absolute
    top -4px
    right 20px
    cursor pointer
    padding 4px 8px
      
  .previous, .next
    position absolute 
    top 0
    bottom 50px
    margin auto 0
    font-size 8em
    color rgb( 160 160 160 )
    cursor pointer
    &.none 
      display none
  .previous
    left -98px
    @media(max-width: 1200px)
      left -50px
    @media(max-width: 1080px)
      left -60px
  .next
    right -98px
    @media(max-width: 1200px)
      right -50px
    @media(max-width: 1080px)
      right -60px
</style>
