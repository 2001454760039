<template lang='pug'>
  .messages.component
    .window
      .message( v-for='msg in messages' :class='{ mine: user.uid == msg.uid }' ) 
        span.author {{ msg.profile.id }}: 
        span.body {{ msg.body }}

    .send
      textarea( v-on:keyup.enter="submit" )
</template>

<script>
  import { db } from '@/main'

  export default {
    name: 'button.combo.component',

    props: {
      session: Object,
      user: Object,
      profile: Object
    },

    data() {
      return {
        messages: []
      }
    },

    firestore() {
      return {
        messages: db.collection( 'sessions' ).doc( this.session.id ).collection( 'messages' ).orderBy( 'date', 'desc' )
      }
    },

    methods: {
      submit(e) {
        db.collection( 'sessions' ).doc( this.session.id ).collection( 'messages' ).add({
          date: new Date(),
          uid: this.user.uid,
          profile: this.profile,
          body: e.target.value
        })
        e.target.value = ''
      }
    }
  }
</script>

<style scoped lang='stylus'>
  .messages
    position relative
    height 32vh

  .window
    padding 16px
    height 70%
    overflow hidden
    display flex
    flex-direction column-reverse

    .message
      padding 16px 12px
      margin-top auto
      
    .message.mine
      // background rgba( 0 0 0 .25 )

  .send
    border-top 1px solid rgb( 0 0 0 .5 )
    textarea
      border-top 1px solid rgba( 255 255 255 .16 )
      padding 16px
      min-height 2.4em
      height 40%
      box-sizing border-box
</style>
