import { db } from '@/main'

export const Inbox = {
  name: 'inbox.mixin',

  data() {
    return {
      current: 0,
      sent: [],
      received: [],
      messages: [],
      threads: []
    }
  },

  firestore() {
    if ( this.profile.id ) return {
      threads: db.collection( 'threads' ).where( 'ids', 'array-contains', this.profile.id )
      // received:  db.collection( 'threads' ).where( 'dst.id', '==', this.profile.id )
    }
  },

  watch: {
    profile( curr, prev ) {
      if ( curr.id ) {
        this.$binding( 'threads', db.collection( 'threads' ).where( 'ids', 'array-contains', this.profile.id ) )
        // this.$binding( 'received', db.collection( 'threads' ).where( 'dst.id', '==', this.profile.id ) )
      }
    },

    current( curr, prev ) {
      if ( this.threads.length ) {
        let ref = db.collection( 'threads' ).doc( this.threads[this.current].id ).collection( 'messages' ).orderBy( 'date', 'desc' )
        this.$binding( 'messages', ref )
      }
    },

    threads( curr, prev ) {
      
      if ( curr.length ) {
        console.log('threads')
        this.current = this.threads.map( t => t.id ).indexOf( this.$route.params.current )

        let ref = db.collection( 'threads' ).doc( curr[this.current].id ).collection( 'messages' ).orderBy( 'date', 'desc' )
        this.$binding( 'messages', ref )
        
      }
    }
  },

  // computed: {
  //   threads() {
  //     return this.sent.concat( this.received ).sort( (t1, t2) => t2.date - t1.date )
  //   }
  // }
}

export const Threads = {
  name: 'threads.mixin',

  methods: {
    create( org, dst ) {
      let date = new Date()
      let ids = [ org.id, dst.id ].sort()
      let id = `${ids[0]}<->${ids[1]}` 
      db.collection( 'threads' ).doc( id ).set({ ids, date })
    },

    send( e ) {
      let date = new Date()
      let author = this.profile
      let body = e.target.value
      let ref = db.collection( 'threads' ).doc( this.threads[this.current].id ).collection( 'messages' )
      e.target.value = ''
      ref.add({ author, date, body })
    },

    async populated( t ) {
      let ref = await db.collection( 'threads' ).doc( t.id ).collection( 'messages' ).orderBy( 'date', 'desc' )
      let msgs = ref.get()
      console.log(msgs)
      
      return !!msgs.docs.length
      
    }
  }
}

export const Writable = {
  methods: {
    remove(collection, target, type) {
      let ref = db.collection( collection ).doc( target.id )
      let msg = `Are you sure you want to delete ${type}: "${target.title}"?`
      if ( confirm( msg ) ) ref.delete().then( () =>
        this.items = this.items.filter( item => item.id != target.id )
      )
    },

    set(collection, target) {
      db.collection( collection ).doc( target.id ).set( target, { merge: true } )
    },

    update(collection, target) {
      db.collection( collection ).doc( target.id ).update( target )
    },
  }
}

export const Sociable = {
  methods: {
    associate( collection, fid, lid ) {
      let id = `${fid}->${lid}`
      let following = { 
        lid: lid, 
        fid: fid, 
        followed: { on: new Date() },

      }
      db.collection( collection ).doc( id ).set( following, { merge: true } ).catch( e => alert(e))
    },

    dissociate( collection, fid, lid ) {
      let id = `${fid}->${lid}`
      db.collection( collection ).doc( id ).delete().catch( e => alert(e))
    },

    // async associated( collection, fid, lid ) {
    //   let id = `${fid}->${lid}`
    //   let doc = await db.collection( collection ).doc( id ).get()
    //   console.log(doc, 'associated')
    //   return doc
    // }
  },

  // computed: {
  //   following() {
  //     return `${this.user.uid}->${this.teacher.uid}`
  //   }
  // }
}

export const Applicable = {
  methods: {
    sign_up() {
      if ( this.confirm == this.password )
        auth.createUserWithEmailAndPassword( this.email, this.password )
        .then( doc => {
          db.collection('profiles').doc(this.slug).set({
            uid: doc.user.uid,
            email: doc.user.email || this.email || '',
            phone: doc.user.phoneNumber || this.phone || '',
            display: this.display || '',
            gender: '',
            location: '',
            locale:  this.$i18n ? this.$i18n.locale : localStorage.getItem('locale'),
          }, {merge: true}).then( () => this.$router.push({ name: 'profile' }))
        })
        .catch( e => this.message = e )
      else
        this.message =  'Password and confirmation does not match' 
    },

    social(provider) {
      switch (provider) {
        case 'google':
          provider = new firebase.auth.GoogleAuthProvider()
          break
        case 'facebook':
          provider = new firebase.auth.FacebookAuthProvider()
          break
      }
      auth.signInWithPopup(provider).then( this.accountify ).catch( e => alert(e) )
      this.message = 'Welcome to WorldTone, please wait while we re-direct you'
      setTimeout( () => {this.message = ''}, 2500)
      setTimeout( () => {this.$router.push('/basic-info')}, 2000 )
    }
  }
}

export const Attachable = {
  methods: {
    upload(e, field) {

      let file = e.target.files[0]
      let ref = str.ref(`/user/${this.user.uid}/avatars/${file.name}`)
      ref.put(file).then( snap => {
        snap.ref.getDownloadURL().then( url => {
          this.user.updateProfile({
            photoURL: url
          }).then( () => this.key++ )
          db.collection( 'accounts' ).doc( this.account.id ).update({
            avatar: url
          }).then( () => this.key++ )
        })
      })

      // let reader  = new FileReader()
      // reader.addEventListener('load', () => {
      //   this.$set(this.profile, 'avatar', reader.result)
      //   // this.profile.avatar = reader.result
      // }, false)

      // let file = e.target.files[0]
      // if (file && file.size <= 999999) reader.readAsDataURL(file)
      // else this.message = 'File size must be under 1 mb'
    }

  }
}