<template lang='pug'>
  #message.view
    header
      w-wrapper
        w-flex
          h4.title {{ $t('Messages')}}
    
    section
      w-wrapper.mainbody
        .sidebar
          .user.thread.actionable( 
            v-for='thread, i in threads' 
            :class='{ current: current == i }'
            @click='current = i' 
          )
            avatar( :id='display( thread )' )
            .userinfo
              .acctname {{ display( thread ).display }}
              .mesgdate {{ date( thread.date.toDate() ) }}
        .currentuser
          span(v-if='threads[current]')
            avatar.current-user( :id='display( threads[ current ] )' :key='current' )
            span {{ display( threads[ current ] ).display }}
            
        .body.current
          message( 
            v-for='msg, i in messages' 
            :msg='msg' 
            :hide='hide( i, msg )'
            :key='msg.id'
            :class='{ mine: msg.author.id == profile.id }'
          )
        .mesg.sendable
          textarea(
            :placeholder='$t("Type a message and press enter")'
            @keyup.enter='send'
          )
</template>

<script>
  import { Viewable } from '@/mixins/authenticable'
  import { Inbox, Threads } from '@/mixins/firebase'
  import Message from '@/components/inbox/message'
  import Avatar from '@/components/inbox/avatar'
  
  export default {
    name: 'message.view',

    mixins: [ Viewable, Inbox, Threads ],

    components: {
      Message,
      Avatar
    },

    methods: {
      display( thread ) {
        return thread.ids.find( id => id != this.profile.id )
      },

      hide( i, curr ) {
        if ( !this.messages[ i - 1 ] ) return false
        return this.messages[ i - 1 ].author.id == curr.author.id 
      },

      date( d ) {
        return `${d.getMonth()}/${d.getDate()} ${d.getHours()}:${d.getMinutes()}`
      }
    }
  }
</script>

<style scoped lang='stylus'>
  #message
    .w-wrapper 
      padding 0px 0px 0px 0px
              
  header
    margin-top -30px
    margin-bottom 0px
    background rgba( 80 160 210 .95 )
    color white  
    padding 10px
    @media( max-width: 420px)
      font-size: .8em

  section
    background rgba( 0 0 0 .5 )
    padding 10px
    .mainbody
      display grid
      grid-template-columns 230px 1fr
      grid-template-rows 40px 1fr 100px
      grid-column-gap 1px
      grid-row-gap 1px
      overflow hidden
      @media( max-width: 560px)
        grid-template-columns 50px 1fr
        grid-template-rows 40px 1fr 100px
      .sidebar
        grid-area 1/1/3/2
        background rgba( 255 255 255 .95)
        border-radius 5px 0px 0px 0px
        padding 2px
        overflow-y auto
        height 577px
        .user
          display grid
          position relative
          grid-template-columns 1fr 1fr
          border-radius 5px
          padding 10px
          margin-bottom 2px
          background rgb( 220 220 220 )
          align-items center
          &.current
            background rgb( 240 160 0 )
            color white
          .userinfo
            .acctname
              font-weight bold
              margin-right 10px
            .mesgdate
              margin-left auto
              text-align right
              margin-right 5px
              font-size .8em
              line-height 1.4
            @media( max-width: 560px)
              display none
        @media( max-width: 640px)
          font-size .8em
      .currentuser
        grid-area 1/2/2/3
        background rgba(255 255 255 .95)
        border-radius 0px 5px 0px 0px
        padding 10px
      .body
        grid-area 2/2/3/3
        height 520px
        padding 10px
        background rgba( 255 255 255 .95)
        overflow hidden
        overflow-y scroll
        display flex
        flex-direction column-reverse
      .mesg
        grid-area 3/1/4/3
        background rgba( 255 255 255 .95)
        border-radius 0px 0px 5px 5px
        padding 10px
        textarea
          resize none
</style>
