<template lang='pug'>
  .w-header.component
    slot
      w-wrapper.container
        w-flex.centered.container.flex-end
          router-link.logo(to='/')
            img(src='/logo-full.svg')           

          .auth-links
            router-link.charge(:to='{ name: "charge" }') {{ $t('Charge Up') }}
            router-link(to='/sign-in') {{ $t('Sign In') }}
            span.divider |
            router-link(to='/sign-up') {{ $t('Sign Up') }}
          
          //- select(v-model='locale' @change='translate')
          //-   option(value='en') English
          //-   option(value='kr') 한국어
          //-   option(value='ch') 中文简体
          //-   option(value='zh') 中文繁體
          //-   option(value='jp') 日本語
          
          //- .menu.toggle.actionable( @click.stop='$emit("update:open", true)' v-visible='!open' )
          //-   menu-icon
</template>

<script>
  import { Translatable } from '@/mixins/view'
  import { auth, db } from '@/main'

  export default {
    name: 'w-header.component',

    mixins: [ Translatable ],

    props: {
      open: { type: Boolean, default: false },
      menu: { type: Boolean, default: false }
    },

    data() {
      return {
        show: { profile: { links: false } },
      }
    },

    created() {
      this.$i18n.locale = localStorage.getItem('locale')
    },

    methods: {
      out() {
        auth.signOut().then( () => {
          db.collection( 'profiles' ).doc( this.profile.id ).update({ status: 'offline' }).then( doc =>
            this.$router.push('/') 
          )
        })
      }
    }
  }
</script>

<style scoped lang='stylus'>
  .w-header
    justify-content flex-end
    @media( max-width 480px )
      .logo
        img
          width 175px
    .auth-links
      color white
      padding-bottom 2px
      margin-left auto
      .divider
        margin 0 4px
      .charge 
        margin-right 24px
        @media( max-width: 960px )
          display block
          text-align right
          margin-right 0
          margin-bottom 4px
      .auth
        @media( max-width: 960px )
          display block
  .logo
    padding 16px 0
    margin-right auto
    img
      width 200px
</style>
