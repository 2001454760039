<template lang='pug'>
  #my-topics.view
    header
      w-wrapper
        w-flex
          h4.title {{ $t('Chat Topics')}}
    base-modal(v-if='show.modal' :key='key')
      modal-wrapper
        .close.actionable( @click='close' )
          close-icon

        form.container(@submit.prevent='update')
          fieldset
            label {{ $t("Topic Title") }}:
            input(
              type='text' 
              :placeholder='$t("Topic Title")'
              v-model='topic.title'
              required
            )

          .dual
            fieldset
              label {{ $t("Points per Minute") }}:
              input(
                type='number' 
                :placeholder='$t("Points per Minute")'
                v-model='topic.points'
                required
              )

            fieldset
              label {{ $t("Duration") }}:
              input(
                type='number' 
                :placeholder='$t("Number of Minutes")'
                v-model='topic.duration'
                required
              )

          .dual
            fieldset
              label {{ $t("Main Language") }}:
              input(
                :placeholder='$t("Main Language")'
                v-model='topic.main'
                required
              )

            fieldset
              label {{ $t("Other Languages") }}:
              input(
                :placeholder='$t("Other Languages")'
                v-model='topic.other'
              )

          fieldset
            vue-tags-input(
              v-model="tag"
              :tags="topic.tags"
              @tags-changed="newTags => topic.tags = newTags"
            )

          fieldset
            label {{ $t("Description") }}:
            textarea(
              :placeholder='$t("Enter a Description")'
              v-model='topic.desc'
              required
            )
          
          w-images(
            ref='images'
            collection='topics'
            :user='user'  
            :parent_id='topic.id'
          )

          .submit-btn.centered-text
            w-button(type='submit')
              template(v-slot:text) {{ $t("Save") }}

    section.my-topics
      w-wrapper
        .topics
          topic.new.actionable( @click.native='create' )
            add-icon
          topic.actionable(
            v-for='item in topics'
            :key='item.id'
            :item='item'
            @click.native='edit(item)'
          )
    w-divider.section
</template>

<script>
  import { db, auth, str } from '@/main'
  import BaseModal from '@/components/modals/base'
  import ModalWrapper from '@/components/modals/wrapper'
  import VueTagsInput from '@johmun/vue-tags-input'

  export default {
    name: 'my-topics.view',

    components: {
      BaseModal,
      ModalWrapper,
      VueTagsInput
    },

    props: {
      user: { type: Object, default: () => ({}) },
      profile: { type: Object, default: () => ({}) }
    },

    data() {
      return {
        key: 0,
        topics: [],
        topic: {},
        show: { modal: false },
        tag: '',
        tags: []
      }
    },

    firestore() {
      if (this.user.uid) {
        return {
          topics: db.collection( 'topics' ).where( 'uid', '==', this.user.uid 
        )}
      }
    },

    watch: {
      user(curr, prev) {
        if (curr.uid) this.$binding( 'topics', db.collection( 'topics' ).where( 'uid', '==', this.user.uid ) ) 
      }
    },

    methods: {
      create() {
          this.topic = { uid: this.user.uid, pid: this.profile.id }
          this.show.modal = true 
      },

      update() {
        if ( this.topic.id ) db.collection( 'topics' ).doc( this.topic.id ).update( this.topic ).then( () => {
          this.$refs.images.set()
          this.show.modal = false
        })
        else db.collection( 'topics' ).add( this.topic ).then( doc => {
          this.$refs.images.set( doc.id )
          this.show.modal = false
        })
      },

      edit(item) {
        this.topic = item
        this.show.modal = true
      },

      upload(e, field) {
        let reader  = new FileReader()
        reader.addEventListener('load', () => {
          this.$set(this.profile, 'avatar', reader.result)
        }, false)

        let file = e.target.files[0]
        if (file) reader.readAsDataURL(file)
      },

      close() {
        this.show.modal = false 
      }
    }
  }

</script>

<style scoped lang='stylus'>
  #my-topics
    .w-wrapper
      padding 10px 18px 10px 18px
    .mainbody
      background rgba( 255 255 255 .95)
      border-radius 5px
      padding 50px
    .close
      top 0px
      right 0px
      z-index 1
      padding 4px 12px

  header
    margin-top -30px
    background rgba( 80 160 210 .95 )
    color white  
    @media( max-width: 420px)
      font-size: .8em
      padding 0px

  section
    background rgba( 0 0 0 .5 )
    padding 20px

  h1
    margin-bottom 16px

  .topics
    display grid
    grid-template-columns 1fr 1fr
    grid-gap 32px 64px
    @media(max-width: 680px)
      grid-template-columns 1fr
  
  .submit-btn
    margin-top 32px

  form
    background white
    padding 24px
    width 420px
    max-height 80vh
    overflow-y auto
    @media( max-width: 480px )
      width calc( 88vw - 48px )

  fieldset
    margin 16px 0

  .dual
    display grid
    grid-template-columns 1fr 1fr
    grid-gap 32px

  label
    display block
    margin 8px

  input, textarea
    display block
    background rgb( 240 240 240 )
    border-radius 9px
    padding 8px 8px
    border 1px solid rgb( 225 225 225 )
    box-sizing border-box
    width 100%

  // input
  //   width 540px

  textarea
  //   width 540px
    height 128px

  a
    display block
    text-align right
    font-size .8em
    color blue
    margin 4px
</style>
