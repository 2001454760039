<template lang='pug'>
  .video-session.modal.component
    video#local-stream( ref='local' muted="" autoplay="" playsinline="" )
    video#remote-stream( ref='remote' autoplay="" playsinline="" )
    
    messages#messages( v-if='session' v-show='show.messages' :session='session' :user='user' :profile='profile' )

    .buttons
      iconic-button.sound.round( @click.native='sound_on = !sound_on' :class='{ inactive: !sound_on }' )
        sound-on-icon( v-if='sound_on' )
        sound-off-icon( v-else )
        
      iconic-button.chat.round( @click.native='show.messages = !show.messages' :class='{ inactive: !show.messages }' )
        chat-icon

    iconic-button.close( @click.native='close' )
      close-icon

</template>

<script>
  import { db, auth, str } from '@/main'
  import IconicButton from '@/components/buttons/iconic'
  import Messages from '@/components/messages/base'

  export default {
    name: 'video-session.modal.component',

    components: {
      IconicButton,
      Messages
    },

    props: {
      user: { type: Object, default: () => ({}) },
      profile: { type: Object, default: () => ({}) },
      session: { type: Object, default: () => ({}), required: true }
    },

    data() {
      return {
        show: { messages: false },
        sound: { on: true },
        sound_on: true,
        local: null,
        remote: null,
        p2p: null,
        configuration: {
          iceServers: [
            {
              urls: [
                'stun:stun1.l.google.com:19302',
                'stun:stun2.l.google.com:19302',
              ],
            },
          ],

          iceCandidatePoolSize: 10
        },

        messages: []
      }
    },

    firestore() {
      return {
        messages: db.collection( 'sessions' ).doc( this.session.id ).collection( 'sessions' )
      }
    },

    computed: {
      room() {
        return { id: `${this.session.request.profile.uid}->${this.session.request.host.uid}` }
      },

      host() {
        return this.user.uid == this.session.request.host.uid
      }
    },

    watch: {
      sound_on( curr, prev ) {
        // console.log(changed)
        if ( this.local.getAudioTracks().length > 0 ) this.local.getAudioTracks()[0].enabled = curr
      }
    },

    methods: {
      close() {
        if ( confirm('This will end the session permanantly.  Are you sure?') )
          db.collection( 'sessions' ).doc( this.session.id ).delete()
      },

      async open() {
        this.local = await navigator.mediaDevices.getUserMedia({ video: true, audio: true })
        this.$refs.local.srcObject = this.local
        this.remote = new MediaStream()
        this.$refs.remote.srcObject = this.remote
        if ( this.host ) this.create()
        else this.join()
      },

      async create() {
        const roomRef = await db.collection('rooms').doc( this.room.id )
        this.p2p = new RTCPeerConnection( this.configuration )

        this.registerPeerConnectionListeners()

        this.local.getTracks().forEach( track => {
          console.log('track obtained', track)
          this.p2p.addTrack( track, this.local )
        })

        // collecting ICE candidates
        const callerCandidatesCollection = roomRef.collection( 'callerCandidates' )

        this.p2p.addEventListener( 'icecandidate', event => {
          if ( event.candidate ) callerCandidatesCollection.add( event.candidate.toJSON() )
        })

        // creating a room
        const offer = await this.p2p.createOffer()
        await this.p2p.setLocalDescription( offer )

        const roomWithOffer = {
          'offer': {
            type: offer.type,
            sdp: offer.sdp,
          },
        }
        await roomRef.set(roomWithOffer)
      
        this.p2p.addEventListener('track', event => {
          this.remote = event.streams[0]
          this.$refs.remote.srcObject = event.streams[0]
          // event.streams[0].getTracks().forEach( track => {
          //   this.remote.addTrack( track )
          // })
        })

        // Listening for remote session description
        roomRef.onSnapshot( async snapshot => {
          const data = snapshot.data()
          if ( !this.p2p.currentRemoteDescription && data && data.answer ) {
            const rtcSessionDescription = new RTCSessionDescription(data.answer)
            await this.p2p.setRemoteDescription(rtcSessionDescription)
          }
        })

        // Listen for remote ICE candidates
        roomRef.collection('calleeCandidates').onSnapshot( snapshot => {
          snapshot.docChanges().forEach(async change => {
            if (change.type === 'added') {
              let data = change.doc.data()
              await this.p2p.addIceCandidate(new RTCIceCandidate(data))
            }
          })
        })
      },

      async join() {
        const roomRef = db.collection('rooms').doc(`${this.room.id}`)
        const roomSnapshot = await roomRef.get()

        if ( roomSnapshot.exists ) {
          this.p2p = new RTCPeerConnection( this.configuration )
          this.registerPeerConnectionListeners()
          this.local.getTracks().forEach( track => this.p2p.addTrack( track, this.local ) )

          // collecting ICE candidates
          const calleeCandidatesCollection = roomRef.collection( 'calleeCandidates' )
          this.p2p.addEventListener('icecandidate', event => {
            if ( event.candidate ) calleeCandidatesCollection.add( event.candidate.toJSON() )
          })


          this.p2p.addEventListener( 'track', event => {
            this.remote = event.streams[0]
            this.$refs.remote.srcObject = event.streams[0]
            // event.streams[0].getTracks().forEach( track => {
            //   this.remote.addTrack( track )
            // })
          })

          // creating SDP 
          const offer = roomSnapshot.data().offer
          await this.p2p.setRemoteDescription( new RTCSessionDescription(offer) )
          const answer = await this.p2p.createAnswer()
          await this.p2p.setLocalDescription( answer )

          const roomWithAnswer = {
            answer: {
              type: answer.type,
              sdp: answer.sdp,
            }
          }
          await roomRef.update(roomWithAnswer)

          // Listening for remote ICE candidates
          roomRef.collection('callerCandidates').onSnapshot( snapshot => {
            snapshot.docChanges().forEach( async change => {
              if ( change.type === 'added' ) {
                let data = change.doc.data()
                await this.p2p.addIceCandidate( new RTCIceCandidate( data ) )
              }
            })
          })
        }
      },

      registerPeerConnectionListeners() {
        this.p2p.addEventListener('icegatheringstatechange', () => {
          console.log( `ICE gathering state changed: ${this.p2p.iceGatheringState}` )
        })

        this.p2p.addEventListener('connectionstatechange', () => {
          console.log( `Connection state change: ${this.p2p.connectionState}` )
        })

        this.p2p.addEventListener('signalingstatechange', () => {
          console.log( `Signaling state change: ${this.p2p.signalingState}` )
        })

        this.p2p.addEventListener('iceconnectionstatechange ', () => {
          console.log( `ICE connection state change: ${this.p2p.iceConnectionState}` )
        })
      }
    },

    created() {
      this.open()
    }
  }

</script>

<style scoped lang='stylus'>
  #messages
    background linear-gradient(
      180deg,
      rgba( 0 0 0 0 ),
      rgba( 0 0 0 .5 )
    )
    color white
    grid-column 1 / -1
    position absolute
    bottom 0
    width calc( 100% - 20vw )
    box-sizing border-box

  #remote-stream
    height 100vh
    width 100vw
  
  #local-stream
    height 16vh
    width 16vw
    position absolute
    bottom 1.6vh
    right 1.6vw
    border-radius 9px

  .video-session
    .buttons
      position absolute
      bottom 20vh
      right 1.6vw
      font-size 1.6em
      .button
        display block
        margin-top 24px

      .button.inactive
        background rgba( 0 0 0 .1 )
        color rgb( 192 192 192 )
        box-shadow none

    .close
      background rgba( 0 0 0 .1 )
      color rgb( 192 192 192 )
      right 1.6vw
      top .8vh
      font-size 1.2em

    > video 
      height 100vh
      width 100vw
      display block
      object-fit cover
        
</style>
