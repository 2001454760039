export const View = {
  name: 'view',
  props: ['user', 'account', 'biz', 'applications', 'srvs', 'csts', 'modals']
}

export const Translatable = {
  name: 'translatable',

  data() {
    return {
      locale: this.$i18n.locale || 'en'
    }
  },

  methods: {
    translate() {
      this.$i18n.locale  = this.locale
      if ( !this.account ) localStorage.setItem( 'locale', this.locale )
    }
  }
}