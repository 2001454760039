<template lang='pug'>
  .w-overlay
    .heading
      slot(name='heading')
    .close(@click='$emit("close")')
      close-icon
    .content
      slot
</template>

<script>
  export default {
    name: 'w-overlay'
  }
</script>

<style scoped lang='stylus'>
  .heading
    margin-bottom 12px
    padding 16px 48px 0 28px
  .close
    position absolute
    right -1px
    top -2px
    font-size 1.5em
    background rgb( 240 80 40 )
    color white
    padding 2px 8px 3px 8px
    border-radius 3px
    border-top-left-radius 0
    box-shadow var(--shadow-box-shallow)
  .content
    padding 24px
    padding-top 12px

  .w-overlay
    position relative
    top 0
    left 0
    border-radius 5px
    height auto
    min-height 100%
    width 100%
    box-sizing border-box
    // background rgba( 255 255 255 .88 )
    z-index 3
    // box-shadow var(--shadow-box-shallow)

  .w-overlay.solid
    background rgb( 252 252 252 )

  .w-overlay.flush
    box-shadow none
    .content
      padding 0
</style>
