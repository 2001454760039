<template lang='pug'>
  .avatar.component
    img( :src='profile.avatar' )
    span {{ profile.display }}
</template>

<script>
  import { db } from '@/main'

  export default {
    name: 'avatar.component',

    props: {
      id: { type: String, required: true }
    },

    data() {
      return {
        profile: {}
      }
    },

    firestore() {
      return {
        profile: db.collection( 'profiles' ).doc( this.id )
      }
    }
  }
</script>

<style lang='stylus' scoped>
  .avatar
    display flex
    align-items center
    img
      width 30px
      height 30px
      object-fit cover
      border-radius 50%
      background rgb( 255 255 255)
      margin-right 10px
    span
      font-size .8em
  
  .avatar.current-user
    width 20px
    height 20px
</style>