<template lang='pug'>
  .w-images.component
    input(
      type='file'
      ref='upload'
      style='display: none'
      accept='image/*'
      :key='key'
      @change='upload($event, images)' multiple
    )
    sortable.sortable.thumbs(
      v-if='user'
      v-model='images'
      handle='.image-container'
    )
      template(v-slot:header)
        w-flex.upload-container.actionable.centered(@click.native='$refs.upload.click()')
          .icon
            add-icon
          span {{ $t('Add Images') }}

      .image-container(v-for='image, i in images' :key='`image-${i}-${image.id}`')
        .delete(@click='del(image, i)')
          delete-icon
        w-flex.centered.draggable
          draggable-icon
        img(:src='image.src')

    w-grid.sortable.thumbs(
      v-else
      v-model='images'
      handle='.image-container'
    )
      .image-container(v-for='image, i in images' :key='`image-${i}-${image.id}`')
        img(:src='image.src' @click='imageshow = i')

      .image-big(v-if='imageshow != "hide"')
        img(:src='images[imageshow].src')
        .close(@click='imageshow = "hide"')
          close-icon
</template>

<script>
  import { db } from '@/main'
  
  import Sortable from 'vuedraggable'

  export default {
    name: 'digi-images.component',

    props: [ 'collection', 'parent_id', 'user' ],

    components: {
      Sortable
    },

    data() {
      return {
        key: 0,
        images: [],
        imageshow: 'hide'
      }
    },

    firestore() {
      if ( this.parent_id ) return {
        images: db.collection( this.collection ).doc( this.parent_id ).collection( 'images' ).orderBy('index')
      }
    },

    methods: {
      upload(e, images) {
        Array.from( e.target.files ).forEach( file => {
          let reader  = new FileReader()
          reader.addEventListener('load', () => {
            images.push( {src: reader.result} )
            this.$emit('changed')
          }, false)

          reader.readAsDataURL(file)
        })
      },

      set( pid=this.parent_id ) {
        this.images.forEach( ( image, i ) => {
          let doc = db.collection( this.collection ).doc( pid )
          if ( image.id ) doc.collection( 'images' ).doc( image.id ).set( {...image, ...{ index: i } }, { merge : true } )
          else doc.collection( 'images' ).add( {...image, ...{ index: i } } )
        })
        this.$binding('images', db.collection( this.collection ).doc( pid ).collection( 'images' ).orderBy('index') )
      },

      del(image, i) {
        db.collection( this.collection ).doc( pid ).collection( 'images' ).doc( image.id ).delete()
      }
    }
  }
</script>

<style scoped lang="stylus">
  .w-images
    width 100%
  .sortable
    display grid
    grid-template-columns repeat( auto-fill, minmax( 128px, 1fr ) )
    grid-gap 2.8vh 2.8vw
    grid-auto-rows 128px
    @media( max-width: 480px )
      grid-template-columns repeat( auto-fill, minmax( 96px, 1fr ) )
      grid-auto-rows 96px

  .image-container
    border-radius 13px
    overflow hidden
    position relative
    cursor grab
    .delete
      position absolute
      top 0
      right 0
      color white
      padding 4px 8px
      background rgba( 0 0 0 .25 )
      border-bottom-left-radius 13px
      z-index 2
    .draggable
      position absolute
      height 100%
      width 100%
      background radial-gradient(circle at center, rgba( 0 0 0 .3) , rgba( 0 0 0 .1 ), rgba( 0 0 0 0 ) 100%)
      border-top-right-radius 12px
      font-size 2em
      color white
      z-index 1
    img
      width 100%
      height 100%
      object-fit cover

  .upload-container
    flex-direction column
    background rgb( 240 240 240 )
    border-radius 13px
    height 100%
    width 100%
    border 2px dashed rgba( 0 0 0 .1 )
    .icon
      font-size 2em
    span
      font-size .88em
      font-weight bold
      
  .upload-container.save
    background var(--clr-blue)
    color var(--clr-light)

  .image-big 
    position fixed
    top 0
    bottom 0
    left 0
    right 0
    margin auto
    height 600px
    width 450px
    @media(max-width: 420px)
      height 80vh
      width 100vw
      box-sizing border-box
      border 16px solid transparent
    img 
      height 100%
      width 100%
      object-fit cover
    .close 
      position absolute 
      padding 1px
      top 5px
      right 5px
      font-size 22px
      color red
      background rgba( 220 220 220 .5 )
      border-radius 3px
</style>
