<template lang='pug'>
  #rsv-cal.rsv-cal.container.component(v-if='!rsv')
    vue-cal.cal.vuecal--blue-theme.calendar(
      default-view="week"
      small
      :time-step='30'
      :disable-views="['years', 'year', 'day', 'month']"
      :selected-date="new Date()"
      /* :time-from='opening' */
      /* :time-to='closing' */
      /* :twelveHour='true' */
      /* :on-event-click='view' */
      :events="events"
      @cell-click='set'
    )

  w-overlay.flush(v-else @close='rsv = false')
    h5.section-heading {{ $t('Reservation/Appointment Details') }}

    rsv-dtls.overlay(
      v-if='rsv'
      :rsv='rsv'
    )

    w-divider.card
    .centered-text  
      w-button.wide(@click.native='reserve')
        template(v-slot:text) {{ $t('Make Reservation') }}

    //- rsv-msgs#rsv-msgs(
    //-   v-if='rsv && account'
    //-   :rsv='rsv'
    //-   :account='account'
    //-   :key='rsv.id'
    //- )
</template>

<script>
  import { db } from '@/main'
  import VueCal from 'vue-cal'
  import 'vue-cal/dist/vuecal.css'

  export default {
    name: 'rsv-cal.component',

    components: {
      VueCal
    },

    props: {
      user: Object,
      profile: Object,
      topic: [ Object, Array ],
      // options: { type: Object, default: () => ({
      //   perspective: {
      //     merchant: true,
      //     customer: true
      //   }
      // })}
    },

    data() {
      return {
        rsv: false,
        rsvs: [],
        orders: [],

      }
    },

    firestore() {
      return {
        rsvs: db.collection('rsvs').where( 'hid', '==', this.topic.uid ),
      }
    },

    computed: {
      // opening() {
      //   if ( !this.biz.hours || !this.biz.hours.opening ) return 8 * 60
      //   let d = new Date(this.biz.hours.opening)
      //   let mins = d.getHours() * 60 + d.getMinutes()
      //   return mins
      // },

      // closing() {
      //   if ( !this.biz.hours || !this.biz.hours.closing ) return 20 * 60
      //   let d = new Date(this.biz.hours.closing)
      //   let mins = d.getHours() * 60 + d.getMinutes()
      //   return mins
      // },

      events() {
        return this.rsvs.map( r => ({ 
          // ...r, 
          // {
            startDate: r.start.toDate(),
            endDate: r.end.toDate(),
            class: 'customer'
            // class: this.klass(r)
          // }
        }))
        
      },
    },

    methods: {
      reserve() {
        
        db.collection( 'rsvs' ).add( this.rsv ).then( doc => 
          this.$emit('rsvs', this.rsv)
        ).then( () => this.rsv = false )
      },

      set(start) {
        if ( !start ) return

        let mins = start.getMinutes()
        start.setMinutes(0)
        if ( mins > 30 ) start.setMinutes(30)

        // let service = this.services.find( s => s.id == this.service_id )
        let end = new Date(start)

        end.setMinutes(
          start.getMinutes() +
          parseInt( this.topic.duration - 1 )
        )



        let rsv = {
          tid: this.topic.id,
          
          hid: this.topic.uid,
          pid: this.topic.pid,
          topic: this.topic,
          reserved: { 
            on: new Date(),
            by: { uid: this.user.uid, profile: this.profile }
          },
          start: start, 
          end: end
        }

        if( start > new Date() ) this.rsv = rsv
        // If no overlaps allow reservation.  Else prompt for another datetime
        // let available = !this.events.filter( e => e.startDate < end && e.startDate > start ).length
        // console.log(available)
        // if ( available && start > new Date() ) {
        //   db.collection( 'rsvs' ).add( rsv ).then( doc => 
        //     console.log(doc)
        //   )
        // }

      },

      // klass(evt) {
      //   let styles = ''

      //   if ( evt.end.toDate() > new Date() ) {
      //     if ( evt.account_id == this.account.id ) styles += 'customer'
      //     else styles += 'merchant'
      //     if ( evt.status == 'Pending Cancellation' ) styles += ' cancellation'
      //     if ( evt.status == 'Canceled' ) styles = 'cancellation'
      //   }

      //   return styles
      // },

      view(evt) {
        // this.rsv = evt
        console.log(evt)
      }
    },

    mounted() {
      // let el = document.getElementById('rsv-cal')
      // if ( el ) el.scrollTo(0, 767)
    }
  }
</script>

<style scoped lang='stylus'>
  .section-heading
    padding 4px 8px 24px 8px

  .rsv-cal
    // max-height calc( 100vh - var(--height-top-nav) - var(--height-merchant-nav) - 8px )
    // max-height 480px
    // overflow-y auto
    .cal
      margin-top 16px

  >>> .customer
    background gray
    color white
    .vuecal__event-time
      display none

  >>> .merchant
    background green
    color white
  >>> .cancellation
    border 2px solid red
  >>> .vuecal__flex.vuecal__title, >>> .vuecal__flex.weekday-label
    font-weight bold
    
  >>> .vuecal__header
    position absolute
    font-size 0.7em
    width 100%
    top 0
    .vuecal__menu
      display none

  >>> .vuecal__body
    margin-top 70px
    max-height 320px

  >>> .vuecal__cell.selected
    background rgb(120 120 244)
  >>> .weekday-label
    font-weight normal
  >>> .vuecal__heading.today
    font-weight bold
    background rgb(54 131 149)
    color white
  >>> .vuecal__cell.today
    background rgb(54 131 149)
    color orange
  >>> .vuecal__cells.month-view
    font-weight bold
    font-size 1em
  >>> .vuecal__time-column
    font-weight normal 
    font-size 0.8em
    background rgb(54 131 149)
  >>> .vuecal__time-cell
    color white
  >>> .vuecal--month-view
    height 450px
  >>> .vuecal--month-view.today
    color orange
  >>>  .vuecal__cell-events-count
    background orange 
  >>> .vuecal__now-line
    color red
    border-top: 3px solid
</style>
