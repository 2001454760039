<template lang="pug">
  button.close(type='button')
    close-icon
</template>

<script>
  export default {
    name: 'close.button'
  }
</script>

<style lang="stylus" scoped>
  .close
    font-size 1.25em
    border-radius 50%
    border 1.5px solid rgb( 240 160 80 )
    height 33px
    width 34px
    display flex
    justify-content center
    align-items center
    transition all 320ms
    &:hover, &:focus
      border-style solid
      box-shadow var(--shadow-box)
    &:active
      transform scale(.88)
    span
      margin-bottom 4px
</style>