<template lang='pug'>
  .w-editor.component
    //- (v-if='props.user && mode.edit')
    //- .functions
    //-   digi-btn.images.round.small(title='Manage Gallery' @click.native='open.images = true' v-show='article.id')
    //-     template(slot='icon')
    //-       images-icon

    //-   digi-btn.export.round.small(title='Export HTML' @click.native='open.export=true' v-show='article.html')
    //-     template(slot='icon')
    //-         export-icon

    //-   digi-btn.delete.round.small(title='Delete' @click.native='$emit("delete")' v-show='article')
    //-     template(slot='icon')
    //-       delete-icon

    //- .meta
    //-   input.date-input(type='date' v-model='article.date')
    //-   .types
    //-     .type.selector
    //-       select(v-model='article.type' @change='update')
    //-         option(disabled value='') Post Type
    //-         option(value='article') Article
    //-         option(value='review') Review
    //-         option(value='guide') Guide
    //-         option(value='copy') Copy

    //-     .type.selector
    //-       select(v-model='article.status' @change='update')
    //-         option(value='draft') Draft
    //-         option(value='publish') Publish

    //-   digi-input.title
    //-     input(type='text' v-model='article.title' placeholder='Please Enter a Title (Required)' @change='update')
    //-   digi-input.subtitle
    //-     input(type='text' placeholder='Enter a Subtitle' v-model='article.subtitle' @change='update')
    //-   .excerpt
    //-     textarea(placeholder='Enter a Description' v-model='article.excerpt' @change='update')

    quill-editor#quill.editor(
      ref="editor"
      :class='{scrolled: scrolled}'
      :options="options"
      @blur="blur"
      @focus="focus"
      @ready="ready"
      @change='change'
      :content='contents'
    )

    //- .metrics
    //-   .words Word Count: {{ words }}

    //- #export-container(v-show='open.export')
    //-   digi-btn.round.small.close(@click.native='open.export=false')
    //-     template(slot='icon')
    //-       close-icon
    //-   .wrapper
    //-     section
    //-       h1 Raw HTML
    //-       .html {{ article.html }}
    //-     section
    //-       h1 Text
    //-       .text {{ article.text }}

</template>

<script>
  import { db } from '@/main'
  import 'quill/dist/quill.core.css'
  import 'quill/dist/quill.snow.css'
  import 'quill/dist/quill.bubble.css'

  import { quillEditor } from 'vue-quill-editor'

  export default {
    name: 'w-editor.component',

    components: {
      quillEditor
    },

    props: [ 'contents' ],

    // props: {
    //   user: Object,
    //   // id: String,
    //   // collection: String,
    //   // type: String,
    //   // item: Object
    //   contents: String
    // },

    data() {
      return {
        mode: {edit: false},
        open: {images: false, export: false},
        // type: 'article',
        saving: false,
        interval: null,
        scrolled: false,
        distance: 320,
        // article: {},
        images: [],
        options: {
         // some quill options
        }
        // author: this.user.email,
      }
    },

    firestore() {
      return {
        // projects: db.collection('projects').where('user_id', '==', )
        // article: db.collection('posts').doc(this.$page.article.id),
        // images: db.collection('articles').doc(this.$page.article.id).collection('images')
      }
    },

    // computed: {
    //   words() {
    //     return this.article.text ? this.article.text.split(" ").length : 0
    //   }
    // },

    methods: {
    //   scroll(e) {
    //     let distance = this.mode.edit ? 420 : 128
    //     if ( e.target.scrollingElement.scrollTop > distance )
    //       this.scrolled = true
    //     else
    //       this.scrolled = false
    //   },

      blur(quill) {
       // console.log('editor blur!', quill)
      //  this.update()
      },

      focus(quill) {
       // console.log('editor focus!', quill)
      },

      ready(quill) {
       // console.log('editor ready!', quill)
      },

      update() {
        // this.saving = true
        let ref = db.collection('posts').doc( this.article.slug )
        this.article.edited = new Date()
        // let dt = new Date(this.article.date)
        // let yr = dt.getFullYear().toString()
        // let mo = (dt.getMonth()+1).toString().padStart (2, '0' )
        // let dy = dt.getDate().toString().padStart( 2, '0')
        // let path = `articles/${yr}/${mo}/${dy}/${slugify(this.article.title.toLowerCase())}`
        // this.article.path = path
        ref.set( this.article, { merge: true } )
        // .then( () => this.saving = false )
      },

      change({ quill, html, text }) {
        this.$emit('update:contents', html)
      },
    },

    // created() {
    //   this.interval = setInterval( this.update, 10000 )
    //   this.article = this.$page.article
    //   window.addEventListener('scroll', this.scroll)
    // },

    // destroyed() {
    //   window.addEventListener('scroll', this.scroll)
    // },

    // beforeDestroy() {
    //   clearInterval( this.interval )
    // }
  }
</script>

<style scoped lang='stylus'>
  .transparent
    background rgba( 0 0 0 .1 )
    transition all 320ms

  nav.scrolled
    opacity 0

  header
    width 100%
    height 480px
    margin-top -96px
    position relative
    color var(--clr-blue)
    @media(max-width: 1080px)
      height 420px
    @media(max-width: 480px)
      height 360px
    img
      display block
      height 100%
      width 100%
      object-fit cover
      object-position center

    .title
      position absolute
      bottom 0
      left 0
      height 100%
      width 100%
      display flex
      flex-direction column
      justify-content flex-end
      align-items flex-start
      background linear-gradient(
        180deg,
        rgba( 0 0 0 .6 ),
        rgba( 255 255 255 .0 ),
        rgba( 255 255 255 .6 ),
        rgba( 255 255 255 .9 )
      )
      .wrapper
        text-shadow 0 0 4px rgba( 255 240 225 1 )
        width 100%
        border-left 6px solid var(--clr-orange)
        margin-bottom 24px
        // background linear-gradient(
        //   90deg,
        //   rgba( 255 245 240 .25 ),
        //   rgba( 255 245 240 .00 ),
        //   rgba( 255 245 240 .00 )
        // )
      h1
        //
      .subtitle
        margin-top 0
        font-family inherit
        // font-style: italic;
      p
        font-family Courgette
        font-size 1.2em
        margin-top 24px

  .major-buttons
    position fixed
    bottom 2vh
    right 4vw
    z-index 3
    display flex
    .btn
      transition all 320ms
      margin-left 12px
      &.saving
        transform rotateZ(360deg) scale(1.25)

  .content
    line-height 1.6
    .excerpt
      // font-style italic
      font-size 1.12em
      padding 32px 0 36px 0
      border-bottom 1px dotted
      font-family Courgette
    .body
      margin 36px 0
      // font-size 1.08em
      >>> p
        font-size 1.08em
  .wrapper
    position relative

  .functions
    margin-left auto
    margin-bottom 32px
    display flex
    width auto
    .component
      margin-left 8px
    @media( max-width: 360px )
      position static
      margin-bottom 24px
      .component
        margin 0 12px

  #digi-gallery, #export-container
    position fixed
    width 100vw
    height 100vh
    top 0
    left 0
    z-index 8
    background var(--bg-body)
    overflow-y auto

  #export-container
    .close
      position fixed
      right 32px
      top 16px
    .wrapper
      box-sizing border-box
      max-width 1080px
      padding 4.2vh 4.2vw

      margin 0 auto
    section
      margin-top 64px
      &:first-child
        margin 0
    h1
      margin-bottom 16px

  .title, .subtitle
    input
      display block
      padding 16px
      padding-bottom 8px
      box-sizing border-box
      width 100%

  .subtitle
    margin-top 32px

  .excerpt
    textarea
      width 100%
      background 0
      box-sizing border-box
      padding 16px
      border-bottom 1px solid rgba( 0 0 0 .1 )
      outline 0
      font-family inherit
      height 112px

  #quill.editor
    font-family inherit
    box-shadow 0 0 32px rgba( 0 0 0 .1 ), 0 0 4px 2px rgba( 255 255 255 1 ) inset
    text-shadow var(--shadow-text)
    margin-top 24px
    z-index 999
    &.scrolled
      >>> .ql-toolbar
        position fixed
        background var(--bg-body)
        box-shadow var(--shadow-box)
        top 0
        width 100vw
        left 0
        border 0
        border-bottom 1px solid rgb(250 250 250)
        z-index 2
        display flex
        flex-wrap wrap
        justify-content center
        padding 16px 0
        transition all 320ms
        .ql-formats
          display flex
          margin 4px 0
          margin-left 16px
          &:first-child
            margin-left 0
        @media(min-width: 1080px)
          text-align center
    >>> .ql-editor
      font-size 1.24em
      padding 24px 16px
      color var(--clr-text)

  .types
    margin-bottom 32px
    display flex
    padding 0 13px
    .selector
      margin-left 16px
      &:first-child
        margin 0

  .metrics
    max-width 1148px
    margin 16px auto
    display flex
    padding 4px 16px
    justify-content space-between

  .date-input
    padding 0 16px
    margin-bottom 24px
</style>
