<template lang='pug'>
  router-link.topic.card.component( :to='{ name: "topic", params: { id: item.id } }' )
    img.featured(:src='featured' @click='$router.push({ name: "topic", params: { id: item.id } })')
    w-flex.body.column
      router-link( :to='{ name: "topic", params: { id: item.id } }' )
        h6.title {{ item.title }}
      p.desc {{ item.desc }}
      footer
        span 
          span.value {{ item.duration }} 
          span {{ $t("mins")}}
        span 
          span.value {{ item.points }}
          span {{ $t("Pts")}}
</template>

<script>
import { db } from '@/main'
export default {
  name: 'topic-card.component',

  props: {
    item: Object,
    collection: { type: String, default: 'images' }
  },

  data() {
    return {
      images: []
    }
  },

  firestore() {
    return {
      images: db.collection( 'topics' ).doc( this.item.id ).collection( this.collection )
    }
  },

  computed: {
    featured() {
      return this.images.length ? this.images[0].src : '/no-image.jpg'
    }
  },
}
</script>

<style scoped lang='stylus'>
  .topic
    padding 12px
    display grid
    grid-template-columns 124px 1fr
    grid-gap 16px
    img.featured
      width 100%
      height 124px
      display block
      margin-right 16px
      object-fit cover
      cursor pointer
    .body
      width 100%
      footer
        margin-top auto
        font-size .88em
        display flex
        justify-content space-between
        width 100%
        span
          margin-right 2px
          color rgb( 240 100 100 )
          .value
            font-weight bold
        a
          // margin-left auto
          color rgb( 100 200 240 )
          font-weight bold
</style>
