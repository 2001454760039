<template lang='pug'>
  .w-divider.component
</template>

<script>
  export default {
    name: 'w-divider'
  }
</script>

<style lang='stylus'>
  .w-divider
    width 100%
    .text
      display none

    &.on-dark
      border-top 1px solid rgba( 0 0 0 .16 )
      border-bottom 1px solid rgba( 255 255 255 .12 )

    &.with-text
      border 0
      display flex
      width 100%
      min-height 1px
      border-top 1px solid rgba( 0 0 0 .15 )
      .text
        color black
        display block
        margin 0 auto
        background white
        margin-top -.7em
        padding 0 16px
        font-weight bold

    &.page
      margin 20px 0
      &.with-text
        margin-bottom 20px

    &.section
      margin 20px 0
      &.with-text
        margin-bottom 20px

    &.card
      margin 15px 0

    &.tight
      margin 2px 0

    &.bordered
      border-top 1px solid rgba( 0 0 0 .05 )
      border-bottom 1px solid rgba( 255 255 255 .75 )
</style>
