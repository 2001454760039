import { db, auth } from '@/main'

export const Authenticable = {
  data() {
    return {
      user: {},
      profiles: [],
      reqs: [],
      sessions: []
    }
  },

  created() {
    auth.onAuthStateChanged( user => {
      this.user = user
      if ( user ) {
        this.$binding( 'authentication', db.collection( 'authentications' ).doc( user.uid ) )
        this.$binding( 'profiles', db.collection( 'profiles' ).where('uid', '==', user.uid) ).then( docs => 
          db.collection( 'profiles' ).doc( docs[0].id ).update({ status: 'online' })
        )
        this.$binding( 'reqs', db.collection( 'requests' ).where( 'status', '==', 'pending' ).where( 'host.uid', '==', user.uid ) )
        this.$binding(
          'sessions', db.collection( 'sessions' )
          .where( 'uids', 'array-contains', user.uid )
          .where( 'status', '==', 'current' )
        )
        // if ( this.$route.name == 'sign-in' || this.$route.name == 'sign-up' ) this.$router.push({ name: 'home' })
      }
      else if ( !this.$route.meta.static ) this.$router.push('/')
      else this.$binding( 'reqs', db.collection( 'requests' ).where( 'status', '==', 'nullified' ) )
    })
  }
}

export const Viewable = {
  props: {
    user: { type: Object, default: () => ({}) },
    profile: { type: Object, default: () => ({}) }
  }
}