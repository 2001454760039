<template lang='pug'>
  .nav-drawer.component( :class='{ open: open }' )
    slot
      nav-links( :user='user' @close='$emit("update:open", false)' )
</template>

<script>
  export default {
    name: 'nav-drawer.component',

    props: {
      open: { type: Boolean, default: false },
      user: { type: Object, default: () => ({}) }
    }
  }
</script>

<style scoped lang='stylus'>
  .nav-drawer
    position fixed
    top 0
    right -100%
    z-index 8
    transition all 320ms
    max-height 100vh
    overflow-y auto
    &.open
      right 0
    // .link
    //   &.toggle:hover
    //     background 0
    //     transform scale(1)
    //   &:first-child
    //     border-top 0
    //   &:last-child
    //     border-bottom 0
    // .router-link-active
    //   color white
    //   filter drop-shadow( 0 0 4px rgba(255 255 255 .5) )
</style>
