<template lang='pug'>
  #phone-sign-in.view
    w-notice(:message='message' @close='message = ""')
    transition
      w-wrapper.centered-text
        form.sign-up-form(@submit.prevent='sign_up' v-show='!confirmed')
          h5 {{ $t("Enter Phone Number Below to Receive Phone Verification Code") }}

          w-divider.section

          w-wrapper.smallest
            fieldset
              vue-tel-input( 
                :enabledCountryCode='true' 
                :required='true'
                :placeholder='$t("Phone Number")' 
                @input='telegraph'
              )

          w-divider.section

          w-button#sign-in-button(type='submit') 
            template(slot='text') {{ $t("Send Code") }}

          w-divider.section

          p {{ $t("Or") }}

          w-divider.section

          router-link(to='/sign-in')
            p {{ $t("Sign in with email / social media?") }}
        
        form.sign-up-form(v-show='confirmed' @submit.prevent='verify')
          h5 {{ $t("Please enter the verification code sent to your mobile number") }}
          w-divider.section
          w-wrapper.smallest
            w-input.basic
              input( required type='text' :placeholder='$t("SMS Verfication Code")' v-model='code')

          w-divider.section
          w-button(type='submit')
            template(slot='text') {{ $t('Sign In') }}
          w-divider.section
          p {{ $t("Didn't receive code?") }}
          w-divider.card

          w-button.small.narrower(@click.native='confirmed = null') 
            template(slot='text') {{ $t('Send Code Again') }}
</template>

<script>
  import { auth, firebase, db } from '@/main'
  import { Authenticable } from '@/mixins/authenticable'
  export default {
    name: 'phone-sign-in.view',

    mixins: [ Authenticable ],

    data() {
      return {
        confirmed: null,
        language: 'English',
        show: false,
        phone: '',
        code: '',
        recaptchaVerifier: null,
        user: null,
        credential: null,
        message: ''
      }
    },

    methods: {
      telegraph( input, obj ) {
        let { number, isValid, country } = obj
        console.log(number.e164)
        this.phone = number.e164
      },

      sign_up() {
        firebase.auth().signInWithPhoneNumber(this.phone, this.recaptchaVerifier)
          .then( confirmationResult => {
            console.log('confirmationResult')
            this.confirmed = confirmationResult
            // this.message = this.$t('SMS verfication code has been sent.  Please enter the code below and sign in!')
          }).catch( e =>  alert(e)  )
      },

      verify() {
        this.confirmed.confirm(this.code).then( result => {
          this.accountify(result)
          this.user = result.user
          this.credential = firebase.auth.PhoneAuthProvider.credential(this.confirmed.verificationId, this.code)
          // this.message = this.$t(`You have successfully signed in via phone number `) + `(${this.user.phoneNumber})`
          this.message = 'Welcome to WorldTone, please wait while we re-direct you'
          setTimeout( () => {this.message = ''}, 2500)
          setTimeout( () => {this.$router.push('/basic-info')}, 2000 )
        }).catch( e => this.message = e )
      }
    },

    mounted() {
      this.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('sign-in-button', {
        size: 'invisible',
        callback: function(response) {
          console.log(response)
          onSignInSubmit()
        }
      })
    },

    created() {
      if ( this.$route.params.id ) db.collection( 'customers' ).doc( this.$route.params.id ).get().then( doc => {
        this.phone = doc.data().phone
        this.referral = { id: doc.data().business_id }
      })
    }
  }
</script>

<style scoped lang='stylus'>
  .sign-up-form 
    background rgb(255 255 255)
    max-width 480px
    border-radius 15px
    margin 32px auto
    padding 32px 164px
    box-shadow 2px 2px 8px rgba( 80 80 80 .5 )
    @media(max-width: 640px)
      padding 32px 10%
</style>