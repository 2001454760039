<template lang='pug'>
  .topic.component.container
    slot
      .delete.actionable(@click.stop='remove')
        delete-icon
      w-flex
        .featured
          img(:src='featured')
        .details
          slot(name='details')
            w-flex.space-between
              h6.title {{ item.title }}
              span.duration.notable {{ item.duration }} {{ $t('mins') }}
              span.points.notable ({{ item.points }} {{ $t('Points') }})
          slot(name='snippet')
            p {{ item.desc }}
</template>

<script>
  import { db } from '@/main'

  export default {
    name: 'topic.component',

    props: {
      item: { type: Object, default: () => ({}) },
      collection: { type: String, default: 'topics' }
    },

    data() {
      return {
        images: []
      }
    },

    firestore() {
      if ( this.item.id ) return {
        images: db.collection( this.collection ).doc( this.item.id ).collection( 'images' ).orderBy('index')
      }
    },

    computed: {
      featured() {
        return this.images.length ? this.images[0].src : '/no-image.jpg'
      }
    },

    methods: {
      remove() {
        db.collection( this.collection ).doc( this.item.id ).delete()
      }
    }
  }
</script>

<style scoped lang='stylus'>
  .topic
    background rgb( 240 240 240 )
    border-radius 15px
    overflow hidden
    &.new
      text-align center
      font-size 3.6em
      height auto
      display flex
      align-items center
      justify-content center
      padding-bottom 12px
    
    .delete
      position absolute
      right 0
      background rgba( 255 255 255 .75 )
      padding 4px 8px 5px 10px
      border-bottom-left-radius 15px
      font-size 1.2em

    .featured
      width 160px
      min-height 108px
      @media(max-width: 480px)
        width 50%
      img
        display block
        width 100%
        height 100%
        object-fit cover
    
    .details
      padding 8px 48px 16px 16px
      flex-grow 1
      width calc(100% - 96px)
      .w-flex
        width 100%
        @media(max-width: 580px)
          flex-direction column
        .title, .notable 
          margin 4px 0
      >>> p
        margin-top 8px
</style>
