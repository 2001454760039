<template lang='pug'>
  w-wrapper.session-requests.modal.component.max-480.container
    .reqs
      w-flex.req( v-for='item in reqs' @click.native='accept(item)' :key='item.id' )
        img( :src='item.profile.avatar' )
        .dtls
          h5.ellipsis {{ item.type == 'existing' ? item.topic.title : item.custom }}
          p 
            span {{ item.topic.points }} Pts | 
            span {{ item.type == 'existing' ? item.topic.duration : item.duration }} mins
          w-button.small
            template( v-slot:text ) {{ $t('Accept') }}
    .reject-all.centered-text.actionable( @click='reject' ) {{ $t('Reject All') }}

</template>

<script>
  import { db } from '@/main'

  export default {
    name: 'modal.session-requests.component',

    props: [ 'reqs' ],

    data() {
      return {
        points: 500,
        custom: '',
        ring: new Audio('/alert.mp3')
      }
    },

    methods: {
      accept( r ) {
        let points = r.profile.points -= r.points * r.duration
        db.collection( 'profiles' ).doc( r.profile.id ).update({ points })
        db.collection( 'requests' ).doc( r.id ).update({ status: 'accepted' }).then( () => {
          this.reqs.forEach( r => db.collection( 'requests' ).doc( r.id).delete() )
          this.ring.pause()
        })
        let doc = { 
          status: 'current', 
          accepted: { on: new Date() },
          request: r,
          uids: [ r.profile.uid, r.host.uid ]
        }
        db.collection( 'sessions' ).doc( `${r.profile.uid}->${r.host.uid}` ).set( doc )
      },

      reject() {
        this.reqs.forEach( r => db.collection( 'requests' ).doc( r.id).update({ status: 'rejected' }) )
        this.ring.pause()
      }
    },

    created() {
      this.ring.addEventListener('ended', function() {
        this.currentTime = 0
        this.play()
      }, false )
      this.ring.play()
    }
  }
</script>

<style scoped lang='stylus'>
  .reject-all
    position absolute
    left 0
    bottom 0
    width 100%
    background red
    color white
    padding 16px 0
    font-weight bold
    box-shadow 0 0 16px 0 rgba( 0 0 0 .5 )

  .session-requests.component
    background white
    border-radius 7px
    padding 0
    overflow hidden

  .reqs
    max-height calc(100vh - 128px)
    overflow-y scroll
    padding 0 32px
    padding-bottom 64px

  .req
    align-items center
    border-top 1px solid rgba( 0 0 0 .1 )
    padding 32px 0
    &:first-child
      border-top 0

    img
      width 88px
      height 84px
      object-fit cover
      display block
    .dtls
      padding 0 16px
      .w-button
        margin-top 8px
</style>

