<template lang='pug'>
  #myposts.view
    header
      w-wrapper
        w-flex
          h4.title {{ $t('My Post')}}

    section.myposts
      w-wrapper.mainbody
        w-modal(:show='show.modal' :key='key')
          form.container(@submit.prevent='update')
            .close.actionable( @click='show.modal = false' )
              close-icon
            fieldset
              label {{ $t("Post Title") }}:
              input(
                type='text' 
                :placeholder='$t("Post Title")'
                v-model='post.title'
              )

            fieldset
              label {{ $t("Sub Title") }}:
              input(
                type='text' 
                :placeholder='$t("Sub Title")'
                v-model='post.subtitle'
              )

            fieldset
              label {{ $t("Post Description") }}:
              textarea(
                :placeholder='$t("Enter a Description")'
                v-model='post.desc'
              )

            w-input(v-show='false')
              suglify(
                :slugify='post.title'
                :slug.sync='slug'
              )
                input.slug(
                  disabled
                  slot-scope='{inputBidding}'
                  v-bind='inputBidding'
                  type='text'
                  placeholder='Slug'
                )


            w-divider.section
            .centered-text
              w-button(type='submit')
                template(v-slot:text) {{ $t("Update") }}
      
        .posts 
          topic.new.actionable( @click.native='create' )
            add-icon

          topic.actionable(
            v-for='item in posts'
            collection='posts'
            :key='item.id'
            :item='item'
            @click.native='edit(item)'
          )
            template( v-slot:details )
              h5.title {{ item.title }}
            template( v-slot:snippet )
              p {{ item.subtitle }}

        //- base-card.container(
        //-   v-for='item in posts' 
        //-   @click.native.stop.prevent='edit(item)'
        //-   )
        //-   h4 {{ item.title }}
        //-   w-divider.card
        //-   h6 {{ item.subtitle }}
        //-   p {{ item.desc }}
        //-   w-divider.card
        //-   p by {{ item.pid }}
        //-   w-flex.configure
        //-     //- .preview-button(@click='preview(item)')
        //-     //-   span Preview
        //-     //- .edit(@click='edit(item)')
        //-     //-   span Edit
        //-     .delete(@click.stop.prevent='trash(item)')
        //-       delete-icon

      //- .preview(v-if='prev')
      //-   h4 {{ previewable.title }}
      //-   w-divider.section
      //-   p(v-html='previewable.content')
      //-   .close(@click='prev = false')
      //-     close-icon



      //- .overlay(v-if='editor || prev' @click='close()')
</template>

<script>
  import { db } from '@/main'
  import { Viewable } from '@/mixins/authenticable'
  export default {
    name: 'my-posts.view',

    mixins: [ Viewable ],

    data() {
      return {
        collection: 'my-posts',
        show: {
          modal: false
        },
        post: {
          title: ''
        },
        key: 0,
        slug: '',
        posts: [],
        editor: false,
        editable: {},
        prev: false,
        previewable: {},
        collection: 'posts'
      }
    },

    firestore() {
      if (this.user.uid) {
        return {
          posts: db.collection( 'posts' ).where( 'uid', '==', this.user.uid )
        }
      }
    },

    watch: {
      user(curr, prev) {
        if (curr.uid) this.$binding( 'posts', db.collection( 'posts' ).where( 'uid', '==', this.user.uid ) ) 
      }
    },

    methods: {
      create() {
        this.show.modal = true
      },
      
      update() {
        // db.collection( 'profiles' ).doc( this.profile.id ).update( this.profile )
        // this.$refs.images.set()
        db.collection( 'posts' ).doc(this.slug).set({
          uid: this.user.uid,
          pid: this.profile.id,
          created: new Date(),
          title: this.post.title,
          subtitle: this.post.subtitle,
          desc: this.post.desc || '',
          slug: this.slug,
          contents: '',
          images: this.post
        }).then( () => {
          this.show.modal = false
          this.post = {
            title: ''
          }
        }).catch( e => alert(e) )
      },

      trash(item) {
        db.collection('posts').doc(item.slug).delete()
      },

      edit(item) {
        this.$router.push({name: "my-post", params: { id: item.slug }})
      },
    }

  }
</script>

<style scoped lang='stylus'>
  #myposts
    .w-wrapper 
      padding 10px 18px 10px 18px
    .mainbody
      background rgba( 255 255 255 .95)
      border-radius 5px
      padding 50px
    .posts
      display grid
      grid-template-columns 1fr 1fr
      grid-gap 32px 64px
      @media(max-width: 680px)
        grid-template-columns 1fr
      .create-post 
        background rgb( 240 240 240 )
        font-size 3.6em
        display flex
        width 100%
        justify-content center
        align-items center
        border-radius 15px
        padding-bottom 8px
      .base-card
        padding 24px
        cursor pointer
        h6 
          margin-bottom 24px
        p:last-child
          text-align right
          margin-top 24px
        .delete
          background rgba( 230 230 230 .5 )
          font-size 1.2em
          padding 12px
          border-bottom-left-radius 13px
          position absolute 
          top 0
          right 0
    // .preview, .editor 
    //   position absolute 
    //   top 0
    //   right 0
    //   bottom 0
    //   left 0 
    //   background white
    //   border-radius 13px
    //   box-shadow var(--shadow-card)
    //   z-index 3
    //   padding 36px 56px
    //   box-sizing border-box
    //   h4
    //     text-align center
    //   .close 
    //     position absolute 
    //     top 25px
    //     right 25px
    //     font-size 2em
    //     background rgba( 190 190 190 .5 )
    //     border-radius 50px
    //     padding 0 4px
    
    // .overlay 
    //   position fixed 
    //   width 100vw
    //   height 100vh 
    //   top 0
    //   left 0
    //   background rgba( 120 120 120 .4 )
    //   z-index 1
    
    form
      background white
      padding 32px
      border-radius 21px
      width 560px
      max-height 80vh
      overflow-y auto
      .w-images
        height 288px
        overflow-y auto
        padding-right 28px
    fieldset
      margin 16px 0

    label
      display block
      margin 8px

    input, textarea
      display block
      background rgb( 240 240 240 )
      border-radius 9px
      padding 8px 8px
      border 1px solid rgb( 225 225 225 )
      box-sizing border-box

    input
      width 320px

    textarea
      width 560px
      height 128px

    a
      display block
      text-align right
      font-size .8em
      color blue
      margin 4px
  header
    margin-top -30px
    background rgba( 80 160 210 .95 )
    color white  
    @media( max-width: 420px)
      font-size: .8em
      padding 0px

  section
    background rgba( 0 0 0 .5 )
    padding 20px
</style>
