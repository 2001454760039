<template lang='pug'>
  flex.base-modal.centered.component
    slot
</template>

<script>
  import Flex from '@/components/containers/flex'

  export default {
    name: 'base-modal.component',

    components: {
      Flex
    },

    props: {
      show: { type: Boolean, default: false },
      scrolled: Boolean
    }
  }
</script>

<style scoped lang='stylus'>
  @keyframes appear
    from
      background-color var(--clr-blue-light)
      border-color var(--clr-blue-light)
      transform scale(0)
      border-width 0
    to
      background-color white
      transform scale(1)

  .base-modal
    height 100vh
    width 100vw
    position fixed
    top 0
    left 0
    z-index 8
    background rgba( 0 0 0 .5 )
    // >>> > .container
    //   animation-name appear
    //   animation-duration 640ms
    //   box-shadow var(--shadow-box)
    //   border-radius 7px
    //   border 1.5px solid rgb( 240 160 80 )
    @media( max-width: 480px )
      >>> > .container
        border 0
        border-radius 0 !important
        height 100vh
        width 100vw !important
        > .close
          right 4px !important
          top 8px !important
</style>
