<template lang='pug'>
  .rsv-dtls.container.component
    rsv-stamp(
      v-if='rsv.status == "Canceled"'
      :text='$t("CANCELED")'
    )
    table
      thead
        //- tr.biz-name(v-if='options.show.biz')
        //-   th {{ $t('Business Name') }}
        //-   td {{ business.name || 'Not Entered' }}
        //- tr.cus-name(v-if='options.show.cus')
        //-   th {{ $t('Customer Name') }}
        //-   td {{ customer.name || 'Not Entered' }}
        //- tr(v-if='options.show.cus')
        //-   th {{ $t('Customer Email') }}
        //-   td {{ customer.email }}
        tr
          th {{ $t('Topic') }}
          td.emphasize {{ rsv.topic.title }}
        tr
          th {{ $t('Points') }}
          td ${{ rsv.topic.points }}
        tr
          th {{ $t('Date') }}
          td.emphasize.enlarge {{ rsv.start.toLocaleDateString() }}
        tr
          th {{ $t('Start Time') }}
          td.emphasize.enlarge {{ rsv.start.toLocaleTimeString([], {hour: 'numeric', minute:'2-digit'}) }}
        tr
          th {{ $t('Duration') }}
          td {{ rsv.topic.duration }} {{ $t('mins') }}
</template>

<script>
  import { db } from '@/main'

  export default {
    name: 'rsv-dtls.component',

    props: {
      rsv: { type: Object, default: () => ({}) },
      options: { type: Object, default: () => ({ show: { biz: false, cus: true } })}
    },

    data() {
      return {
        business: {},
        customer: {},
        service: {}
      }
    },

    // firestore() {
    //   return {
    //     business: db.collection('businesses').doc( this.rsv.business_id ),
    //     customer: db.collection('accounts').doc( this.rsv.account_id ),
    //     service: db.collection('services').doc( this.rsv.service_id )
    //   }
    // }
  }
</script>

<style scoped lang='stylus'>
  .rsv-dtls
    //
  table
    overflow hidden
    width 100%
    text-align left
    font-size 1.02em
    box-sizing border-box
    border-radius 5px
    th, td
      font-size 0.8em
      padding 12px 10px
    th
      background rgba( 0 75 150 .1 )
      text-align right
      width 108px
      font-weight bold
    tr:nth-child(odd)
      background rgba( 0 0 0 .02 )
    .emphasize
      font-weight bold

  .rsv-dtls.overlay
    .biz-name
      // display none
    .cus-name
      // display table-row
</style>
