<template lang='pug'>
  .w-wrapper.component
    slot
</template>

<script>
  export default {
    name: 'w-wrapper.component'
  }
</script>

<style scoped lang='stylus'>
  .w-wrapper
    margin 0 auto
    max-width 960px
    box-sizing border-box
    @media( max-width: 960px )
      padding 0 3.2vw
    @media( max-width: 480px )
      padding 0 1.6vw

  .w-wrapper.text
    max-width 36em

  .w-wrapper.max-200
    max-width 200px
  .w-wrapper.max-280
    max-width 280px
  .w-wrapper.max-480
    max-width 480px
  .w-wrapper.max-640
    max-width 640px
  .w-wrapper.max-800
    max-width 800px
  .w-wrapper.max-960
    max-width 960px
  .w-wrapper.max-1080
    max-width 1080px
  .w-wrapper.max-1280
    max-width 1280px

  .w-wrapper.rounded
    border-radius 15px
    overflow hidden
  .w-wrapper.padded
    padding 20px
</style>
