<template lang='pug'>
  #profile-card.component.container
    .avatar
      img(:src='profile.avatar')
    router-link.user-info.container(:to='{ name: "profile", params: { id: profile.id } }')
      w-flex.ratings.space-evenly(v-if="profile.status == 'online'")
        .icon 
          star-icon
        //- span.rating.long(v-if='profile.rating.length > 6') {{ profile.rating }}
        // span.rating {{ profile.rating || '1,907' }}
      .info.container
        .user-name.ellipsis 
          span {{ profile.display }}
        .user-location.ellipsis 
          span {{ profile.location }}

</template>

<script>
  import { db } from '@/main'

  export default {
    name: 'profile-card.component',

    props: {
      uid: String,
      user: Object
    },

    data() {
      return {
        profile: {},
        profiles: []
      }
    },

    firestore() {
      return {
        profiles: {
          ref: db.collection( 'profiles' ).where( 'uid', '==', this.uid),
          resolve: profiles => this.profile = profiles[0]
        }
      }
    }
  }
</script>

<style scoped lang='stylus'>
  #profile-card
    height 180px
    margin 0 8px
    overflow hidden
    min-width 180px
    .avatar
      height inherit
      width inherit
      box-sizing border-box
      img 
        background white
        object-fit cover
        height 180px
        width 180px
        border-radius 5px
        margin 0 auto
        display block
    .user-info
      position absolute 
      top 0
      left 0
      right 0
      bottom 0
      width 180px
      margin 0 auto
      .info 
        position absolute 
        bottom 0
        left 0
        right 0
        color white
        padding-top 10px
        background linear-gradient(to top, rgba( 50 50 50 .7), transparent)
        border-bottom-right-radius 5px
        border-bottom-left-radius 5px
        .user-name, .user-location 
          display inline-block
          width 50%
        .user-location 
          margin-left auto
          padding-right 8px
          text-align right
          box-sizing border-box
        .user-name 
          padding-left 8px
          box-sizing border-box
      
      .ratings 
        background rgba( 150 150 150 .5 )
        border-radius 15px
        border 1px solid white
        width 25px
        font-size 0.7em
        margin-left 8px
        margin-top 8px
        .icon 
          color rgb( 220 90 90 )
          font-size 1.4em
          line-height 1.2
        span.rating 
          display inline-block 
          margin-top auto
          margin-bottom auto
          color white
          padding-right 2px
</style>
