<template lang='pug'>
  #mypoints.view
    w-wrapper.container
      w-flex.container.sub-header
        .points.container
          w-flex
            h1
              span {{ $t('M') }}
              span {{ $t('y Points') }}
            .current-points
              span.bold {{ $t("Current Points") }}: 
              span.bold.color-red.em13 {{ points }}
        w-flex.buttons.container
          router-link(:to='{ name: "charge" }')
            .button.charge.em10.centered-text {{ $t("Charge Up") }}
          .button.withdraw.em10.centered-text {{ $t("Withdraw") }}

      w-divider.section

      w-flex.active-tab.color-gray
        p.tab(@click='tab = "Purchase"' :class="{active: tab== 'Purchase'}") {{ $t("Purchase Record") }}
        p.tab(@click='tab = "Send"' :class="{active: tab== 'Send'}") {{ $t("Points Send") }}
        p.tab(@click='tab = "Receive"' :class="{active: tab== 'Receive'}") {{ $t("Points Received") }}
        p.tab(@click='tab = "Withdraw"' :class="{active: tab== 'Withdraw'}") {{ $t("Withdraw Record") }}

      w-divider.card

      w-flex.column.sessions-info
        w-flex.info-title
          w-flex.space-between.front
            p {{ $t("Purchase Date") }}
            p {{ $t("Paid By") }}
          p.received {{ $t("Points Received") }}
        .info-detail
          w-flex.no-purchase
            p.em13.color-gray(v-if='!session') {{ $t("No purchase history.") }}
      
</template>

<script>
  import { Viewable } from '@/mixins/authenticable'

  export default {
    name: 'my-points.view',

    mixins: [ Viewable ],

    data() {
      return {
        tab: 'Purchase',
        session: null
      }
    },

    computed: {
      points() {
        return this.profile.points || 0
      }
    }
  }
</script>

<style scoped lang='stylus'>
  #mypoints 
    .w-wrapper
      border-radius 21px
      background white
      padding 0 18px 48px 18px
      .sub-header
        @media(max-width: 860px)
          flex-direction column
        .current-points
          padding 8px 20px
          background rgb(240 240 240)
          height 30px
          line-height 30px
          margin auto 0
          margin-top 8px
          margin-left 40px
          border-radius 9px
          box-shadow 2px 2px 8px rgba( 80 80 80 .5 ), -1px 1px 4px rgba( 80 80 80 .5 )
          @media(max-width: 380px)
            margin-left auto
            margin-right 8px

      .active-tab
        font-size 1.1em
        @media(max-width: 680px)
          font-size 1em
        .tab
          margin-right 48px
          padding-bottom 4px
          display inline-block
          cursor pointer
          @media(max-width: 800px)
            margin 0 auto
          @media(max-width: 680px)
            text-align center
          @media(max-width: 580px)
            width 80px
        .active 
          border-bottom 5px solid rgb( 130 130 130 )

      .info-title
        background rgb( 180 180 180 )
        color white
        padding 16px 48px
        border-top-right-radius 13px
        border-top-left-radius 13px
        @media(max-width: 480px)
          padding 16px
        .front 
          width 35%
          @media(max-width: 480px)
            width 58%
        .received
          margin-left auto
      .info-title p
        @media(max-width: 380px)
          width 25% 
          text-align center

      .info-detail
        min-height 200px
        border 1px solid rgb( 180 180 180 )
        background white
        .no-purchase 
          justify-content center
          margin-top 32px

  .buttons 
    margin-left auto
    @media(max-width: 860px)
      margin-right auto
      margin-left 0
      margin-top 16px
    @media(max-width: 420px)
      margin 16px auto 0 auto
  .button
    padding 3px 42px
    background var(--clr-blue)
    border-radius 21px
    color white
    cursor pointer
    margin-top 13px
    height 30px
    line-height 30px
    @media(max-width: 420px)
      padding 6px 28px
  .charge
    margin-right 26px
  .withdraw
    left 590px
</style>