<template lang='pug'>
  #w-fields.component 
    fieldset
      label Post Title:
      input(
        type='text' 
        placeholder='Post Title'
        :value='title'
        @input='$emit("update:title", $event.target.value)'
      )

    fieldset
      label Sub-Title:
      input(
        type='text' 
        placeholder='Sub-Title'
        :value='subtitle'
        @input='$emit("update:subtitle", $event.target.value)'
      )

    fieldset
      label Description:
      textarea(
        type='text' 
        placeholder='Description'
        :value='desc'
        @input='$emit("update:desc", $event.target.value)'
      )
    

      //- fieldset
      //-   label Sub-Title:
      //-   input(
      //-     type='text' 
      //-     placeholder='Sub-Title'
      //-     v-model='post.subtitle'
      //-   )
      
      //- fieldset
      //-   label Description:
      //-   textarea(
      //-     type='text' 
      //-     placeholder='Description'
      //-     v-model='post.desc'
      //-   )
</template>

<script>
  import { db } from '@/main'
  import { Viewable } from '@/mixins/authenticable'
  export default {
    name: 'w-fields.component',

    mixins: [ Viewable ],

    props: ['title', 'subtitle', 'desc' ],

    methods: {
      // save() {
      //   db.collection(this.collection).doc(this.post.slug).set( this.post, { merge: true } )
      // }
    }

  }
</script>

<style scoped lang='stylus'>
    form
      background white
      // padding 32px
      border-radius 21px
      width 560px
      max-height 80vh
      overflow-y auto
      .w-images
        height 288px
        overflow-y auto
        padding-right 28px
    fieldset
      margin 16px 0

    label
      display block
      margin 8px

    input, textarea
      display block
      background rgb( 240 240 240 )
      border-radius 9px
      padding 8px 8px
      border 1px solid rgb( 225 225 225 )
      box-sizing border-box

    input
      width 320px
      @media(max-width: 360px)
        width 100%

    textarea
      width 560px
      height 128px
      @media(max-width: 580px)
        width 100%

    a
      display block
      text-align right
      font-size .8em
      color blue
      margin 4px


</style>