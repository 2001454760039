<template lang='pug'>
  #contactus.view
    header
      w-wrapper
        w-flex
          h4.title {{ $t('Contact Us')}}
    
    section.myposts
      w-wrapper.mainbody

      
</template>

<script>
  import { Viewable } from '@/mixins/authenticable'
  
  export default {
    name: 'contactus.view',

    mixins: [ Viewable ],
    
  }
</script>

<style scoped lang='stylus'>
  
  #contactus
    .w-wrapper 
      padding 10px 18px 10px 18px
    .mainbody
      background rgba( 255 255 255 .95)
      border-radius 5px
      padding 50px
  header
    margin-top -30px
    background rgba( 80 160 210 .95 )
    color white  
    @media( max-width: 420px)
      font-size: .8em
      padding 0px
  section
    background rgba( 0 0 0 .5 )
    padding 20px
    
</style>
