<template lang='pug'>
  button.follow.button.component( :type='type' :title='title' )
    slot
      button.active( 
        v-if='following'
        :class='{ hovering }'
        @click='socialize' 
        @mouseover='hovering = true'
        @mouseout='hovering = false'
      ) {{ hovering ? $t('Unfollow') : $t('Following') }}
      button.inactive( v-else @click='socialize' ) {{ $t('Follow') }}
</template>

<script>
  import { db } from '@/main'
  import { Sociable } from '@/mixins/firebase'

  export default {
    name: 'follow.button.component',

    mixins: [ Sociable ],

    props: {
      type: { type: String, default: 'button' },
      title: { type: String, default: 'Follow Button' },
      leader: { required: true },
      follower: { required: true },
      followers: { type: Array, required: true }
    },

    data() {
      return {
        hovering: false
      }
    },

    computed: {
      following() {
        return !!this.followers.filter( f => f.fid == this.follower.uid ).length
      }
    },

    methods: {
      socialize() {
        console.log('socializing')
        if ( this.following ) {
          if ( confirm( this.$t("Are you sure you no longer want to follow") +` ${this.leader.display}?` ) ) this.dissociate( 'followings', this.follower.uid, this.leader.uid )
        }
        else this.associate( 'followings', this.follower.uid, this.leader.uid )
      }
    }
  }
</script>

<style scoped lang='stylus'>
  .active
    width 80px
    font-size .8em
    background var(--clr-orange)
    color white
    padding 4px 12px
    border-radius 5px
    &.hovering
      background red
      color white
  .inactive
    width 80px
    font-size .8em
    border 1px solid var(--clr-orange)
    background white
    color var(--clr-orange)
    padding 4px 12px
    border-radius 5px
</style>
