<template lang='pug'>
  #landing.view 
    user-home( v-if='user' :user='user' :profile='profile' )
    visitor-home( v-else :user='user' )
</template>

<script>
  import { db } from '@/main'
  import { Viewable } from '@/mixins/authenticable'
  import UserHome from '@/components/user-home'
  import VisitorHome from '@/components/visitor-home'

  export default {
    name: 'landing.view',

    mixins: [ Viewable ],

    components: {
      UserHome,
      VisitorHome
    }
  }
</script>

<style scoped lang='stylus'>
  @import url('https://fonts.googleapis.com/css?family=Titillium+Web:700&display=swap')

  #landing
    min-height calc( 95vh - 160px )
</style>