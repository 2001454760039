<template lang='pug'>
  .w-header.component
    slot
      w-wrapper.container
        w-flex.centered.container.flex-end
          router-link.logo.resp(to='/')
            img(src='/logo-full.svg')

          router-link.logo-compact(to='/')
            img(src='/logo-full.svg')

          .nav-links.resp
            router-link( to='/' ) {{ $t('Home') }}
            router-link( to='/about' ) {{ $t('About Us') }}
            router-link( to='/charge' ) {{ $t('Charge Up') }}
          
          .profile-links.resp(v-if='user')
            .toggle(@click.stop='$emit("menu")')
              img.avatar( v-if='profile && profile.avatar' :src='profile.avatar' )
              face-female-icon( v-else )
              menu-down-icon(v-if='!menu')
              menu-up-icon(v-else)
            ul.actionable(v-if='menu')
              li 
                .icon
                  user-icon
                router-link(:to='{ name: "my-profile" }') {{ $t('Profile') }}

              li 
                .icon
                  chat-icon
                router-link(:to='{ name: "my-topics" }') {{ $t('Chat Topics') }}

              li 
                .icon 
                  follow-icon
                router-link(:to='{ name: "my-follows" }') {{ $t('My Follows') }}

              li 
                .icon 
                  history-icon
                router-link(:to='{ name: "my-history" }') {{ $t('History') }}

              li 
                .icon
                  money-icon
                router-link(:to='{ name: "my-points" }') {{ $t('My Points') }}

              li 
                .icon
                  post-icon
                router-link(:to='{ name: "my-posts" }') {{ $t('My Posts') }}

              li 
                .icon
                  refer-icon
                router-link(:to='{ name: "refer"}') {{ $t("Refer a Friend") }}
                
              li( @click='out' v-if='user' )
                .icon
                  logout-icon
                router-link(:to='{ name:"sign-in" }') {{ $t("Sign Out") }}
              

          .auth-links(v-else)
            router-link(to='/sign-in') {{ $t('Sign In') }}
            span /
            router-link(to='/sign-up') {{ $t('Sign Up') }}
          
          select(v-model='locale' @change='translate')
            option(value='en') English
            option(value='kr') 한국어
            option(value='ch') 中文简体
            option(value='zh') 中文繁體
            option(value='jp') 日本語
          
          .menu.toggle.actionable( @click.stop='$emit("update:open", true)' v-visible='!open' )
            menu-icon
</template>

<script>
  import { Translatable } from '@/mixins/view'
  import { auth, db } from '@/main'

  export default {
    name: 'w-header.component',

    mixins: [ Translatable ],

    props: {
      user: { type: Object, default: () => ({}) },
      profile: { required: true },
      open: { type: Boolean, default: false },
      menu: { type: Boolean, default: false }
    },

    data() {
      return {
        show: { profile: { links: false } },
      }
    },

    created() {
      this.$i18n.locale = localStorage.getItem('locale')
    },

    methods: {
      out() {
        auth.signOut().then( () => {
          db.collection( 'profiles' ).doc( this.profile.id ).update({ status: 'offline' }).then( doc =>
            this.$router.push('/') 
          )
        })
      }
    }
  }
</script>

<style scoped lang='stylus'>
  .menu.toggle
    font-size 2.4em
    transform scaleX(1.2)
    transform scaleY(1.2)
    color white
    display none
    margin-left 10px
    @media( max-width: 1080 px )
      display block
      z-index 3

  .avatar
    height 36px
    width 36px
    object-fit cover
    border-radius 50%
    vertical-align middle

  .w-header
    justify-content flex-end
    @media( max-width 1080px )
      font-size .88em
    
    .nav-links, .profile-links .toggle, .auth-links
      color white
      padding-bottom 2px
      .router-link-exact-active
        border-bottom 2px solid

    .bg
      position absolute
      right -400px
      top -750px
      z-index -1
      .rect
        height 1200px
      .main
        position absolute
        top 850px
        right 400px
        background white
        border-radius 50%
        z-index 1
        width 520px
        height 520px
        background-size contain
        
      @media( max-width: 1080px )
        right -680px
        top -760px
      @media( max-width: 1080px )
        .main
          top 820px
          right 672px
          background white
      @media( max-width: 640px )
        right -760px
      @media( max-width: 480px )
        right -808px
      @media( max-width: 415px )
        right -916px

    .logo
      padding 16px 0
      margin-right auto
      img
        width 200px

    .logo-compact 
      display none
      position relative
      margin-right auto
      top 5px
      left 5px
      @media( max-width: 1080px )
        display block

    .logo-compact img
      width 140px

    .nav-links
      margin-left auto
      margin-right 10px
      a
        margin-right 20px
    
    .auth-links
      margin-left auto
      span
        margin 0 8px
      @media( max-width: 415px )
        display none
        
    select
      margin-left 16px
      background rgb( 240 240 240 )
      padding 3px 8px
      border-radius 7px
      @media( max-width: 415px )
        margin-left auto

  .profile-links
    .toggle 
      font-size 2em
    ul
      position absolute
      background rgb( 240 240 240)
      border-radius 7px
      border 1px solid rgb( 160 160 160 )
      font-weight bold
      z-index 1
      li
        padding 10px 40px 10px 10px
        border-top 1px solid rgb( 160 160 160 )
        .icon 
          display inline-block
          padding 0px 10px 0px 0px
          font-size 1.2em
          position relative 
          top 0px
        &:first-child
          border 0
</style>
