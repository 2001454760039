<template lang='pug'>
  .nav-links.component
    slot
      .mobile-menu.container
        .menu-toggle.actionable(@click='$emit("close")')
          menu-icon

        .logged-in(v-if='user')
          li
            .icon
              user-icon 
            router-link(:to='{ name: "my-profile" }') {{ $t("Profile") }}

          li 
            .icon
              chat-icon
            router-link(:to='{ name: "my-topics" }') {{ $t("Chat Topics") }}

          li 
            .icon
              follow-icon
            router-link(:to='{ name: "my-follows" }') {{ $t("My Follows") }}

          li 
            .icon
              history-icon
            router-link(:to='{ name: "my-history" }') {{ $t("History") }}

          li 
            .icon
              money-icon
            router-link(:to='{ name: "my-points" }') {{ $t("My Points") }}

          li 
            .icon
              post-icon
            router-link(:to='{ name: "my-posts"}') {{ $t("My Posts") }}
          
          li 
            .icon
              refer-icon
            router-link(:to='{ name: "refer"}') {{ $t("Refer a Friend") }}

        w-divider.card.bordered(v-if='user')

        li
          .icon
            home-icon
          router-link(:to='{ name:"home"}' ) {{ $t("Home") }}
        li 
          .icon
            information-icon
          router-link(:to='{ name:"about" }') {{ $t("About Us") }}
        li 
          .icon
            charge-icon
          router-link(:to='{ name:"charge" }') {{ $t("Charge Up") }}
        
        w-divider.card.bordered
        
        li(@click='out' v-if='user') 
          .icon
            logout-icon
          router-link(:to='{ name:"sign-in" }') {{ $t("Sign Out") }}
        li(v-if='!user') 
          .icon
            login-icon
          router-link(:to='{ name: "sign-in" }') {{ $t("Sign In") }}
</template>

<script>
  import { auth } from '@/main'

  export default {
    name: 'nav-links.component',

    props: {
      user: { type: Object, default: () => ({}) },
      open: { type: Boolean, default: false },
    },

    methods: {
      out() {
        auth.signOut().then( () => {
          // db.collection(  )
          this.$router.push('/') 
        })
      }
    }
  }
</script>

<style scoped lang='stylus'>
  .menu-toggle
    font-size 2em
    transform scaleX(1.5)
    transform scaleY(1.5)
    transform-origin right
    margin-top 5px
    margin-right 15px
    text-align right

  .menu-close-overlay
    position fixed
    top 0
    left 0
    right 0
    bottom 0
    height 100vh
    width 100vw
    z-index -1

  .mobile-menu 
    background var(--clr-blue)
    color white
    box-shadow 4px 2px 4px rgba( 80 80 80 .5 )
    overflow-x hidden
    li 
      font-size 1.1em
      padding 0px 40px 0px 5px
      list-style none
      margin 12px 12px
      cursor pointer
      .icon 
        display inline-block
        font-size 1.2em
        position relative 
        top 0px
      a 
        margin-left 20px
</style>
