<template lang='pug'>
  .w-footer.component.container
    slot
      w-wrapper
        w-grid.footer.container
          .links-1
            img( src='/logo-white.svg' width='170')
            
          .links-2
            h5 {{ $t("CUSTOMER SERVICE")}}
            router-link(to='about') {{ $t('ABOUT US') }}
            router-link(to='contactus') {{ $t('CONTACT US') }}
            router-link(to='privacy') {{ $t('PRIVACY POLICY') }}
            router-link(to='security') {{ $t('SITE SECURITY') }}
            router-link(to='help') {{ $t('HELP') }}
            
          .social
            p {{ $t('FOLLOW US') }}
            w-flex.social-links
              router-link.facebook(to='/facebook') 
                facebook-icon
              router-link.twitter(to='/twitter') 
                twitter-icon
              router-link.google-plus(to='/google-plus') 
                google-plus-icon
              router-link.behance(to='/behance') 
                behance-icon
              router-link.pinterest(to='/pinterest') 
                pinterest-icon

          p.copywrite {{ $t('All Rights Reserved') }} &copy; {{ $t('2020 Copywrite WorldTone') }}
</template>

<script>
  export default {
    name: 'w-footer.component'
  }
</script>

<style scoped lang='stylus'>
  .w-footer
    background rgb( 100 168 212 )
    padding 32px
    
  .footer
    grid-template-columns 1fr 1fr
    grid-gap 64px 16px
    color black

    .links-1, .links-2
      font-size .8em
      line-height 1.5
      @media( max-width: 640px )
        font-size .6em
      h5 
        margin-bottom 8px
      a
        display block

    .copywrite
      font-size .8em
      text-align right
      align-items flex-end
      @media( max-width: 640px)
        font-size .6em

  .social
    p
      font-size .75em
      margin-bottom 8px

  .social-links
    font-size .75em
    color white
    span 
      font-size .75em
    a
      display flex
      justify-content center
      height 24px
      width 24px
      padding-bottom 2px
      box-sizing border-box
      border-radius 50%
      margin-right 2px
    .facebook
      background #3b5998
    .twitter
      background #00acee
    .google-plus
      background rgb( 221 75 57 )
    .behance
      background #053eff
    .pinterest
      background #c8232c
</style>
