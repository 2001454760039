<template lang='pug'>
  #security.view
    header
      w-wrapper
        w-flex
          h4.title {{ $t('Site Security')}}
    
    section.myposts
      w-wrapper.mainbody
        h5 What Makes my Credit Card safe when Ordering?
        p World Language licenses special software that automatically encrypts any data sent by you on our secure order pages. Our server then reads it by using a private key. This means is that your data - be it credit card information or other personal data - is turned into a type of code that can only be decoded with the private key or password.
        h5.title How Do I Know the Browser Page is Secure?
        p With most versions of Microsoft Internet Explorer, a locked padlock will appear in the bottom toolbar of the browser. With newer Netscape browsers, a similar padlock will appear at the bottom. You can also check your URL to see if it begins with https:// This is another sign that the page is secure. Another way in Netscape is to click View, then Page Info. This will have further information on the security of the document. You can do the same thing in Internet Explorer, just click File, then Properties.
        h5.title Is Ordering on the Internet Really Safe?
        p We're so confident of our security that we gladly offer you a Safe Shopping Guarantee. But if you still feel uneasy about ordering through our web site, we encourage you to order by telephone at 1-424-328-0063, or simply fill out the on screen forms and print them out and fax your order to 888-213-4042. Whichever way makes you feel more comfortable is the way we would like to serve you. We truly appreciate your business when you shop at WorldLanguage - The Ultimate Language Store.

      
</template>

<script>
  import { Viewable } from '@/mixins/authenticable'
  
  export default {
    name: 'security.view',

    mixins: [ Viewable ],
    
  }
</script>

<style scoped lang='stylus'>
  
  #security
    .w-wrapper 
      padding 10px 18px 10px 18px
    .mainbody
      background rgba( 255 255 255 .95)
      border-radius 5px
      padding 50px
      .title
        padding-top 20px
      p
        padding 10px
      h5
        padding 5px
      @media( max-width: 560px)
        font-size 0.8em
    
      
  header
    margin-top -30px
    background rgba( 80 160 210 .95 )
    color white  
    @media( max-width: 420px)
      font-size: .8em
      padding 0px
  section
    background rgba( 0 0 0 .5 )
    padding 20px
    
</style>
