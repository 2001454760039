<template lang='pug'>
  #my-calendar.view 
    w-wrapper.container
      h1
        span {{ $t('R') }}
        span {{ $t('eservations Calendar') }}
      w-divider.card.borderless
      base-card.rsv.container.padded
        #rsv-cal.rsv-cal.container.component
          vue-cal.cal.vuecal--blue-theme.calendar(
            default-view="week"
            small
            :time-step='30'
            :disable-views="['years', 'year', 'day', 'month']"
            :selected-date="new Date()"
            /* :time-from='opening' */
            /* :time-to='closing' */
            /* :twelveHour='true' */
            /* :on-event-click='view' */
            :events="events"
          )
      w-divider.section

        //- w-overlay.flush
        //-   h5.section-heading {{ $t('Reservation/Appointment Details') }}

        //-   rsv-dtls.overlay(
        //-     v-if='rsv'
        //-     :rsv='rsv'
        //-   )
</template>

<script>
  import { db } from '@/main'
  import { Viewable } from '@/mixins/authenticable'
  import VueCal from 'vue-cal'
  export default {
    name: 'my-calendar.view',

    mixins: [ Viewable ],

    components: {
      VueCal
    },

    data() {
      return {
        hosting: [],
        joining: []
      }
    },

    firestore() {
      if ( this.user.uid ) return {
        hosting: db.collection( 'rsvs' ).where( 'hid', '==', this.user.uid ).where( 'start', '>', new Date()),
        joining: db.collection( 'rsvs' ).where( 'reserved.by.profile.uid', '==', this.user.uid ).where( 'start', '>', new Date()),
      }
    },

    watch: {
      user(curr, prev) {
        console.log(curr.uid)
        if ( curr.uid ) {
          this.$binding( 'hosting', db.collection( 'rsvs' ).where( 'hid', '==', this.user.uid ).where( 'start', '>', new Date()) )
          this.$binding( 'joining', db.collection( 'rsvs' ).where( 'reserved.by.profile.uid', '==', this.user.uid ).where( 'start', '>', new Date()) )
        }
      }
    },

    computed: {
      rsvs() {
        return [...this.hosting, ...this.joining]
      },

      events() {
        return this.rsvs.map( r => ({ 
          // ...r, 
          // {
            startDate: r.start.toDate(),
            endDate: r.end.toDate(),
            class: `${r.hid == this.user.uid ? "host" : "student"}`
            // class: this.klass(r)
          // }
        }))
        
      },
    }
  }
</script>

<style scoped lang='stylus'>
  >>> .host
    background rgb( 240 150 90 )
    color white
  >>> .student 
    background rgb( 0 200 90 )
    color white
    // border 1px solid black

</style>