<template lang="pug">
  .tone.modal.component
    button.close( @click='$emit("close")' )
      close-icon

    h2.centered-text
      span Go 
      span.emphasize Online 
      span and
    h4.centered-text Accept call request from other users

    section
      .topic-select.centered-text
        fieldset( v-if='topic' )
          label Select My Chat Topic
          select( v-model='topic' )
            //- option( value='new' ) {{ $t('New Topic') }}
            option( v-for='topic in topics' :value='topic' ) {{ topic.title }}
      
      //- h3.centered-text( v-show='topic == "new"' ) OR

      //- form( ref='new' v-show='topic == "new"' ).new-topic
      //-   fieldset.title
      //-     label.centered-text Define My Chat Topic
      //-     input( type='text' v-model='custom.title' required )

      //-   fieldset.details
      //-     label More Details:
      //-     input( type='text' v-model='custom.desc' required )

      //-   fieldset.language
      //-     label Language Used:
      //-     input( type='text' v-model='custom.main' required )

      //-   fieldset.points
      //-     label Points Required:
      //-     input( type='text' v-model='custom.points' required )

      //-   fieldset.time
      //-     label Estimated Time:
      //-     input( type='text' v-model='custom.duration' required )
        
      //- fieldset.save( v-show='topic == "new"' )
      //-   input( type='checkbox' v-model='save' )
      //-   label Save as My Chat Topic
      
      .buttons.centered-text( v-if='!topic' )
        h6 You don't have any topics for chat, please create one first
        w-divider.card
        button.offline.no-topic( @click='go( "offline")' ) Go to My Topics

      w-flex.buttons.centered( v-if='topic' )
        button.online( @click='write("online")' ) Set and Go Online
        button.offline( @click='write("offline")' ) Go Offline


</template>

<script>
  import { db } from '@/main'

  export default {
    name: 'tone.modal.component',

    props: {
      user: { type: Object, required: true },
      profile: { type: Object, required: true }
    },

    data() {
      return {
        topic: null,
        topics: [],
        save: false,
        custom: {}
      }
    },

    firestore() {
      return {
        topics: { 
          ref: db.collection( 'topics' ).where( 'uid', '==', this.user.uid ),
          resolve: topics => this.topic = this.profile.topic || topics[0]
        }
      }
    },

    methods: {
      update( status ) {
        if ( this.status ) this.create( status )
        else this.write( status )
      },

      create( status ) {
        db.collection( 'profiles' ).doc( this.profile.id ).update({ status })
        db.collection( 'topics' ).add({
          ...this.custom,
          ...{ pid: this.profile.id, uid: this.user.uid }
        }).then( doc => this.$emit( 'close', doc ) )
      },

      write( status ) {
        let topic = this.topic
        db.collection( 'profiles' ).doc( this.profile.id ).update({ status, topic }).then( doc => this.$emit( 'close', doc ) )
      },

      go( status ) {
        db.collection( 'profiles' ).doc( this.profile.id ).update({ status }).then( doc =>
          this.$router.push({ name: "my-topics" })
        )
      }
    }
  }
</script>

<style lang="stylus" scoped>
  .close
    background rgb( 245 245 245 )
    position absolute
    right 12px
    top 12px
    padding 6px 12px
    font-size 1.2em
    border-radius 5px

  .tone
    padding 32px
    min-width 640px
    background white
    border-radius 11px
    overflow-y auto
    max-height 88vh
    box-sizing border-box
    position relative
    @media( max-width: 640px )
      min-width auto
      font-size .88em
      height 100vh
      width 100vw
      border-radius 0

  h2
    margin-bottom 12px

  .emphasize
    color rgb( 240 75 50 )

  label
    display block
    margin-bottom 4px

  input, select
    background rgb( 245 245 245 )
    padding 4px 12px
    border-radius 5px
    width 100%
    box-sizing border-box

  fieldset
    margin 24px 0

  .new-topic
    display grid
    grid-template-columns repeat( auto-fit, minmax( 112px, 1fr ) )
    grid-gap 0 16px

    .title, .details
      grid-column 1 / -1
    
    fieldset
      margin-bottom 0

    .language
      grid-column 1 / 3

    @media( max-width: 640px )
      grid-template-columns 1fr
      .language
        grid-column 1 / -1

  .save
    text-align center
    margin-top 64px

    label, input
      display inline
      width auto

    input
      transform scale( 1.75 )

    label
      margin-left 8px
      position relative
      bottom 1px
  
  .buttons
    margin-top 64px
    button
      padding 16px 0
      color white
      border-radius 7px
      min-width 172px
      text-align center
      &.online
        background rgb( 50 125 250 )
        margin-right 32px
      &.offline
        background rgb( 250 75 50 )
      &.no-topic
        padding 16px

    @media( max-width: 420px )
      flex-direction column
      justify-content center
      align-items center
      button.online
        margin 0
        margin-bottom 16px
</style>