<template lang='pug'>
  .w-flex.component
    slot
</template>

<script>
  export default {
    name: 'w-flex.component'
  }
</script>

<style scoped lang='stylus'>
  .w-flex
    display flex

  .w-flex.centered
    align-items center
    justify-content center

  .w-flex.space-between
    justify-content space-between

  .w-flex.space-evenly
    justify-content space-evenly

  .w-flex.flex-end
    justify-content flex-end

  .w-flex.column
    flex-direction column

  .w-flex.block
    width 100%

  .w-flex.full
    width 100%
    height 100vh

  .w-flex.fixed
    position fixed
    top 0
    left 0
    &.bottom
      top auto
      bottom 0
</style>
