<template lang='pug'>
  #profile.view
    template( v-if='teacher.private && $route.params.access != profile.access' )
      w-wrapper.private-profile-container
        h1 User Profile Is Private

    template( v-else )
      w-modal( :show='modal.request' )
        session-request(
          v-if='modal.request && profile.id != teacher.id'
          :user='user' 
          :profile='profile' 
          :teacher='teacher' 
          :topics='topics' 
          @close='modal.request = false'
        )

      .page-header
        w-wrapper
          w-flex
            h4.title {{ teacher.display }}

      header
        w-wrapper
          .profile
            w-flex.head 
              img.avatar(:src='teacher.avatar')
              .stats
                h4 {{ teacher.display }}
                p.location {{ teacher.location }}
                p.languages {{ teacher.languages }}
                .followship
                  span.boldnum {{ followings.length }} 
                  span {{ $t("Followings")}}
                  span.boldnum {{ followers.length }} 
                  span {{ $t("Followers")}} 

            .buttons.centered-text
              follow-button( :follower='profile' :leader='teacher' :followers='followers' )
              button.chat( v-if="teacher.status=='online'" @click='connect' ) {{ $t("Connect to Chat")}} 
              img.actionable( src='/sendmsg.svg' width='50' @click='message( teacher )' )

            p.intro( v-if="teacher.intro || teacher.youtube") {{ teacher.intro }} {{ teacher.youtube }}

          .video( v-if="teacher.youtube")
            iframe(
              :src= 'teacher.youtube'
              frameborder="0" 
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen=""
            )

      section.topics(v-if="topics.length")
        w-wrapper
          h5 {{ $t("Chat with Me: ")}}
          .topics-grid
            base-card.topic( v-for='topic in topics' :key='topic.id' )
              topic-card( :item='topic' )

      section.schedule( v-if="topics.length")
        w-wrapper
          h5 {{ $t('My Schedule')}}
          .rsv-cal.container.component
            vue-cal.cal.vuecal--blue-theme.calendar(
              default-view="week"
              small
              :time-step='30'
              :disable-views="['years', 'year', 'day']"
              :selected-date="new Date()"
              /* :time-from='opening' */
              /* :time-to='closing' */
              /* :twelveHour='true' */
              /* :on-event-click='view' */
              :events="events"
            )
</template>

<script>
  import { db } from '@/main'
  import { Viewable } from '@/mixins/authenticable'
  // import { Sociable } from '@/mixins/firebase'
  import FollowButton from '@/components/buttons/follow'
  import VueCal from 'vue-cal'
  import SessionRequest from '@/components/modals/session-request'

  export default {
    name: 'profile.view',

    mixins: [ Viewable ],
    
    props: {
      // session: { required: true }
    },

    components: {
      SessionRequest,
      VueCal,
      FollowButton
    },

    data() {
      return {
        modal: { request: false },
        teacher: {},
        topics: [],
        messageunfollow: '',
        followings: [],
        followers: [],
        hosting: [],
        joining: []
      }
    },

    firestore() {
      return {
        teacher: {
          ref: db.collection( 'profiles' ).doc( this.$route.params.id ),
          resolve: doc => {
            this.$binding( 'topics', db.collection('topics').where('uid', '==', doc.uid) )
            console.log('binding', doc.uid)
            this.$binding( 'followings', db.collection( 'followings' ).where( 'fid', '==', doc.uid ) )
            this.$binding( 'followers', db.collection( 'followings' ).where( 'lid', '==', doc.uid ) )
            // this.$binding( 'hosting', db.collection( 'rsvs' ).where( 'hid', '==', doc.uid ).where( 'start', '>', new Date()) )
            // this.$binding( 'joining', db.collection( 'rsvs' ).where( 'reserved.by.profile.uid', '==', this.doc.uid ).where( 'start', '>', new Date()) )
            // this.$binding( 'following', db.collection( 'followings' ).doc( `${this.user.uid}->${doc.uid}` ) ).catch( e => console.log(e) )
          }
        }
      }
    },

    computed: {
      rsvs() {
        return [...this.hosting, ...this.joining]
      },

      events() {
        return this.rsvs.map( r => ({ 
          // ...r, 
          // {
            startDate: r.start.toDate(),
            endDate: r.end.toDate(),
            class: `${r.hid == this.user.uid ? "host" : "student"}`
            // class: this.klass(r)
          // }
        }))
        
      },
    },

    methods: {
      unfollow() {
        this.messageunfollow = this.$t("Are you sure you want to unfollow ") + `${this.teacher.display}?`
      },

      follow() {
        if ( this.user ) {
          if ( this.following.id ) {
            this.dissociate( 'followings', this.user.uid, this.teacher.uid )
            this.following = {}
            this.messageunfollow =''
          }
          else this.associate( 'followings', this.user.uid, this.teacher.uid )
        }

        else this.$router.push({ name: 'sign-in' })
      },

      connect() {
        if ( this.user ) this.modal.request = true
        else this.$router.push({ name: 'sign-in' })
      },

      async message( dst ) {
        let id = `${this.profile.id}->${dst.id}`
        let thread = await db.collection( 'threads' ).doc( id ).get()
        if ( !thread.exists ) this.create( this.profile, dst )
        else console.log('thread exists')
        this.$router.push({ name: "messages", params: { current: id } })
      }
    }
  }
</script>

<style scoped lang='stylus'>
  #profile .page-header
    margin-top -24px
    margin-bottom 24px
    background rgba( 80 160 210 .95 )
    color white
    .w-wrapper
      background 0
      padding 12px 0
    @media( max-width: 420px)
      font-size: .8em
      padding 0px

  #profile
    background rgba( 0 0 0 .5 )
    padding-bottom 64px

    .w-wrapper
      background rgba( 255 255 255 .92 )
      padding 24px
      border-radius 3px

  header, section
    margin-bottom 32px

  header
    .w-wrapper
      display grid
      grid-template-columns repeat( auto-fit, minmax( 320px, 1fr ) )
      grid-gap 32px

  .profile
    .head 
      .avatar
        background rgb( 255 255 255)
        width 128px
        height 128px
        border-radius 50%
        margin-right 32px
        object-fit cover
      p
        margin 12px 0
      
      .followship
        span:first-child
          margin-right 5px
        .boldnum
          font-weight bold
          margin-left 5px


    .buttons
      display flex
      align-items center
      justify-content space-between
      margin 10px 0
      .follow.button
        margin-left 25px

      button.chat
        padding 8px 24px
        border-radius 5px
      .chat
        font-size .9em
        background var(--clr-blue)
        color white
        padding 6px 16px
                      
    .intro
      background white
      padding 16px

  .video
    iframe
      height 100%
      width 100%
      min-height 256px
  
  .topics, .schedule
    h5
      margin-bottom 16px
    .topics-grid
      display grid
      grid-template-columns repeat( auto-fit, minmax( 320px, 1fr ) )
      grid-gap 20px

  >>> .vuecal__flex.vuecal__title, >>> .vuecal__flex.weekday-label
    font-size .8em
    color black
    font-weight bold
  >>> .vuecal--week-view 
    border 1px solid grey
    border-radius 5px
    background rgb(255 255 255)
  >>> .vuecal__cell.selected
    background rgb(120 120 244)
  >>> .weekday-label
    border-radius 5px
    font-weight bold
  >>> .vuecal__heading.today
    color white
    
  >>> .vuecal__cell.today
    background rgb(54 131 149)
    color orange
  >>> .vuecal__cells.month-view
    font-weight bold
    
  >>> .vuecal__time-column
    font-size .8em
  >>> .vuecal__no-event
    font-size .7em 
        
  >>> .vuecal__time-cell
    color black
    
  >>> .vuecal--month-view
    height 450px
  >>> .vuecal--month-view.today
    color orange
  >>>  .vuecal__cell-events-count
    background orange 
  >>> .vuecal__now-line
    color orange
    border-top: 3px solid
  
</style>

