<template lang='pug'>
  .user-home.component
    w-modal( :show='modal.request' )
      session-request(
        v-if='modal.request && profile.id != teacher.id && teacher.topic'
        :user='user' 
        :profile='profile' 
        :teacher='teacher' 
        :topics='topics'
        :key='teacher.id'
        @close='modal.request = false'
      )
    base-modal( v-if='modal.tone' @click.native='modal.tone = false' )
      modal-wrapper( @click.native.stop='' )
        tone-modal(
          v-if='user && profile'
          :user='user' 
          :profile='profile'
          @close='modal.tone = false'
        )
    header
      w-wrapper
        w-flex
          .demographics
            select
              option(value='' ) {{ $t('Language') }}
              option(value='en') English
              option(value='kr') 한국어
              option(value='ch') 中文简体
              option(value='zh') 中文繁體
              option(value='jp') 日本語
            select
              option(value='' ) {{ $t('Location') }}
              option(value='en') {{ $t('United States') }}
              option(value='kr') {{ $t('Korea') }}
              option(value='ch') {{ $t('China') }}
              option(value='jp') {{ $t('Japan') }}
          .demographics-compact
            img(src='/lang.svg' width='20')
            img(src='/location.svg' width='20')
          .search-filters
            button {{ $t('Online') }}
            button {{ $t('Gender') }}
            button {{ $t('Points') }}
            input.centered-text( type='search' :placeholder='$t( "Search")' )
    
    section.profiles
      w-wrapper
        w-flex.centered
          w-button( v-bind:class="['settone',profile.status]" type='submit' @click.native='modal.tone = true' )
            template(v-slot:text) {{ $t('Set My Tone') }}
        masonry(
          :cols="{ default: 2, 820: 1 }" 
          :gutter="{ default: '16px' }"
        )
          base-card.profile( v-for='item in profiles' :key='item.id' )
            profile-card( :item='item' @connect='connect( item )' :user='user', :profile='profile' )
</template>

<script>
  import { db } from '@/main'
  import { Viewable } from '@/mixins/authenticable'
  import ProfileCard from '@/components/cards/profile'
  import SessionRequest from '@/components/modals/session-request'
  import BaseModal from '@/components/modals/base'
  import ModalWrapper from '@/components/modals/wrapper'
  import ToneModal from '@/components/modals/tone'

  export default {
    name: 'user-home.view',

    mixins: [ Viewable ],

    components: {
      ProfileCard,
      SessionRequest,
      BaseModal,
      ModalWrapper,
      ToneModal
    },

    data() {
      return {
        profiles: [],
        modal: { request: false, tone: false },
        teacher: null
      }
    },

    firestore() {
      return {
        profiles: {
          ref: db.collection( 'profiles' ).where( 'private', '==', false ),
          resolve: profiles => this.profiles = profiles.filter( p => p.uid != this.user.uid )
        }
      }
    },

    methods: {
      update() {
        db.collection( 'profiles' ).doc( this.profile.id ).update({ status: this.status })
      },

      connect( host ) {
        if ( this.user ) {
          this.$binding( 'topics', db.collection('topics').where('uid', '==', host.uid) ).then( () => {
            this.teacher = host
            this.modal.request = true
          })
        }
        else this.$router.push({ name: 'sign-in' })
      }
    }
  }
</script>

<style scoped lang='stylus'>
  header
    margin-top -30px
    background rgba( 80 160 210 .95 )
    padding 10px 0  
    select, button, input
      background rgb( 250 250 250 )
      padding 5px 20px
      font-weight normal
      border 1px solid lightgrey
      border-radius 5px
    
    select:first-child
      margin-right 2px

    .search-filters
      margin-left auto
      button
        margin 0 2px
        padding 5px 10px
      input
        width 200px
      @media( max-width: 830px)
        font-size .8em
      @media( max-width: 720px)
        font-size .7em
        input 
          width 150px
      @media( max-width: 440px)
        input
          width 120px
    
     
  section
    background rgba( 0 0 0 .5 )
  
  .profiles
    padding 15px 0
    .profile
      margin-bottom 10px

  .demographics
    font-size 1em
    @media( max-width: 830px)
      font-size .8em
    @media( max-width: 720px)
      font-size .7em
    @media( max-width: 680px)
      display none
  
  .demographics-compact
    display none 
    @media( max-width: 680px)
      display flex
      img
        padding 0px 10px 0px 10px

</style>
