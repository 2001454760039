<template lang='pug'>
  button.w-button.actionable.component(
    unselectable='on'
    :type='type'
    :title='title'
  )
    slot(name='icon')
    slot(name='text')
</template>

<script>
  export default {
    name: 'w-button.component',

    props: {
      type: { type: String, default: 'button' },
      title: String
    }
  }
</script>

<style scoped lang='stylus'>
  .w-button
    background rgb(76 154 207)
    color white
    border-radius 5px
    padding 10px 20px 10px 20px
    text-align center
    transition all 320ms
    font-weight normal

    // &:hover
    //   color white
      // box-shadow var(--shadow-box)
    &:active
      transform scale(.8)
    &:focus 
      //
  [type=reset]
    background rgb( 176 176 176 )
    color rgb( 32 32 32 )

  .w-button.link
    padding 0
    a
      display block
      padding 8px

  .w-button.bright
    background rgb( 0 175 255 )

  .w-button.wide
    padding 8px 24px
    width auto

  .w-button.tall
    padding-top 10px
    padding-bottom 10px

  .w-button.taller
    padding-top 12px
    padding-bottom 12px

  .w-button.bold
    font-weight bold
    letter-spacing .5px

  .w-button.secondary
    background rgb( 200 200 200 )

  .w-button.tertiary
    background rgb( 240 240 240 )
    color rgb( 128 128 128 )

  .w-button.narrow
    width auto
    padding-left 36px
    padding-right 36px

  .w-button.narrower
    width auto
    padding-left 32px
    padding-right 32px

  .w-button.narrowest
    width auto
    padding-left 24px
    padding-right 24px

  .w-button.small
    font-size .84em
    padding-top 7px
    padding-bottom 7px

  .w-button.large
    font-size 1.2em

  .w-button.full
    width auto
    padding 10px 28px

  .w-button.clear
    background 0
    color var(--bg-nav)
    font-weight bold
    border 1.5px solid var(--bg-nav)

  .w-button.icon-only
    font-size 2.1em
    padding-top 0
    padding-bottom 0

  .w-button.combo
    display flex
    align-items center
    justify-content center
    .icon
      margin-right 8px

  .w-button.inactive
    background var(--bg-inactive)
    color black

  .confirm-unfollow
    margin-left 24px

  .w-button.max 
    width 100%

  .w-button.rounded 
    border-radius 25px

  .w-button.settone
    background rgb( 240 40 40)
    padding 10px 30px 10px 30px
    margin-bottom 10px
    border-radius 20px

  .w-button.settone.offline
    background rgb( 255 255 255)
    color rgb( 240 40 40)
    border 1px solid rgb( 240 40 40)
    
</style>
