import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './registerServiceWorker'

import VueSuglify from "vue-suglify"
Vue.component("suglify", VueSuglify)

// import VueI18n from 'vue-i18n'
// Vue.use(VueI18n)

import VueTelInput from 'vue-tel-input'
Vue.use(VueTelInput)

import VueAgile from 'vue-agile'
Vue.use(VueAgile)

import VueMasonry from 'vue-masonry-css'
Vue.use(VueMasonry)

const config = {
  apiKey: "AIzaSyAMPdLC9urV--u0COPCJ8nk04b4wZ2g40E",
  authDomain: "worldtone-f7d0c.firebaseapp.com",
  databaseURL: "https://worldtone-f7d0c.firebaseio.com",
  projectId: "worldtone-f7d0c",
  storageBucket: "worldtone-f7d0c.appspot.com",
  messagingSenderId: "285264590021",
  appId: "1:285264590021:web:e96a7534cb862b72696cac",
  measurementId: "G-H420DFL3ZN"
}

// import * as Firebase from 'firebase'
import Firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/storage'
import 'firebase/messaging'
Firebase.initializeApp(config)
export const db = Firebase.firestore()
export const auth = Firebase.auth()
export const str = Firebase.storage()
export const firebase = Firebase

// let msgr = null
// if ( Firebase.messaging.isSupported() ) {
//   msgr = Firebase.messaging()
//   msgr.usePublicVapidKey('BN4zZNRHGs3AlrILyG3258NUC_kxKVJf2KhqH3pMrRayNYfgWSKC8VnNJJmyexgln5qAnXtq0tJvCczQt4tNnus')
// }
// export { msgr }

import VueFirestore from 'vue-firestore'
Vue.use( VueFirestore, { key: 'id' } )

import i18n from '@/i18n'

// const i18n = new VueI18n({
//   locale: 'en',
//   messages: {
//     en: {
//       // ...
//     },
//     ja: {
//       // ...
//     }
//   }
// })



import BaseCard from '@/components/base-card'
Vue.component('base-card', BaseCard)

import WFlex from '@/components/w-flex'
Vue.component('w-flex', WFlex)

import WButton from '@/components/w-button'
Vue.component('w-button', WButton)

import Divider from '@/components/w-divider'
Vue.component('w-divider', Divider)

import Notice from '@/components/w-notice'
Vue.component('w-notice', Notice)

import DraggableIcon from 'vue-material-design-icons/Drag'
Vue.component('draggable-icon', DraggableIcon)

import Input from '@/components/w-input'
Vue.component('w-input', Input)

import NavDrawer from '@/components/nav-drawer'
Vue.component('nav-drawer', NavDrawer)

import NavLinks from '@/components/nav-links'
Vue.component('nav-links', NavLinks)

import RsvCal from '@/components/rsv-cal'
Vue.component('rsv-cal', RsvCal)

import RsvDtls from '@/components/rsv-dtls'
Vue.component('rsv-dtls', RsvDtls)

import Topic from '@/components/topic'
Vue.component('topic', Topic)

import WGrid from '@/components/w-grid'
Vue.component('w-grid', WGrid)

import WImages from '@/components/w-images'
Vue.component('w-images', WImages)

import WModal from '@/components/w-modal'
Vue.component('w-modal', WModal)

import WFooter from '@/components/w-footer'
Vue.component('w-footer', WFooter)

import WHeader from '@/components/w-header'
Vue.component('w-header', WHeader)

import WOverlay from '@/components/w-overlay'
Vue.component('w-overlay', WOverlay)

import Wrapper from '@/components/w-wrapper'
Vue.component('w-wrapper', Wrapper)

import ProfileCard from '@/components/profile-card'
Vue.component('profile-card', ProfileCard)

import WEditor from '@/components/w-editor'
Vue.component('w-editor', WEditor)

import WFields from '@/components/w-fields'
Vue.component('w-fields', WFields)

import WCounter from '@/components/w-counter'
Vue.component('w-counter', WCounter)

import WDoubleCarousel from '@/components/w-double-carousel'
Vue.component('w-double-carousel', WDoubleCarousel)

import TopicCard from '@/components/topic-card'
Vue.component('topic-card', TopicCard)

// import Layout from '@/layouts/default.vue'
// Vue.component('layout', Layout)

Vue.config.productionTip = false

import 'vue-material-design-icons/styles.css'

import AddIcon from 'vue-material-design-icons/Plus'
Vue.component('add-icon', AddIcon)

import CloseIcon from 'vue-material-design-icons/Close'
Vue.component('close-icon', CloseIcon)

import CopyIcon from 'vue-material-design-icons/ClipboardMultipleOutline'
Vue.component('copy-icon', CopyIcon)

import DeleteIcon from 'vue-material-design-icons/DeleteOutline'
Vue.component('delete-icon', DeleteIcon)

import FacebookIcon from 'vue-material-design-icons/Facebook.vue'
Vue.component('facebook-icon', FacebookIcon)

import TwitterIcon from 'vue-material-design-icons/Twitter.vue'
Vue.component('twitter-icon', TwitterIcon)

import GooglePlusIcon from 'vue-material-design-icons/GooglePlus.vue'
Vue.component('google-plus-icon', GooglePlusIcon)

// import BehanceIcon from 'vue-material-design-icons/Behance.vue'
// Vue.component('behance-icon', BehanceIcon)

import BellIcon from 'vue-material-design-icons/BellAlertOutline.vue'
Vue.component('bell-icon', BellIcon)

import PinterestIcon from 'vue-material-design-icons/Pinterest.vue'
Vue.component('pinterest-icon', PinterestIcon)

import PhoneOffIcon from 'vue-material-design-icons/PhoneOff.vue'
Vue.component('phone-off-icon', PhoneOffIcon)

import StarIcon from 'vue-material-design-icons/Star.vue'
Vue.component('star-icon', StarIcon)

import FaceFemaleIcon from 'vue-material-design-icons/Face.vue'
Vue.component('face-female-icon', FaceFemaleIcon)

import SoundOnIcon from 'vue-material-design-icons/Microphone.vue'
Vue.component('sound-on-icon', SoundOnIcon)

import SoundOffIcon from 'vue-material-design-icons/MicrophoneOff.vue'
Vue.component('sound-off-icon', SoundOffIcon)

import MenuIcon from 'vue-material-design-icons/Menu.vue'
Vue.component('menu-icon', MenuIcon)

import MenuDownIcon from 'vue-material-design-icons/MenuDown.vue'
Vue.component('menu-down-icon', MenuDownIcon)

import MenuUpIcon from 'vue-material-design-icons/MenuUp.vue'
Vue.component('menu-up-icon', MenuUpIcon)

import TextIcon from 'vue-material-design-icons/TextRecognition'
Vue.component('text-icon', TextIcon)

import CameraIcon from 'vue-material-design-icons/Camera'
Vue.component('camera-icon', CameraIcon)

import CameraOffIcon from 'vue-material-design-icons/CameraOff'
Vue.component('camera-off-icon', CameraOffIcon)

import EmailIcon from 'vue-material-design-icons/EmailOutline'
Vue.component('email-icon', EmailIcon)

import PasswordIcon from 'vue-material-design-icons/LockOutline'
Vue.component('password-icon', PasswordIcon)

import LeftIcon from 'vue-material-design-icons/MenuLeft'
Vue.component('left-icon', LeftIcon)

import RightIcon from 'vue-material-design-icons/MenuRight'
Vue.component('right-icon', RightIcon)

import UserIcon from 'vue-material-design-icons/AccountOutline'
Vue.component('user-icon', UserIcon)

import ReferIcon from 'vue-material-design-icons/AccountPlusOutline'
Vue.component('refer-icon', ReferIcon)

import ChatIcon from 'vue-material-design-icons/ForumOutline'
Vue.component('chat-icon', ChatIcon)

import FollowIcon from 'vue-material-design-icons/AccountMultipleOutline'
Vue.component('follow-icon', FollowIcon)

import HistoryIcon from 'vue-material-design-icons/FormatAlignLeft'
Vue.component('history-icon', HistoryIcon)

import MoneyIcon from 'vue-material-design-icons/CurrencyUsd'
Vue.component('money-icon', MoneyIcon)

import PostIcon from 'vue-material-design-icons/FileDocumentOutline'
Vue.component('post-icon', PostIcon)

import HomeIcon from 'vue-material-design-icons/HomeOutline'
Vue.component('home-icon', HomeIcon)

import InformationIcon from 'vue-material-design-icons/InformationOutline'
Vue.component('information-icon', InformationIcon)

import ChargeIcon from 'vue-material-design-icons/CartPlus'
Vue.component('charge-icon', ChargeIcon)

// import ContactIcon from 'vue-material-design-icons/PhoneMessageOutline'
// Vue.component('contact-icon', ContactIcon)

import ContactIcon from 'vue-material-design-icons/FaceAgent'
Vue.component('contact-icon', ContactIcon)

import LogoutIcon from 'vue-material-design-icons/Logout'
Vue.component('logout-icon', LogoutIcon)

import LoginIcon from 'vue-material-design-icons/Login'
Vue.component('login-icon', LoginIcon)

import VideoIcon from 'vue-material-design-icons/VideoOutline'
Vue.component('video-icon', VideoIcon)

// import VueStripeCheckout from 'vue-stripe-checkout'
// Vue.use( VueStripeCheckout, {
//   publishableKey: 'pk_test_OkfwqFBL4znAZ8s0hGo6VGNo007E7UusKr' 
// })

Vue.directive('focus', {
  inserted: el => el.focus()
})

Vue.directive('visible', {
  update: (el, binding, vnode) => {
    if ( binding.value ) el.style.opacity = 1
    else el.style.opacity = 0
  }
})

new Vue({
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')



// 'pk_live_vrRtx0rGPMrDXSP3pRUkw3M9'