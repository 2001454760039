<template lang='pug'>
  .w-input.component
    w-flex.container.centered
      span.icon
        slot(name='icon')
          text-icon
      span.input-container
        slot
          input(type='text')
        //- label(v-show='text')
        //-   slot(name='label')
</template>

<script>
  export default {
    name: 'w-input.component',

    // props: [ 'text' ]
  }
</script>

<style scoped lang="stylus">
  .w-input .container
    background rgb( 240 240 240 )
    padding 8px 24px
    border-radius 20px
    &.remind
      border 1.5px solid red

  .input-container
    flex-grow 1
    margin-left 8px

  .icon
    // width 16px
    // height 16px
    font-size 1.5em
    margin-right 8px
    // flex-grow 0

  .w-input.material
    .icon
      width auto
      height auto
      margin-right 2px

// Specific Styles //

  .w-input.basic
    .input-container
      margin-left 0
    .icon
      display none

  .w-input.block
    box-sizing border-box
    input, textarea
      width 100%
      display block


  .w-input.clear
    background 0
    border 1px solid rgba( 0 0 0 .1 )

  .w-input.thin
    padding: 5px 12px

  .w-input.centered
    .input-container
      flex-grow 0

  .w-input.icon-right .container
    flex-direction row-reverse
    .icon
      font-size 1em
      margin 3.5px 24px 0px 12px
      font-weight bold

  .w-input.phone
    padding-left 12px
    padding-top 6px
    padding-bottom 6px

  .w-input.phone-input
    margin 0 16px
    min-width 268px
    position relative
    >>> .tel-input
      position absolute
      margin 0 auto
      border 0
      top 3px
      @media(min-width: 420px)
        top 8px
        left 68px
      @media(min-width: 360px)
        top 7px
      input
        padding 0

  .w-input.labeled
    padding-top 16px
    &.text-area
      .container
        height 128px
        box-sizing border-box
        >>> textarea
          z-index 1
          width 100%
          top 18px
          resize none
          box-sizing border-box
    .container
      height 44px
      padding 0 24px
    >>> label
      position relative
      bottom 22px
      opacity 0
      font-size .96em
      font-weight bold
    >>> input, >>> textarea
      padding 0 24px
      padding-top 32px
      position absolute
      left 0
      top -18px
      box-sizing: border-box;
      &:focus, &:active
        &::placeholder
          opacity 1
          transition all 480ms
          transform scale(.96) translateY(-37px)
          opacity .75
          transform-origin left
          color var(--clr-blue)
      &:focus + label
        opacity .75
</style>
