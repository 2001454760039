<template lang='pug'>
  #topic.view
    w-modal( :show='modal.request' )
      session-request(
        v-if='modal.request && profile.id != teacher.id'
        :user='user' 
        :profile='profile' 
        :teacher='teacher' 
        :topics='topics'
        @close='modal.request = false'
      )

    header
      w-wrapper
        .profile
          w-flex.head 
            img.avatar(:src='teacher.avatar')
            .stats
              h4 {{ teacher.display }}
              p.location {{ teacher.location }}
              p.languages {{ teacher.languages }}
              .followship
                span.boldnum {{ followings.length }} 
                span {{ $t("Followings")}}
                span.boldnum {{ followers.length }} 
                span {{ $t("Followers")}}

          .buttons.centered-text
            follow-button( :follower='profile' :leader='teacher' :followers='followers' )
            button.chat( v-if="teacher.status=='online'" @click='connect' ) {{ $t("Connect to Chat")}}
            img.actionable( src='/sendmsg.svg' width='50' @click='$router.push({ name: "messages", params: { id: teacher.id } })')


          p.intro {{ teacher.intro }}

        w-flex.chat.column
          p {{ $t("Chat with me: ") }}
          h4.title {{ topic.title }}
          .buttons.centered-text
            button.chat {{ $t("Schedule a Chat") }}

    section.details
      w-wrapper
        w-flex.head
          table.lang
            tr
              th {{ $t("Main Language:") }}
              td {{ topic.main }}
            tr
              th {{ $t("Additional Language(s):")}}
              td {{ topic.other }}
          table.cost
            tr
              th {{ $t("Estimated Time:")}}
              td {{ topic.duration }} {{ $t("mins")}}
            tr
              th {{ $t("Points")}}
              td {{ topic.points }} {{$t("Pts")}}
        p.desc {{ topic.desc }}
        
        .images
          h6 {{ $t("Additional Information")}}
          w-flex.container
            img.image( v-for='img in images' :src='img.src' )
    
    section.chat.centered-text
      button.chat {{ $t("Schedule a Chat") }}
</template>

<script>
  import { db } from '@/main'
  import { Viewable } from '@/mixins/authenticable'
  import { Sociable } from '@/mixins/firebase'
  import FollowButton from '@/components/buttons/follow'
  import SessionRequest from '@/components/modals/session-request'


  export default {
    name: 'topic.view',

    mixins: [ Viewable, Sociable ],
    
     components: {
      SessionRequest,
      FollowButton
    },

    data() {
      return {
        modal: { request: false },
        topic: {},
        teacher: {},
        images: [],
        following: {},
        message: '',
        messageunfollow: '',
        followings: [],
        followers: [],
        topics: []
      }
    },

    firestore() {
      return {
        topic: {
          ref: db.collection( 'topics' ).doc( this.$route.params.id ),
          resolve: doc => {
            this.$binding( 'topics', db.collection('topics').where('uid', '==', doc.uid) )
            this.$binding( 'teacher',  db.collection( 'profiles' ).doc( doc.pid ) ) 
            this.$binding( 'following', db.collection( 'followings' ).doc( `${this.user.uid}->${doc.uid}` ) )
            this.$binding( 'followings', db.collection( 'followings' ).where( 'fid', '==', doc.pid ) ),
            this.$binding( 'followers', db.collection( 'followings' ).where( 'lid', '==', doc.uid ) )
            this.$binding( 'images', db.collection( 'topics' ).doc( this.$route.params.id ).collection( 'images' ).orderBy( 'index' ) )
          }
        },

        images: db.collection( 'topics' ).doc( this.$route.params.id ).collection( 'images' )
      }
    },

    methods: {
      connect() {
        if ( this.user ) this.modal.request = true
        else this.$router.push({ name: 'sign-in' })
      },

      unfollow() {
        this.messageunfollow = $t("Are you sure you want to unfollow ") + `${this.teacher.display}?`
      },
      follow() {
        if ( this.following.id ) {
          this.dissociate( 'followings', this.user.uid, this.teacher.uid )
          this.following = {}
          this.messageunfollow =''
        }
        else this.associate( 'followings', this.user.uid, this.teacher.uid )
      },

      reserved(rsv) {
      //  this.message = `Your reservation has been made for "${rsv.topic.title}" on ${rsv.reserved.on}. Please check your homepage for details on your reservation.`
          this.message = this.$t('Your reservation has been made. Please check your homepage for details.')
      }
    }
  }
</script>

<style lang='stylus' scoped>
  #topic
    background rgba( 0 0 0 .5 )

    .w-wrapper
      background rgba( 255 255 255 .92 )
      padding 24px
      border-radius 3px

  header, section
    margin-bottom 32px

  header
    .w-wrapper
      display grid
      grid-template-columns repeat( auto-fit, minmax( 320px, 1fr ) )
      grid-gap 32px

  .profile
    .head 
      .avatar
        width 128px
        height 128px
        border-radius 50%
        margin-right 32px
        object-fit cover
      p
        margin 12px 0
      
      .followship
        span:first-child
          margin-right 5px
        .boldnum
          font-weight bold
          margin-left 5px


    .buttons
      display flex
      align-items center
      justify-content space-between
      margin 10px 0
      .follow.button
        margin-left 25px

      button.chat
        padding 8px 24px
        border-radius 5px
      .chat
        font-size .9em
        background var(--clr-blue)
        color white
        padding 6px 16px

  .chat
    .title
      margin 24px 0
    .buttons
      margin 12px 0
      margin-top auto

  button.chat
    background var(--clr-orange)
    color white
    padding 4px 32px
    border-radius 5px

  section.details
    .w-wrapper
      .head
        justify-content space-between
        flex-wrap wrap
        table
          width 280px 
          th, td
            padding 4px
          th
            text-align right
            padding-right 8px
          td 
            color var(--clr-blue)
      
      .desc
        margin 32px 0
        font-size .85em
      
      .images
        .container
          margin-top 16px
          img
            height 96px
            margin-left 16px
            &:first-child
              margin-left 0
</style>

