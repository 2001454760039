<template lang="pug">
  .inbox.message.component 
    img.author( :src='msg.author.avatar' :class='{ hide: hide}' )
    p.body {{ msg.body }}
</template>

<script>
  export default {
    name: 'inbox.message.component',

    props: [ 'msg', 'hide' ],

    data() {
      return {
      }
    }
  }
</script>

<style lang="stylus" scoped>
  .message
    --avatar-width 42px
    display flex
    margin 10px 10px
    align-items center
    &:first-child
      margin-top 0
    .author
      width var(--avatar-width)
      height var(--avatar-width)
      border-radius 50%
      object-fit cover
      margin 0 8px 0 0
      &.hide
        visibility hidden
    .body
      background rgb( 220 220 220 )
      padding 5px 15px
      border-radius 5px
  
  .message.mine
    flex-direction row-reverse
    .author
      display none
    .body
      background var(--clr-blue)
      color white
</style>
