<template lang='pug'>
  .profile.card
    w-wrapper( v-bind:class="['followhead',observable.status]")
      img.avatar.actionable( :src='observable.avatar' @click='$router.push({ name: "profile", params: { id: item.id } })' )
      span( v-if="this.user.uid!=item.uid" )
        follow-button( :follower='user' :leader='item' :followers='followers' )
    
    .title
      .heading
        h6.display.actionable( @click='$router.push({ name: "profile", params: { id: item.id } })' ) {{ observable.display }}
        span.video-icon(v-if="observable.youtube")
          video-icon
        span( v-bind:class="['status', observable.status]" ) {{ $t(observable.status) }}
        
      .followship
        span.followings.boldnum {{ followings.length }}
        span.followings {{ $t('Followings') }}
        span.followers.boldnum {{ followers.length }} 
        span.followers {{ $t('Followers') }}
        
      .demographics
        img( v-if='observable.languages' src='/lang.svg' width='13')
        span.languages {{ observable.languages }}
        img( v-if='observable.location' src='/location.svg' width='10')
        span.location {{ observable.location }}
      .buttons(v-if="this.user.uid!=observable.uid")
        button( 
          v-if='observable.status == "online" && topics.length'
          @click='$emit("connect")'
        ) {{ $t('Connect to Chat') }}
        button.inactive( v-if="observable.status == 'offline' && topics.length" @click='$router.push({ name: "profile", params: { id: item.id } })' ) {{ $t('Schedule a Chat')}}
        img.sendmsg( src='/sendmsg.svg' width='50' @click='message(item)')
    .body
      p.desc {{ observable.intro }}
      .tags(v-if='topics.length')
        span.tagtitle {{ $t('Chat with me: ') }}
        span.tag( v-for='t in topics' ) 
          span( @click='$router.push({ name: "topic", params: { id: t.id } })' ) {{ t.title }}
      
        router-link.more-icon( :to='{ name: "profile", params: { id: item.id } }' )
          triangle-icon
</template>

<script>
  import { db } from '@/main'
  import TriangleIcon from 'vue-material-design-icons/Triangle.vue'
  import FollowButton from '@/components/buttons/follow'
  import { Threads } from '@/mixins/firebase'

  export default {
    name: 'profile.card.component',

    mixins: [ Threads ],

    components: {
      TriangleIcon,
      FollowButton
    },

    props: { 
      item: { required: true }, 
      user: { required: true },
      profile: { required: true }
    },

    data() {
      return {
        topics: [],
        followings: 0,
        followers: 0,
        observable: {}
      }
    },

    firestore() {
      return {
        topics: db.collection( 'topics' ).where( 'pid', '==', this.item.id ),
        followings: db.collection( 'followings' ).where( 'fid', '==', this.item.uid ),
        followers: db.collection( 'followings' ).where( 'lid', '==', this.item.uid ),
        observable: db.collection( 'profiles' ).doc( this.item.id )
      }
    },

    methods: {
      async message( dst ) {
        let ids = [ this.profile.id, dst.id ].sort()
        let id = `${ids[0]}<->${ids[1]}` 
        let thread = await db.collection( 'threads' ).doc( id ).get()
        if ( !thread.exists ) this.create( this.profile, dst )
        else console.log('thread exists')
        this.$router.push({ name: "messages", params: { current: id } })
      }
    }
  }
</script>

<style lang="stylus" scoped>
  .profile
    display grid
    grid-template-columns 104px 1fr
    padding 10px
    .followhead
      text-align center
      background rgb( 174 209 233)
      border 1px solid rgb(200 200 200)
      border-radius 5px
      padding 10px
      
    .followhead.offline
      background rgb( 240 240 240 )
      
    .card 
      padding 20px 20px 0
    .avatar
      width 80px
      height 80px
      object-fit cover
      border-radius 50%
      background rgb( 255 255 255)
    .title
      position relative
      margin-left 15px
      .heading
        display flex
        align-items center
        .video-icon
          margin-left 20px
          font-size 2em
          @media(max-width: 560px)
            font-size 1.4em
        .status
          font-size .8em
          background var(--clr-orange)
          color white
          padding 4px 12px
          border-radius 5px
          margin-left auto
        .status.offline
          background lightgrey
        
      .followship
        font-size .9em
        .followings
          margin-right 10px
        margin 10px 0
        .boldnum
          font-weight bold
          margin-right 5px
      .demographics
        .location, .languages
          padding-left 5px
        font-size .9em
        .languages
          margin-right 16px
     
      .buttons
        position relative 
        display flex 
        margin-top 10px
        button
          font-size .9em
          background var(--clr-blue)
          color white
          padding 6px 16px
          margin 4px 0
          margin-right 12px
          border-radius 5px
          &:last-child
            margin-right 0px
        .inactive 
          background var(--clr-orange)
          cursor pointer
        .sendmsg
          margin-left auto
          cursor pointer
        @media( max-width: 1080px)
          font-size .8em

    .body
      grid-column 1 / -1
      p
        margin-bottom 10px
      
      .tags
        font-size .8em
        display flex
        flex-wrap wrap
        align-items center
        span:first-child
          margin-right 16px
        .tagtitle
          font-weight bold
        .tag
          background rgb( 240 240 240 )
          color var(--clr-blue)
          padding 4px 12px
          margin 4px 0
          margin-right 12px
          border 1px solid var(--clr-blue)
          border-radius 5px
          cursor pointer
          &:last-child
            margin-right 0
        .more-icon
          margin-left auto
          transform rotate(180deg)
          font-size 1.6em
</style>
