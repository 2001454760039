<template lang='pug'>
  transition
    w-flex.centered.w-notice(v-show='message')
      w-flex.centered.wrapper.column(v-if='unfollow')
        .message
          span.message {{ $t(message) }}
          span.close(@click='$emit("close")')
            close-icon
        slot
      w-flex.wrapper.centered(v-else)
        span.message {{ $t(message) }}
        span.close(@click='$emit("close")')
          close-icon

</template>

<script>
  import { auth } from '@/main'

  export default {
    name: 'w-notice.component',

    props: {
      message: String,
      unfollow: Boolean
    },
  }
</script>

<style scoped lang='stylus'>
  .w-notice
    position fixed
    top 0
    width 100vw
    height 100vh
    background rgba( 0 0 0 .3 )
    z-index 8
    text-align center
    .wrapper
      padding 16px 32px
      margin 32px
      color var(--clr-notice)
      background var(--bg-notice)
      position relative
      border-radius 9px
      border 1px solid white
      box-shadow 3px 3px 5px rgba( 80 80 80 .5 )
    .message
      padding 0 28px
    .close
      position absolute
      right 12px
      // top 0
      font-size 1.2em
      background rgba( 255 255 255 .25 )
      padding 4px 8px
      border-radius 50%
      cursor pointer
</style>
