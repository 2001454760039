<template lang='pug'>
  .base-card.component
    slot
</template>

<script>
  import { db } from '@/main'

  export default {
    name: 'base-card.component'
  }
</script>

<style scoped lang='stylus'>
  .base-card
    border 1px solid rgb( 240 240 240 )
    border-radius 5px
    box-shadow var(--shadow-card)
    overflow hidden
    background white

  .base-card.padded
    padding 24px

  .base-card.padded.roomy
    padding 24px

  .base-card.overflowable
    overflow visible

  .base-card.bg-240
    background rgb( 240 240 240 )

  .base-card.borderless 
    border 0

</style>
