<template lang='pug'>
  .w-grid.component
    slot
</template>

<script>
  // import { ContainerMixin, ElementMixin } from 'vue-slicksort'

  export default {
    name: 'digi-grid.component',
  }
</script>

<style scoped lang='stylus'>
  .w-grid
    display grid
    grid-template-columns repeat( auto-fit, minmax( 320px, 1fr ) )
    grid-gap 2vh 2vw

  .w-grid.dense
    // grid-auto-flow dense

  .w-grid.loose
    grid-gap 3vh 3vw
  .w-grid.tight
    grid-gap 1vh 1vw
  .w-grid.tighter
    grid-gap .5vh .5vw
  .w-grid.tightest
    grid-gap .25vh .25vw

  .w-grid.small
    grid-template-columns repeat( auto-fill, minmax( 280px, 1fr ) )
    grid-auto-rows 240px

  .w-grid.smaller
    grid-template-columns repeat( auto-fill, minmax( 240px, 1fr ) )
    grid-auto-rows 240px
    
  .w-grid.smallest
    grid-template-columns repeat( auto-fill, minmax( 220px, 1fr ) )
    grid-auto-rows 220px

  .w-grid.cards
    grid-template-columns repeat( auto-fill, minmax( 240px, 1fr ) )
  .w-grid.compact
    grid-template-columns repeat( auto-fill, minmax( 192px, 1fr ) )
  .w-grid.mini-cards
    grid-template-columns repeat( auto-fill, minmax( 190px, 1fr ) )
  .w-grid.tiny-cards
    grid-template-columns repeat( auto-fill, minmax( 190px, 1fr ) )
  .w-grid.big-thumbs
    grid-template-columns repeat( auto-fit, minmax( 148px, 1fr ) )
  .w-grid.thumbs
    grid-template-columns repeat( auto-fill, minmax( 108px, 1fr ) )

  .w-grid.list
    grid-template-columns 1fr

  .w-grid.min-64
    grid-template-columns repeat( auto-fit, minmax( 64px, 1fr ) )
  .w-grid.min-80
    grid-template-columns repeat( auto-fit, minmax( 80px, 1fr ) )
  .w-grid.min-96
    grid-template-columns repeat( auto-fit, minmax( 96px, 1fr ) )

  .w-grid.percent-50
    grid-template-columns 1fr 1fr
  .w-grid.percent-33
    grid-template-columns 1fr 1fr 1fr
</style>
