<template lang='pug'>
  #sign-in.view
    w-notice(:message='message' @close='message = ""')
    w-wrapper
      form.sign-up-form(@submit.prevent='sign_in')
        fieldset
          w-input
            template(slot='icon')
              email-icon
            input( :placeholder='$t("Email")' v-model='email' )

          w-divider.card
          w-input
            template(slot='icon')
              password-icon
            input( :placeholder='$t("Password")' v-model='password' type='password')

          w-divider.card
          w-flex.space-between.forget
            .remember-me
              input(type='checkbox' checked)
              span {{ $t(' Remember me') }}

            .forgot-password-link
              router-link.text-only(:to='{ name: "forgot-password" }') {{ $t('Forgot Password?') }}

          w-divider.section
          w-flex.centered
            w-button.combo(type='submit')
              template(v-slot:text) {{ $t('Sign In') }}

        w-divider.card
        .auth-link.centered-text
          span {{ $t("Don't have an account?") }}
          router-link(:to="{ name: 'sign-up', params: {} }") {{ $t("Sign Up") }}
        w-divider.card
        p.centered-text {{ $t("Or") }}
        w-divider.card
        w-wrapper.max-280
          w-flex.social-login.centered.space-between
            img(
              src='/facebook.png'
              @click='social("facebook")'
            )
            img(
              src='/google-plus.png'
              @click='social("google")'
            )
            router-link(to='/phone-sign-in')
              img(src='/phone.png')
</template>

<script>
  import { auth, firebase, db } from '@/main'

  export default {
    name: 'sign-in.view',

    components: {
    },


    data() {
      return {
        email: '',
        password: '',
        confirm: '',
        message: '',
        language: 'English',
        show: false,
      }
    },

    methods: {
      sign_in() {
        auth.signInWithEmailAndPassword( this.email, this.password )
        .then( doc => {
          let timestamp = new Date()
          this.$emit( 'auth', { timestamp, uid: doc.user.uid } ) 
          // localStorage.setItem( 'timestamp', JSON.stringify( timestamp ) )
          // set observable and revoke other devices' tokens
          db.collection( 'authentications' ).doc( doc.user.uid ).set({ uid: doc.user.uid, time: timestamp }).then( () => {
            this.$router.push('/')
          }).catch( e => console.log(e) )

        })
        .catch( e => this.message = this.$t(e) )
      },

      social(provider) {
        switch (provider) {
          case 'google':
            provider = new firebase.auth.GoogleAuthProvider()
            break
          case 'facebook':
            provider = new firebase.auth.FacebookAuthProvider()
            break
        }
        auth.signInWithPopup(provider).then( this.accountify ).catch( e => alert(e) )
        this.message = $t('Welcome back to WorldTone!')
        setTimeout( () => {this.message = ''}, 2500)
        setTimeout( () => {this.$router.push('/basic-info')}, 2000 )
      }
    }
  }
</script>

<style scoped lang='stylus'>
  #sign-in
    // width 100%
  .sign-up-form 
    background rgb(255 255 255)
    max-width 480px
    border-radius 15px
    margin 32px auto
    padding 32px 64px
    box-shadow 2px 2px 8px rgba( 80 80 80 .5 )
    @media(max-width: 640px)
      padding 32px 12%

  .forget 
    @media(max-width: 640px)
      flex-direction column
    .remember-me, .forgot-password-link
      @media(max-width: 640px)
        margin 12px auto
    .forgot-password-link 
      cursor pointer
</style>
