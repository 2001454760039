<template lang='pug'>
  #my-profile.view
    w-notice(:message='message' @close='message = ""')
    header
      w-wrapper
        w-flex
          h4.title {{ $t('Profile')}}
    
    section.my-profile
      w-wrapper.mainbody
        form(@submit.prevent='update').container
          .avatar
            w-flex.logo.centered.actionable(@click.native='$refs.upload.click()')
              input(type='file' ref='upload' style='display: none' accept='image/*' @change='upload($event, "logo")')
              template(v-if='profile.avatar')
                w-flex.column
                  .image-container
                    img(:src='profile.avatar')
                    p {{ $t('(1MB Max)') }}
              template(v-else)
                w-flex.upload-container.centered.column
                  img(src='/no-profile-image.png')
                  span {{ $t('Add Image') }}
        
          base-card.padded
            .toggles
              span Private:
              span
                input( type='radio' v-model='private' :value='true')
                label Yes
              span
                input( type='radio' v-model='private' :value='false')
                label No

            .url
              span Access URL: 
              span https://www.worldtone.com/profile/{{profile.id}}/
              input( type='text' v-model='access' )
              input.copy( 
                ref='copy' 
                type='text'
                :value='`https://www.worldtone.com/profile/${profile.id}/${profile.access}`'
              )
              span.copy-btn( @click='copy' )
                copy-icon

          .primary-fields
            fieldset
              label {{ $t('My Name') }}:
              input(type='text' v-model='profile.display')
            fieldset
              label {{ $t('Gender') }}:
              input(type='text' v-model='profile.gender')
            fieldset
              label {{ $t('Email') }}:
              input(type='email' v-model='profile.email')
            fieldset
              label {{ $t('Current Password') }}:
              input(type='password' v-model='password.current')
              router-link(:to='{ name: "forgot-password" }') {{ $t('Forgot Password?') }}
            fieldset
              label {{ $t('New Password') }}:
              input(type='password' v-model='password.new')
            fieldset
              label {{ $t('Verify Password') }}:
              input(type='password' v-model='password.confirm')
          
          .secondary-fields
            fieldset
              label {{ $t('Location (Country)') }}:
              input(type='text' v-model='profile.location')
            fieldset
              label {{ $t('Native Language') }}:
              input(type='text' v-model='profile.native')
            fieldset
              label {{ $t('Other Languages') }}:
              textarea(v-model='profile.languages')
            fieldset
              label {{ $t('Intro') }}:
              textarea(v-model='profile.intro')
            fieldset
              label {{ $t('Video Intro (YouTube Link)') }}:
              input(type='text' v-model='profile.youtube')
          fieldset.paypal
            label {{ $t('Payment (Paypal)') }}:
            input(type='text' v-model='profile.paypal')
          
          .submit.centered-text
            button(type='submit') {{ $t('Update') }}

      w-divider.section
</template>

<script>
  import { db, auth, str } from '@/main'

  export default {
    name: 'my-profile.view',

    props: {
      user: { type: Object, default: () => ({}) },
      profile: { type: Object, default: () => ({}) }
    },

    data() {
      return {
        key: 0,
        access: 'access-key',
        password: {},
        message: '',
        private: null,
      }
    },

    watch: {
      profile( curr, prev ) {
        if ( curr.id ) {
          this.private = !!curr.private
          this.access  = curr.access || 'access-key'
        }
      }
    },

    created() {
      this.private = !!this.profile.private
      this.access = this.profile.access || 'access-key'
    },

    methods: {
      copy() {
        var copy = this.$refs.copy

        copy.select()
        copy.setSelectionRange(0, 99999) /*For mobile devices*/

        document.execCommand("copy")

        alert("Copied the text: " + copy.value)
      },

      update() {
        if ( this.user.email == this.profile.email ) {
          this.set()
          if ( this.password.current && this.password.new && this.password.confirm && this.password.current.length > 0 ) {
            auth.signInWithEmailAndPassword( this.user.email, this.password.current )
            .then( (doc) => {
              this.message = 'Authenticated:  Updating password...'
              if ( this.password.new != this.password.confirm )
                this.message = 'Information Updated. Please input the current password and new/confirmation password if you want to change the password.'
              else
                this.user.updatePassword( this.password.new )
                  .then( () => {
                    this.message = this.$t('Information & Password updated!')
                    setTimeout( () => { this.message = ''}, 3000 )
                    this.password = {}
                  })
                  .catch( e => this.message = this.$t("Information Updated but,") + `${e}`)
            })
            .catch( e => this.message = $t("Information Updated but,") + `${e}`)
          }
          else if ( Object.keys(this.password).length > 0 ) {
            this.message = 'Information Updated, but please input your current password, new password and verify password correctly to change new password'
          }
          else { 
            this.message = this.$t('Information Updated!')
            setTimeout( () => { this.message = ''}, 1500 )
          }
        }

        else {
          if ( this.password.current ) {
            this.set()
            auth.signInWithEmailAndPassword( this.user.email, this.password.current )
            .then( (doc) => {
              this.message = this.$t('Authenticated: Updating Email...')
              this.user.updateEmail( this.profile.email )
                .then( () => {
                  if ( this.password.current && this.password.new && this.password.confirm ) {
                    auth.signInWithEmailAndPassword( this.user.email, this.password.current )
                      .then( (doc) => {
                        if ( this.password.new != this.password.confirm )
                          this.message = this.$t('Password and confirmation password does not match')
                        else {
                          this.user.updatePassword( this.password.new )
                            .then( () => {
                              this.message = this.$t('Information & Email/Password updated!')
                              setTimeout( () => { this.message = ''}, 3000 )
                              this.password = {}
                            })
                            .catch( e => this.message = `Information & Email updated! + ${e}` )
                        }
                      })
                      .catch( e => this.message = `Information & Email updated! + ${e}`  )
                  }
                  else {
                    this.password = {}
                    this.set()
                    this.message = this.$t('Information & Email Updated!')
                    setTimeout( () => { this.message = ''}, 2500 )
                  }
                })
            }).catch( e => this.message = $t('Please input your current password to update your email') )
          }
          else this.message = this.$t('Please input the current password correctly to update your email')
        }
      },

      set() {
        db.collection( 'profiles' ).doc( this.profile.id ).update({ 
          ...this.profile,
          private: this.private,
          access: this.access
        })
          // .then( () => {
          //   this.message = 'Information Updated!'
          //   setTimeout( () => { this.message = ''}, 1500 )
          // })
      },

      upload(e, field) {

        // let file = e.target.files[0]
        // let ref = str.ref(`/user/${this.user.uid}/${file.name}`)
        // ref.put(file).then( snap => {
        //   snap.ref.getDownloadURL().then( url => {
        //     this.user.updateProfile({
        //       photoURL: url
        //     }).then( () => this.key++ )
        //   })
        // })

        let reader  = new FileReader()
        reader.addEventListener('load', () => {
          this.$set(this.profile, 'avatar', reader.result)
          // this.profile.avatar = reader.result
        }, false)

        let file = e.target.files[0]
        if (file && file.size <= 999999) reader.readAsDataURL(file)
        else this.message = 'File size must be under 1 mb'
      },
    }
  }

</script>

<style scoped lang='stylus'>
  #my-profile
    .w-wrapper
      padding 10px 18px 10px 18px
    .mainbody
      background rgba( 255 255 255 .95)
      border-radius 5px
  
  header
    margin-top -30px
    background rgba( 80 160 210 .95 )
    color white  
    @media( max-width: 420px)
      font-size: .8em
      padding 0px

  section
    background rgba( 0 0 0 .5 )
    padding 20px

  .copy
    position absolute
    z-index -1

  .toggles
    margin-bottom 16px
    span:first-child
      margin-right 16px

    input, label, span
      
      display inline-block
      width auto
    span
      margin-right 16px
    input
      margin-right 8px
  
  .url
    word-break break-all
    span:first-child
      margin-right 4px
    input
      display inline
      width 98px
      padding 4px 8px
    .copy-btn
      font-size 1.5em
      position relative
      margin-left 4px
      top 2px

  form
    display grid
    grid-template-columns 88px 1fr 1fr
    grid-gap 32px 64px
    padding 32px 0
    padding-right 64px

    .primary-fields
      grid-column-start 2 

    .padded
      grid-column 2 / -1

    @media(max-width: 800px)
      grid-template-columns 1fr 1fr
      padding-right 0
      grid-gap 32px
      padding 16px
      .primary-fields
        grid-column-start 1
      .padded
        grid-column 1 / -1
        margin-top 48px

    @media(max-width: 680px)
      grid-template-columns 1fr
      grid-template-rows 1fr
      grid-gap 8px
      padding-top 48px

    h1 
      @media(max-width: 800px)
        grid-column 1 / -1

    .submit
      grid-column 2 / -1
      @media(max-width: 800px)
        grid-column 1 / -1
      @media(max-width: 680px)
        margin-top 24px
      button
        background rgb( 76 154 207 )
        color white
        padding 8px 48px
        border-radius 5px

    .paypal
      grid-column 2 / -1
      background rgb( 240 200 50 )
      padding 16px 24px
      border 1px solid
      border-radius 5px
      width 75%
      margin 0 auto
      @media(max-width: 800px)
        grid-column 1 / -1
      @media(max-width: 680px)
        margin-top 24px
      label
        font-size 1.25em
        margin-right 16px
      input
        width calc( 100% - 192px )
        @media(max-width: 480px)
          width 100%

      label, input
        display inline
      
    .avatar
      img
        border-radius 50%
        height 88px
        width 88px
        object-fit cover
      @media(max-width: 800px)
        position absolute
        top 20px
        right 25px
      p 
        color rgb( 180 180 180 )
        margin-left 2px
      .image-container
        margin-left 30px
        text-align center
        
    fieldset
      margin 4px 0
    label
      display block
      margin 4px 0
    input, textarea
      display block
      background rgb( 255 255 255 )
      border-radius 5px
      padding 8px 8px
      border 1px solid rgb( 225 225 225 )
      width 100%
      box-sizing border-box
    a
      display block
      text-align right
      font-size .8em
      color blue
      margin 4px
</style>
