<template lang='pug'>
  #sign-up.view
    w-wrapper
      w-divider.page
      form.sign-up-form(@submit.prevent='sign_up')
        fieldset
          w-input
            input( required :placeholder='$t("My Name")' v-model='display' )

          w-divider.card
          w-input
            suglify(
              :slugify='display'
              :slug.sync='slug'
            )
              input.slug(
                v-model='slug'
                slot-scope='{inputBidding}'
                v-bind='inputBidding'
                type='text'
                :placeholder='$t("Username")'
              )

          w-divider.card
          w-input
            template(slot='icon')
              email-icon
            input( type='email' required :placeholder='$t("Email")' v-model='email' )

          w-divider.card
          w-input
            template(slot='icon')
              password-icon
            input( required :placeholder='$t("Password")' v-model='password' type='password')

          w-divider.card
          w-input
            template(slot='icon')
              password-icon
            input( required :placeholder='$t("Confirm Password")' v-model='confirm' type='password')

          w-divider.borderless.card
          w-flex.centered
            w-button.combo(type='submit')
              template(slot='text') {{ $t('Sign Up') }}

        w-divider.card
        .auth-link.centered-text
          span {{ $t("Already have an account?") }}
          router-link(:to="{ name: 'sign-in', params: {} }") {{ $t("Sign In") }}

        w-divider.card

        p.centered-text {{ $t('Or') }}
          w-divider.card
          w-wrapper.max-280
            w-flex.social-login.centered.space-between
              img(
                src='/facebook.png'
                @click='social("facebook")'
              )
              img(
                src='/google-plus.png'
                @click='social("google")'
              )
              router-link(to='/phone-sign-in')
                img(src='/phone.png')
</template>

<script>
  import { auth, db } from '@/main'

  export default {
    name: 'sign-up.view',

    components: {
    },

    data() {
      return {
        display: '',
        slug: '',
        email: '',
        password: '',
        confirm: '',
        message: '',
        language: 'English',
        show: false,
        profiles: [],
        slugs: []
      }
    },

    firestore() {
      return {
        profiles: {
          ref: db.collection( 'profiles' ),
          resolve: docs => this.slugs = docs.map( doc => doc.id )
        }
      }
    },

    methods: {
      sign_up() {
        if ( this.confirm == this.password ) {
          console.log( 'confirmed ')
          if ( this.slugs.includes( this.slug ) ) this.message = this.$t('Username is taken.  Please select another one.')
          else {
            console.log( 'username is ok' )
            auth.createUserWithEmailAndPassword( this.email, this.password ).then( doc => {
              console.log( 'auth usr created' )
              let profile = {
                status: 'offline',
                uid: doc.user.uid,
                email: doc.user.email || this.email || '',
                phone: doc.user.phoneNumber || this.phone || '',
                display: this.display || '',
                gender: '',
                location: '',
                locale:  this.$i18n ? this.$i18n.locale : localStorage.getItem('locale'),
                private: false
              }
              console.log( 'setting  profile ' )
              db.collection('profiles').doc( this.slug ).set( profile, { merge: true } )
              .then( () => console.log( 'profile set success' ) )
              .catch( e => console.log('set profile err', e ) )

              let ref = this.$route.params.ref 
              if ( ref ) db.collection( 'referrals' ).doc( ref ).update({ 
                joined: true,
                profile: profile
              }).then( snap => {
                console.log( 'referred and updated referral', snap )
                if ( ref.follow ) {
                  // auto-follow referrer
                  db.collection( 'followings' ).doc( `${profile.uid}->${ref}` )
                  // redirect to my-follows
                  this.$router.push({ name: 'my-follows' })
                }
                else {
                  console.log( 'NOT followed' )
                  this.$router.push({ name: 'profile' })
                }
              }).catch( e => console.log(e) )

              else {
                console.log( 'not referred' )
                this.$router.push({ name: 'profile' })
              }

            }).catch( e => console.log( e ) )
          }
        }
        else
          this.message =  $t('Password and confirm password does not match')
      },

      social(provider) {
        switch (provider) {
          case 'google':
            provider = new firebase.auth.GoogleAuthProvider()
            break
          case 'facebook':
            provider = new firebase.auth.FacebookAuthProvider()
            break
        }
        auth.signInWithPopup(provider).then( this.accountify ).catch( e => alert(e) )
        this.message = 'Welcome to WorldTone, please wait while we re-direct you'
        setTimeout( () => {this.message = ''}, 2500)
        setTimeout( () => {this.$router.push('/basic-info')}, 2000 )
      }
    }
  }
</script>

<style scoped lang='stylus'>
  .sign-up-form 
    background rgb(255 255 255)
    max-width 480px
    border-radius 5px
    margin 32px auto
    padding 32px 64px
    box-shadow 2px 2px 8px rgba( 80 80 80 .5 )
    @media(max-width: 640px)
      padding 32px 12%
</style>
