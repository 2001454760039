<template lang='pug'>
  #chargeup.view
    w-notice(:message='message' @close='message = ""')
    header
      w-wrapper
        w-flex
          h4.title {{ $t('Charge Up')}}
          .current-points(v-if='profile.id')
            span.bold {{ $t('Current Points') }}:  
            span.bold.color-red.em13 {{ profile.points || 0 }}

    section.charge-up
      w-wrapper
        w-grid.big-thumbs.dense.grid
          .top-up.container(v-for='item, i in charge' :key='i' @click='select(item)' :class='{ selected: selected.amount == item.amount }')
            .points
              p {{ item.points }}
              p.color-gray.em11 {{ $t('Points') }}
            .extra(v-if='item.extra') {{ $t('Extra') }} {{ item.extra }} {{ $t('Points') }}
            .price {{ item.price }}
          .top-up.container.gold(v-for='item, i in gold' :key='item.points' @click='select(item)' :class='{ selected: selected.amount == item.amount }')
            .points
              p.color-gold {{ item.points }}
              p.em11 {{ $t('Points') }}
            .extra {{ $t('Extra') }} {{ item.extra }} {{ $t('Points') }}
            .price.gold {{ item.price }}
            .tag
              span {{ $t('Best Value') }}
      
        w-divider.section.borderless
        .payment
          base-card.padded( v-if='selected' )
            card.stripe-card(
              stripe='pk_test_OkfwqFBL4znAZ8s0hGo6VGNo007E7UusKr'
              :class='{ complete }'
              :options='{}'
              @change='complete = $event.complete'
            )
          w-divider.card
          w-button( v-if='selected' @click.native='pay' )
            template( v-slot:text ) {{ $t('Pay') }}

    section.qa
      w-wrapper
        h4 + What is Point for?
        h4 + Do I need Point to use WorldTone?
        h4 + How do I get points?
        h4 + Can I withdraw my points?

    section.my-points(v-if='profile.id')
      w-wrapper.container
        w-flex.active-tab.color-gray
          p.tab(@click='tab = "Purchase"' :class="{active: tab== 'Purchase'}") {{ $t("Purchase Record") }}
          p.tab(@click='tab = "Send"' :class="{active: tab== 'Send'}") {{ $t("Points Send") }}
          p.tab(@click='tab = "Receive"' :class="{active: tab== 'Receive'}") {{ $t("Points Received") }}
          p.tab(@click='tab = "Withdraw"' :class="{active: tab== 'Withdraw'}") {{ $t("Withdraw Record") }}

        w-divider.card

        w-flex.column.sessions-info
          w-flex.info-title
            w-flex.space-between.front
              p {{ $t("Purchase Date") }}
              p {{ $t("Paid By") }}
            p.received {{ $t("Points Received") }}
          .info-detail
            w-flex.no-purchase
              p.em13.color-gray(v-if='!session') {{ $t("No purchase history.") }}
        
        .buttons.centered-text
          button {{ $t('Withdraw Points') }}
</template>

<script>
  import { db } from '@/main'
  import { Viewable } from '@/mixins/authenticable'
  import { Card, createToken } from 'vue-stripe-elements-plus'

  export default {
    name: 'charge.view',

    mixins: [ Viewable ],

    components: { Card },

    data() {
      return {
        cus: {},
        tokens: [],
        complete: false,
        points: {},
        stripeOptions: {
          // https://stripe.com/docs/stripe.js#element-options
        },
        selected: false,
        tab: 'Purchase',
        session: null,

        charge: [
          {
            points: '100',
            pts: 100,
            price: 'US $1.00',
            amount: 100,
            extra: null
          },

          {
            points: '500',
            pts: 500,
            price: 'US $5.00',
            amount: 500,
            extra: null
          },

          {
            points: '1,100',
            pts: 1100,
            price: 'US $10.00',
            amount: 1000,
            extra: '100'
          },

          {
            points: '2,300',
            pts: 2300,
            price: 'US $20.00',
            amount: 20000,
            extra: '300'
          }
        ],

        gold: [
          {
            points: '6,000',
            pts: 6000,
            price: 'US $50.00',
            amount: 50000,
            extra: '1000'
          }
        ],

        gold: [
          {
            points: '6,000',
            pts: 6000,
            price: 'US $50.00',
            amount: 50000,
            extra: '1000'
          }
        ],
        step: 0,
        total: 0,
        newpoints: 0,
        message: ''
      }
    },

    firestore() {
      if ( this.user.uid ) return {
        cus: {
          ref: db.collection( 'stripe-customers' ).doc( this.user.uid ),
          resolve: doc => this.$binding( 
            'tokens', 
            db.collection( 'stripe-customers' ).doc( doc.id ).collection( 'tokens' )
          )
        }
      }
    },

    watch: {
      user(curr, prev) {
        if ( curr.uid ) this.$binding( 'cus', db.collection( 'stripe-customers' ).doc( curr.uid ) ).then( doc => 
          this.$binding( 
            'tokens', 
            db.collection( 'stripe-customers' ).doc( doc.id ).collection( 'tokens' )
          )
        )
      }
    },

    methods: {
      pay() {
        // createToken returns a Promise which resolves in a result object with
        // either a token or an error key.
        // See https://stripe.com/docs/api#tokens for the token object.
        // See https://stripe.com/docs/api#errors for the error object.
        // More general https://stripe.com/docs/stripe.js#stripe-create-token.
        this.points.original = this.profile.points
        this.profile.points = this.points.original + this.selected.pts
        createToken().then( data => {
          console.log(data)
          let token = data.token

          let doc = {
            token: token,
            customer: this.cus.cid,
            name: token.card.name,
            date: new Date(),
            selected: this.selected,
            uid: this.user.uid,
            pid: this.profile.id
          }
          
          db.collection('chargeups').doc( token.id ).set( doc ).then( () =>
            this.selected = false
          )

          db.collection('profiles').doc(this.profile.id).update(this.profile).catch( e => console.log(e))
          // .then( () => {
          //   let data = { token: 'tok_visa', amount: this.total * 100 }
          //   let url = 'https://us-central1-digipadz-f4bdf.cloudfunctions.net/paystripe'
          //   this.axios.post(url, {data}).then( r =>  {
          //     console.log(r)
          //     this.charge = r.data
          //     this.$emit(
          //       'notify',
          //       `Thanks ${doc.name}! We've received your request and will contact you via ${doc.email}`
          //     )
          //   })
          // })
        }).then( () => this.message = `Your points have been updated to ${this.profile.points}`)
      },

      select(item) {
        if ( this.user ) {
          this.selected = item
        }

        else this.$router.push({ name: 'sign-in' })
      }
    }
  }
</script>

<style scoped lang='stylus'>
  header
    margin-top -30px
    background rgba( 80 160 210 .95 )
    color white
    padding 12px 0
    @media( max-width: 420px)
      font-size: .8em
      padding 0px
  
    .w-flex
      align-items center

  section.my-points
    .w-wrapper
      .sub-header
        @media(max-width: 860px)
          flex-direction column

      .active-tab
        font-size 1.1em
        @media(max-width: 680px)
          font-size 1em
        .tab
          margin-right 48px
          padding-bottom 4px
          display inline-block
          cursor pointer
          @media(max-width: 800px)
            margin 0 auto
          @media(max-width: 680px)
            text-align center
          @media(max-width: 580px)
            width 80px
        .active 
          border-bottom 5px solid rgb( 130 130 130 )

      .info-title
        background rgb( 180 180 180 )
        color white
        padding 16px 48px
        border-top-right-radius 13px
        border-top-left-radius 13px
        @media(max-width: 480px)
          padding 16px
        .front 
          width 35%
          @media(max-width: 480px)
            width 58%
        .received
          margin-left auto
      .info-title p
        @media(max-width: 380px)
          width 25% 
          text-align center

      .info-detail
        min-height 200px
        border 1px solid rgb( 180 180 180 )
        background white
        .no-purchase 
          justify-content center
          margin-top 32px
      .buttons
        margin-top 64px
        button
          background rgb( 240 160 80 )
          color white
          border-radius 17px
          padding 8px 32px
    .buttons 
      margin-left auto
      @media(max-width: 860px)
        margin-right auto
        margin-left 0
        margin-top 16px
      @media(max-width: 420px)
        margin 16px auto 0 auto
    .button
      padding 3px 42px
      background var(--clr-blue)
      border-radius 21px
      color white
      cursor pointer
      margin-top 13px
      height 30px
      line-height 30px
      @media(max-width: 420px)
        padding 6px 28px
    .charge
      margin-right 26px
    .withdraw
      left 590px

  .current-points
    padding 4px 20px
    color black
    background rgb(240 240 240)
    margin auto 0
    margin-left 32px
    border-radius 5px
    box-shadow 2px 2px 8px rgba( 80 80 80 .5 ), -1px 1px 4px rgba( 80 80 80 .5 )
    span:first-child
      margin-right 4px
    @media(max-width: 380px)
      margin-left auto
      margin-right 8px

  section
    background rgba( 0 0 0 .5 )
    padding 24px 16px
    .w-wrapper
      padding 24px
      background white
      padding 32px 
      border-radius 5px

  section.qa
    h4
      margin-top 12px
      &:first-child
        margin-top 0
    @media( max-width: 420px)
      font-size .7em

  section.charge-up
    .color-gold 
      text-shadow 1px 1px 3px rgb(190 160 0)
      color rgb(110 110 110)
    .title
      margin-top 10px
    .grid
      @media(max-width: 860px)
        grid-row-gap 25px
      @media(max-width: 360px)
        grid-row-gap 15px
      .top-up
        height 150px
        min-width 125px
        padding 10px
        margin-bottom 10px
        background rgb( 240 240 240 )
        border-radius 5px
        box-shadow 2px 5px 3px rgba( 80 80 80 .3 ), -1px 1px 1px rgba( 80 80 80 .3 )
        border .6px solid lightgray
        cursor pointer
        @media(max-width: 460px)
          font-size .8em
          height 110px
          padding 10px
          margin-bottom 5px
        @media(max-width: 360px)
          margin 8px auto
        .price
          width 70%
          text-align center
          background white
          margin 0 auto
          position absolute 
          bottom 15px
          padding 8px 0
          left 0
          right 0
          color rgb(20 130 250)
          font-weight bold
        .points 
          position relative 
          text-align center
          p:first-child
            font-size 2em
            padding-top 5px
        .extra 
          position relative 
          text-align center
          font-size .85em
          color rgb( 250 80 80 )
          font-weight bold
        .gold
          color rgb(230 200 0)
          border 1px solid gold
          background rgb(253 253 253)
        .tag
          color white
          background rgb( 250 80 80 )
          border-radius 5px
          width 100px
          padding 5px 0
          text-align center
          transform rotate(30deg)
          border .5px solid white
          font-size .94em
          position absolute 
          top 0
          right -24px
        .payment
          margin-top 30px
      
        &.selected 
          transform scale(1.04)
          border 2px solid rgb( 240 100 100 )
          transition all 400ms
</style>
